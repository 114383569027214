import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCollapse: false,
  isOpen: false,
  theme: "light",
  loading: false,
};

export const toggleSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.isCollapse = action.payload;
    },

    toggleDrawer: (state, action) => {
      state.isOpen = action.payload;
    },

    setTheme: (state, action) => {
      state.theme = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { toggleSidebar, toggleDrawer, setTheme, setLoading } =
  toggleSlice.actions;

export default toggleSlice.reducer;
