import React, { useMemo } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { GiCheckMark } from "react-icons/gi";

import { useUpdateCheckPointsMutation } from "../../api/poVerificationApi";
import { ToastContainer, toast } from "react-toastify";

const PoTable = ({ tableData, urn }) => {
    const [updateCheckPoints] = useUpdateCheckPointsMutation();

    const data = useMemo(() => tableData, [tableData]);

    const handleChange = async (e, rowData) => {
        const { name, checked } = e.target;

        const data = {
            checkPointId: +name,
            isChecked: checked ? 1 : 0,
            isKam: rowData.kam_check,
            urn,
        };

        console.log(data);

        try {
            const resp = updateCheckPoints(data).unwrap();
            toast.promise(resp, {
                pending: "Posting",
                success: "Updated Successully",
                error: "Something Went Wrong",
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <table className="text-left text-gray-500 dark:text-gray-300 text-sm mb-4 w-full">
                <thead className="uppercase bg-gray-100 dark:bg-rich-black-2 w-max text-gray-700 dark:text-gray-200 text-center">
                    <tr>
                        <th
                            rowSpan={2}
                            className=" py-3 px-6 whitespace-nowrap"
                        >
                            Details
                        </th>
                        <th
                            className=" py-3 px-6 whitespace-nowrap"
                            colSpan={4}
                        >
                            Verified By
                        </th>
                    </tr>
                    <tr>
                        <th className=" py-3 px-6 whitespace-nowrap">SO</th>
                        <th className=" py-3 px-6 whitespace-nowrap">KAM</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((obj, i) => (
                        <tr
                            key={i}
                            className="border-b last-of-type:border-none"
                        >
                            <td className="w-max py-4 px-6 font-semibold">
                                {obj.check_point}
                            </td>
                            <td className="w-max py-4 px-6">
                                <input
                                    type="checkbox"
                                    defaultChecked={obj.so_check}
                                    name={obj.check_point_id}
                                    onChange={(e) => handleChange(e, obj)}
                                />
                            </td>
                            <td className="w-max py-4 px-6">
                                {obj.kam_check ? (
                                    <GiCheckMark className="h-5 w-5   text-green-500 mx-auto" />
                                ) : (
                                    <RxCrossCircled className="h-5 w-5  text-red-500 mx-auto" />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer
                autoClose={1500}
                hideProgressBar
                pauseOnFocusLoss={false}
            />
        </div>
    );
};

export default PoTable;
