import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useDraftEditQuery, useDraftListQuery } from "../../api/poProcessApi";
import { reset, setTotalCount } from "../../features/tableSlice";
import DataTable from "../../components/Tables/DataTable";
import { setLoading } from "../../features/toggleSlice";
import AbcoPoForm from "./AbcoPoForm";
import Modal from "../../components/modal";
import { ToastContainer } from "react-toastify";
import ServerError from "../../components/Error";
import { BarLoader } from "react-spinners";
import PageNotFound from "../../components/Error/PageNotFound";

const Drafts = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );

    const { data, isLoading, isFetching, isError, error } = useDraftListQuery({
        pageIndex,
        rowCount,
        pageFilter,
    });
    console.log(error);

    const [id, setId] = useState("");

    const tableData = data?.data;

    useEffect(() => {
        dispatch(setTotalCount(data?.last_page));
    }, [data?.last_page, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "SI no",
            Cell: ({ row: { index } }) => index + 1,
        },
        {
            Header: "Reference No",
            accessor: "reference_number",
        },

        {
            Header: "Key Account Manager",
            accessor: "kam",
        },
        {
            Header: "Customer Name",
            accessor: "partner",
        },

        {
            Header: "Edit",
            accessor: "id",
            Cell: ({ value }) => (
                <button
                    onClick={() => {
                        setId(value);
                        setOpen(true);
                    }}
                    className="bg-blue-100 hover:bg-blue-200 p-2 rounded-full"
                >
                    <BiEditAlt className="h-5 w-5 text-blue-500" />
                </button>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <>
                        <div className="p-7 bg-white rounded-lg shadow-xl dark:bg-raisin-black">
                            <DataTable
                                data={tableData}
                                tableColumns={tableColumns}
                            />

                            <Modal open={open} setOpen={setOpen}>
                                <EditDraft id={id} setOpen={setOpen} />
                            </Modal>
                        </div>

                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                        />
                    </>
                )
            )}
        </>
    );
};

export default Drafts;

const EditDraft = ({ id, setOpen }) => {
    const { data, isLoading, isError, error } = useDraftEditQuery(id);

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : isLoading ? (
                <div className="w-full my-5">
                    <BarLoader color="#E8687B" className="mx-auto" />
                </div>
            ) : (
                <AbcoPoForm
                    data={data}
                    urn={data.urn}
                    isDraft={true}
                    setOpen={setOpen}
                    poProcessId={id}
                    type="EDIT"
                />
            )}
        </>
    );
};
