import React, { useState } from "react";
import { useUrnListQuery } from "../../api/poVerificationApi";
import PoInfo from "./PoInfo";
import Select, { createFilter } from "react-select";
import Title from "../../components/Title";

const Loader = () => (
    <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-3 ">
            <div className="h-3 w-1/4 bg-slate-200 rounded" />
            <div className="h-6 w-2/5 bg-slate-200 rounded" />
        </div>
    </div>
);

const PoDetails = () => {
    const { data, isLoading } = useUrnListQuery();
    const [selected, setSelected] = useState("");

    return (
        <>
            <>
                <div className="flex-1 w-11/12  mx-auto p-7  bg-white rounded-lg shadow-xl m-4 dark:bg-raisin-black">
                    <Title>Verify PO Details</Title>

                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="w-full md:w-1/2 lg:w-2/5 mt-3">
                            <div className="text-gray-700 dark:text-gray-300 mb-2 font-semibold">
                                Search Reference Number
                            </div>

                            <Select
                                placeholder="Search URN"
                                value={selected}
                                unstyled
                                onChange={(value) => setSelected(value)}
                                isClearable
                                styles={{
                                    input: (base) => ({
                                        ...base,
                                    }),
                                    control: (base) => ({
                                        ...base,
                                    }),
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                classNames={{
                                    control: ({ isFocused }) =>
                                        `${
                                            isFocused && "border-primary-500"
                                        } border dark:bg-raisin-black dark:border-gray-700 font-semibold rounded-lg hover:cursor-pointer`,

                                    input: () =>
                                        "text-gray-700 dark:text-gray-300 font-semibold",

                                    singleValue: () =>
                                        "text-gray-700 dark:text-gray-300",

                                    option: ({ isFocused, isSelected }) =>
                                        `
                ${
                    isSelected
                        ? "bg-pink dark:bg-pink text-white "
                        : "text-gray-700 dark:text-gray-300"
                }
                  ${isFocused && "bg-gray-200 dark:bg-gray-700 "}
                  text-lg px-3 py-1 my-1 rounded-lg`,

                                    dropdownIndicator: () => "text-gray-700",
                                    clearIndicator: () => "text-gray-700",
                                    menu: () =>
                                        "p-2 mt-2 bg-white border dark:bg-raisin-black rounded-lg",

                                    indicatorsContainer: () => `px-2 gap-1`,
                                    valueContainer: () => "px-3 gap-1",
                                    placeholder: () => "text-gray-500",
                                }}
                                options={data}
                                components={{ IndicatorSeparator: null }}
                                getOptionLabel={(option) =>
                                    option.quote_reference_number
                                }
                                getOptionValue={(option) =>
                                    option.quote_reference_number
                                }
                            />
                        </div>
                    )}
                    {selected ? <PoInfo refNumber={selected} /> : null}
                </div>
            </>
        </>
    );
};

export default PoDetails;
