import React, { useEffect, useState } from "react";
import { FcViewDetails } from "react-icons/fc";
import { BiEditAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    usePoProcessEditQuery,
    usePoProcessTableListQuery,
} from "../../api/poProcessApi";
import { reset, setTotalCount } from "../../features/tableSlice";
import DataTable from "../../components/Tables/DataTable";
import Modal from "../../components/modal";
import Report from "./Report";
import { setLoading } from "../../features/toggleSlice";
import AbcoPoForm from "./AbcoPoForm";
import ServerError from "../../components/Error";
import { BarLoader } from "react-spinners";
import PageNotFound from "../../components/Error/PageNotFound";
import { ToastContainer } from "react-toastify";

const PoProcessTable = () => {
    const dispatch = useDispatch();
    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );
    const { data, isLoading, isFetching, isError, error } =
        usePoProcessTableListQuery({
            pageIndex,
            rowCount,
            pageFilter,
        });
    console.log(error);

    const [id, setId] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [isSpr, setIsSpr] = useState(null);
    const [viewModal, setViewModal] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState("");

    const tableData = data?.data;

    useEffect(() => {
        dispatch(setTotalCount(data?.last_page));
    }, [data?.last_page, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "SI no",
            Cell: ({ row: { index } }) => index + 1,
        },
        {
            Header: "Reference No",
            accessor: "reference_number",
        },
        {
            Header: "Solid Sequence No",
            accessor: "solid_sequence_number",
        },
        {
            Header: "Key Account Manager",
            accessor: "kam",
        },
        {
            Header: "Customer Name",
            accessor: "partner",
        },
        {
            Header: "Status",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <span
                    className={`${
                        value.is_approved && !value.is_cancelled
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                    } p-1 rounded-md whitespace-nowrap align-middle`}
                >
                    {value.is_cancelled
                        ? "This PO has been Cancelled"
                        : value.is_approved
                        ? "Verified & Loaded"
                        : "Not Verified"}
                </span>
            ),
        },
        {
            Header: "View",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    disabled={value.is_cancelled}
                    onClick={() => {
                        setId(value.id);
                        setIsSpr(value.isSpr);
                        setViewModal(true);
                    }}
                    className="bg-blue-100 p-2 rounded-full"
                >
                    <FcViewDetails className="h-5 w-5 text-blue-500" />
                </button>
            ),
        },
        {
            Header: "Edit",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    disabled={value.is_cancelled || value.is_approved}
                    onClick={() => {
                        setId(value.id);
                        setEditModal(true);
                        setReferenceNumber(value.reference_number);
                    }}
                    className="disabled:opacity-75  hover:bg-blue-200 p-2 rounded-full"
                >
                    <BiEditAlt className="h-5 w-5 text-blue-500" />
                </button>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="p-7 bg-white rounded-lg shadow-xl dark:bg-raisin-black">
                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />

                        <Modal
                            open={viewModal}
                            setOpen={setViewModal}
                            title={
                                isSpr
                                    ? "SALES PURCHASE REPORT"
                                    : "INVOICE REQUEST SLIP"
                            }
                        >
                            <Report id={id} isSpr={isSpr} />
                        </Modal>

                        <Modal open={editModal} setOpen={setEditModal}>
                            <EditPoProcess
                                id={id}
                                referenceNumber={referenceNumber}
                                setOpen={setEditModal}
                            />
                        </Modal>
                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default PoProcessTable;

export const EditPoProcess = ({ id, referenceNumber, setOpen }) => {
    const { data, isLoading, isError, error } = usePoProcessEditQuery(id);

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : isLoading ? (
                <div className="w-full my-5">
                    <BarLoader color="#E8687B" className="mx-auto" />
                </div>
            ) : (
                <AbcoPoForm
                    data={data}
                    urn={referenceNumber}
                    isDraft={false}
                    type={"EDIT"}
                    poProcessId={id}
                    setOpen={setOpen}
                />
            )}
        </>
    );
};
