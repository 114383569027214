import { HiOutlineHome } from "react-icons/hi";
import { BsPatchCheck } from "react-icons/bs";
import { VscServerProcess } from "react-icons/vsc";
import { TbNotesOff } from "react-icons/tb";
import { BiUserCircle } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";

export const items = [
    {
        name: "dashboard",
        label: "Dashboard",
        icon: <HiOutlineHome />,
        link: "/",
    },
    {
        name: "Verify PO Details",
        label: "Verify PO Details",
        icon: <BsPatchCheck />,
        link: "/verify-po-details",
    },
    {
        name: "ABCO PO Process",
        label: "ABCO PO Process",
        icon: <VscServerProcess />,
        link: "/abco-po-process",
    },
    {
        name: "Not Loaded POs",
        label: "Not Loaded POs",
        icon: <TbNotesOff />,
        link: "/all-un-loaded-po",
    },
    {
        name: "NDA & MSA",
        label: "NDA & MSA",
        icon: <IoDocumentTextOutline />,
        link: "/nda-msa",
    },
    {
        name: "Admin",
        label: "Admin",
        icon: <BiUserCircle />,

        items: [
            {
                name: "Country",
                label: "Country",
                link: "/admin/country",
            },
            {
                name: "State",
                label: "State",
                link: "/admin/state",
            },
            {
                name: "City",
                label: "City",
                link: "/admin/city",
            },
            {
                name: "SupportType",
                label: "Support Type",
                link: "/admin/support-type",
            },
            {
                name: "OEM",
                label: "OEM",
                link: "/admin/oem",
            },
            {
                name: "SLA",
                label: "SLA",
                link: "/admin/sla",
            },
            {
                name: "ProductType",
                label: "Product Type",
                link: "/admin/product-type",
            },
            {
                name: "Model",
                label: "Model",
                link: "/admin/model",
            },
        ],
    },
];
