import React, { useEffect, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import StateDetails from "./StateDetails";
import Modal from "../../components/modal";
import Title from "../../components/Title";
import {
    useAddStateMutation,
    useDeleteStateMutation,
    useGetStateListQuery,
} from "../../api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import { reset, setTotalCount } from "../../features/tableSlice";
import DataTable from "../../components/Tables/DataTable";
import { Field, Formik } from "formik";
import { CgSpinnerAlt } from "react-icons/cg";
import { setLoading } from "../../features/toggleSlice";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";
import { ToastContainer, toast } from "react-toastify";

const State = () => {
    const dispatch = useDispatch();

    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );

    const {
        data: stateList,
        isLoading,
        isError,
        error,
        isFetching,
    } = useGetStateListQuery({
        pageIndex,
        rowCount,
        pageFilter,
    });
    const [deleteState] = useDeleteStateMutation();

    const [addState, setAddState] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [stateId, setStateId] = useState(null);

    const tableData = stateList?.data;

    useEffect(() => {
        dispatch(setTotalCount(stateList?.last_page));
        return () => dispatch(reset());
    }, [dispatch, stateList?.last_page]);

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "Name",
            accessor: "state_name",
        },
        {
            Header: "Country",
            accessor: "country.country_name",
        },

        {
            Header: "edit",
            accessor: "state_id",
            Cell: ({ value }) => (
                <button
                    onClick={() => {
                        setStateId(value);
                        setEditModal(true);
                    }}
                    className="hover:bg-blue-100 p-2 rounded-full"
                >
                    <BiEditAlt className="h-5 w-5 hover:text-blue-500" />
                </button>
            ),
        },
        {
            Header: "delete",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={async () => {
                        try {
                            const resp = await deleteState(
                                value.state_id
                            ).unwrap();
                            toast.success(resp.message);
                        } catch (error) {
                            toast.error(error.data.error);
                        }
                    }}
                >
                    <HiOutlineTrash className="h-5 w-5" />
                </button>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="card w-11/12">
                        <Title>State</Title>
                        <div className="flex justify-end">
                            <button
                                onClick={() => {
                                    setAddState(true);
                                }}
                                className="bg-gray-100 hover:bg-gray-200  active:bg-gray-300 focus:outline-none py-2 px-3  rounded-lg  dark:bg-charcoal dark:text-gray-300"
                            >
                                <FiPlus className="h-4 w-4 inline mr-3" />
                                Add New State
                            </button>
                        </div>

                        <AddState open={addState} setOpen={setAddState} />

                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />

                        <Modal
                            open={editModal}
                            setOpen={setEditModal}
                            title="Edit State"
                        >
                            <StateDetails id={stateId} setOpen={setEditModal} />
                        </Modal>
                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                            theme="colored"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default State;

const AddState = ({ open, setOpen }) => {
    const [addState] = useAddStateMutation();
    return (
        <Modal open={open} setOpen={setOpen}>
            <Formik
                initialValues={{
                    state_name: "",
                    country_id: 78,
                }}
                onSubmit={async (values) => {
                    try {
                        const resp = await addState(values).unwrap();
                        toast.success(resp.message);
                        setOpen(false);
                    } catch (error) {
                        toast.error(error.data.error);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="overflow-hidden  sm:rounded-md">
                            <div className="border-b-2  px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="country_id"
                                            className="form-label"
                                        >
                                            Country
                                        </label>
                                        <Field
                                            as="select"
                                            name="country_id"
                                            id="country_id"
                                            className=" form-select"
                                        >
                                            {/* {countryList &&
                    countryList.map(({ country_name, country_id }, i) => (
                      <>
                        <option value={country_id}>{country_name}</option>
                      </>
                    ))} */}
                                        </Field>
                                    </div>
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="state_name"
                                            className="form-label"
                                        >
                                            State
                                        </label>
                                        <Field
                                            id="state_name"
                                            name="state_name"
                                            className="form-input"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-action">
                                <button
                                    type="submit"
                                    className="form-btn-primary"
                                >
                                    {isSubmitting && (
                                        <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                    )}
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="form-btn-secondary"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};
