import React, { Fragment } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import logo from "../../assets/images/Solid-Tech-logo.png";
import logo_mini from "../../assets/images/logo-mini.png";
import { useDispatch, useSelector } from "react-redux";
import NavList from "./NavList";
import { items } from "../../config/sidebarMenu";
import { toggleDrawer, toggleSidebar } from "../../features/toggleSlice";
import Drawer from "./Drawer";

export const Sidebar = () => {
    const dispatch = useDispatch();
    const { isCollapse } = useSelector(({ toggle }) => toggle);
    const { user } = useSelector(({ auth }) => auth);

    return (
        <>
            <aside
                className={`hidden lg:block bg-white dark:bg-rich-black-2  ${
                    isCollapse ? "lg:w-16 -left-64" : "lg:w-64 left-0"
                }   border-r dark:border-raisin-black  top-0  bottom-0 transition-all duration-300 lg:sticky fixed z-10 `}
            >
                <div className="flex flex-col sticky top-0 h-screen">
                    <div className="flex-grow-0 mb-3 pt-5 h-24">
                        {isCollapse ? (
                            <>
                                <button
                                    className="absolute bg-gray-50 border-2 border-transparent dark:bg-rich-black dark:border-gray-500  drop-shadow-xl  rounded-full px-[0.4rem] py-[0.4rem] -right-4 text-gray-700 dark:text-gray-200 transition duration-300"
                                    onClick={() => {
                                        dispatch(toggleSidebar(false));
                                    }}
                                >
                                    <FaChevronRight className="h-4 w-4" />
                                </button>
                                <img
                                    className="w-9 mx-auto"
                                    src={logo_mini}
                                    alt="Solid_Tech"
                                />
                            </>
                        ) : (
                            <>
                                <button
                                    className="absolute bg-gray-50 border-2 border-transparent dark:bg-rich-black dark:border-gray-500  drop-shadow-xl  rounded-full px-[0.4rem] py-[0.4rem] -right-4 text-gray-700 dark:text-gray-200 transition duration-300"
                                    onClick={() => {
                                        dispatch(toggleSidebar(true));
                                    }}
                                >
                                    <FaChevronLeft className="h-4 w-4" />
                                </button>
                                <img
                                    className="w-32 pl-5"
                                    src={logo}
                                    alt="Solid_Tech"
                                />
                            </>
                        )}
                    </div>
                    <div
                        className={`pb-5 flex-1 overflow-y-auto scroll-smooth hover:scroll-auto ${
                            isCollapse
                                ? "px-1 flex flex-col items-center"
                                : "px-5"
                        }`}
                    >
                        <ul className="flex flex-col space-y-1">
                            {items.map(
                                (
                                    { link, name, icon, label, items: submenu },
                                    i
                                ) => (
                                    <Fragment key={name + i}>
                                        {label === "Admin" &&
                                        user.user_id !== (38 || 55) ? null : (
                                            <NavList
                                                link={link}
                                                icon={icon}
                                                label={label}
                                                isCollapse={isCollapse}
                                                hasSubNav={!!submenu}
                                                submenu={submenu}
                                            />
                                        )}
                                    </Fragment>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </aside>

            <Drawer>
                <div
                    className={`pb-5 flex-1 overflow-y-auto scroll-smooth hover:scroll-auto ${
                        isCollapse ? "px-1 flex flex-col items-center" : "px-5"
                    }`}
                >
                    <ul className="flex flex-col  space-y-1">
                        {items.map(
                            (
                                { link, name, icon, label, items: submenu },
                                i
                            ) => (
                                <Fragment key={name + i}>
                                    {label === "Admin" &&
                                    user.user_id !== (38 || 55) ? null : (
                                        <NavList
                                            link={link}
                                            icon={icon}
                                            label={label}
                                            isCollapse={isCollapse}
                                            hasSubNav={!!submenu}
                                            submenu={submenu}
                                            close={() => {
                                                dispatch(toggleDrawer(false));
                                            }}
                                        />
                                    )}
                                </Fragment>
                            )
                        )}
                    </ul>
                </div>
            </Drawer>
        </>
    );
};
