import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "../../hooks/useDebounce";
import { setPageFilter, setPageIndex } from "../../features/tableSlice";
import { BiSearch } from "react-icons/bi";

export const Filter = () => {
    const dispatch = useDispatch();

    const [value, setValue] = useState("");
    const keywords = useDebounce(value, 500);

    useEffect(() => {
        dispatch(setPageFilter(keywords));
        dispatch(setPageIndex(0));
    }, [dispatch, keywords]);

    return (
        <div className="w-3/5 lg:w-1/4 relative border dark:border-gray-600 dark:text-gray-200  focus:ring-gray-800 focus:dark:bg-gray-700 bg-gray-50 dark:bg-gray-800 px-3 rounded-lg">
            <BiSearch className="w-5 h-full stroke-gray-500 absolute inset-y-0" />
            <input
                className="bg-transparent outline-none py-2 pl-10 pr-3 rounded-md text-sm w-full"
                value={value || ""}
                placeholder="Search"
                onChange={(e) => {
                    setValue(e.target.value);
                }}
            />
        </div>
    );
};
