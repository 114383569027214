import { FaChevronLeft } from "react-icons/fa";
import logo from "../../assets/images/Solid-Tech-logo.png";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../features/toggleSlice";

const Drawer = ({ children }) => {
  const dispatch = useDispatch();
  const { theme, isOpen } = useSelector(({ toggle }) => toggle);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`lg:hidden relative z-10 ${theme === "dark" && "dark"}`}
        onClose={() => dispatch(toggleDrawer(false))}
      >y
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="border-r dark:border-raisin-black pointer-events-auto relative  max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="bg-white dark:bg-rich-black-2 flex justify-between py-3 px-5 mt-3">
                      <button
                        className="absolute bg-gray-50 border-2 border-transparent
                dark:bg-rich-black dark:border-gray-500  drop-shadow-xl  rounded-full px-[0.4rem] py-[0.4rem] -right-4 text-gray-700 dark:text-gray-200 transition duration-300"
                        onClick={() => {
                          dispatch(toggleDrawer(false));
                        }}
                      >
                        <FaChevronLeft className="h-4 w-4" />
                      </button>
                      <img className="w-32" src={logo} alt="Solid_Tech" />
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-auto bg-white dark:bg-rich-black-2 py-6 shadow-xl">
                    <div className="px-4 sm:px-6"></div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
