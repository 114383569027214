import React from "react";
import errorSvg from "../../assets/images/500 Internal Server Error-amico.svg";

const serverError = () => {
  return (
    <div className="w-fit h-fit  object-cover mx-auto mt-12">
      <img src={errorSvg} alt="" width="100%" height="100%" />
    </div>
  );
};

export default serverError;
