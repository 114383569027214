import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageIndex: 0,
  rowCount: 10,
  pageFilter: "",
  totalCount: 0,
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload;
    },

    setRowCount: (state, action) => {
      state.rowCount = action.payload;
    },

    setTotalCount: (state, { payload }) => {
      state.totalCount = payload;
    },

    setPageFilter: (state, { payload }) => {
      state.pageFilter = payload;
    },

    reset: () => initialState,
  },
});

export const {
  setPageIndex,
  setRowCount,
  setTotalCount,
  setPageFilter,
  reset,
} = tableSlice.actions;

export default tableSlice.reducer;
