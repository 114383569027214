import React from "react";
import {
    useGetCountryQuery,
    useGetStateDataQuery,
    useStateUpdateMutation,
} from "../../api/adminApi";
import { Field, Formik } from "formik";
import { toast } from "react-toastify";

const StateDetails = ({ id, setOpen }) => {
    const { data, isLoading } = useGetStateDataQuery(id);
    const { data: countryList } = useGetCountryQuery();
    const [stateUpdate] = useStateUpdateMutation();

    return (
        <>
            {!isLoading && (
                <Formik
                    initialValues={{
                        state_name: data[0]?.state_name || "",
                        country_id: data[0]?.country_id || "",
                    }}
                    onSubmit={async (values) => {
                        try {
                            const resp = await stateUpdate({
                                data: values,
                                id,
                            }).unwrap();
                            setOpen(false);
                            toast.success(resp.message);
                        } catch (error) {
                            toast.error(error.data.error);
                        }
                    }}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="overflow-hidden  sm:rounded-md">
                                <div className="border-b-2  px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="country_id"
                                                className="form-label"
                                            >
                                                Country
                                            </label>
                                            <Field
                                                as="select"
                                                name="country_id"
                                                id="country_id"
                                                className=" form-select"
                                            >
                                                {countryList &&
                                                    countryList.map(
                                                        (
                                                            {
                                                                country_name,
                                                                country_id,
                                                            },
                                                            i
                                                        ) => (
                                                            <>
                                                                <option
                                                                    value={
                                                                        country_id
                                                                    }
                                                                >
                                                                    {
                                                                        country_name
                                                                    }
                                                                </option>
                                                            </>
                                                        )
                                                    )}
                                            </Field>
                                        </div>
                                        <div className="col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="state_name"
                                                className="form-label"
                                            >
                                                State
                                            </label>
                                            <Field
                                                id="state_name"
                                                name="state_name"
                                                className="form-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    <button
                                        type="submit"
                                        className="form-btn-primary"
                                        // onClick={() => dispatch(setModalOpen(false))}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="form-btn-secondary"
                                        // onClick={() => dispatch(setModalOpen(false))}
                                        // ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default StateDetails;
