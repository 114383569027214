import React from "react";
import {
    useCountryUpdateMutation,
    useGetCountryDataQuery,
} from "../../api/adminApi";
import { Field, Formik } from "formik";
import { CgSpinnerAlt } from "react-icons/cg";
import { toast } from "react-toastify";

const CountryDetails = ({ id, setOpen }) => {
    const { data, isLoading } = useGetCountryDataQuery(id);
    const [countryUpdate] = useCountryUpdateMutation();

    return (
        <>
            {!isLoading && (
                <Formik
                    initialValues={{
                        country_name: data[0]?.country_name || "",
                    }}
                    onSubmit={async (values) => {
                        try {
                            const resp = await countryUpdate({
                                data: {
                                    ...values,
                                    sortname: "AS",
                                    currency: null,
                                    currency_covertion_rate_to_inr: "0.00",
                                    phonecode: 1684,
                                },
                                id,
                            }).unwrap();
                            toast.success(resp.message);
                            setOpen(false);
                        } catch (error) {
                            toast.error(error.data.error);
                        }
                    }}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="overflow-hidden  sm:rounded-md">
                                <div className="border-b-2  px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="partnerAccount"
                                                className="form-label"
                                            >
                                                Country
                                            </label>
                                            <Field
                                                id="country_name"
                                                name="country_name"
                                                className="form-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    <button
                                        type="submit"
                                        className="form-btn-primary"
                                    >
                                        {isSubmitting && (
                                            <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                        )}
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="form-btn-secondary"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default CountryDetails;
