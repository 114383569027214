import { apiSlice } from "./apiSlice";

const poVerificationApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        urnList: builder.query({
            query: () => "verify-po-details/urns",
        }),

        urnDetails: builder.query({
            query: (n) => `verify-po-details/urn/${n}`,
        }),

        updateCheckPoints: builder.mutation({
            query: (data) => ({
                url: "/verify-po-details",
                method: "put",
                body: data,
            }),
        }),
    }),
});

export const {
    useUrnListQuery,
    useUrnDetailsQuery,
    useUpdateCheckPointsMutation,
} = poVerificationApi;
