import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { Popover, Transition } from "@headlessui/react";

const NavList = ({
    link,
    close = null,
    icon,
    label,
    isCollapse,
    hasSubNav,
    submenu,
}) => {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <li>
            {!hasSubNav && (
                <NavLink
                    className={({ isActive }) =>
                        (isActive
                            ? "bg-gray-100  font-medium dark:bg-oxford-blue"
                            : "bg-white dark:bg-rich-black-2") +
                        " flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-oxford-blue transition-colors duration-300"
                    }
                    to={link}
                    onClick={close}
                >
                    {icon}
                    <span
                        className={`text-sm flex-grow ${
                            isCollapse ? "lg:hidden" : "lg:block"
                        }`}
                    >
                        {label}
                    </span>
                </NavLink>
            )}

            {hasSubNav && (
                <>
                    <div
                        className=" bg-white dark:bg-rich-black-2 cursor-pointer flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-oxford-blue transition-colors duration-300"
                        onClick={() => setCollapsed((preValue) => !preValue)}
                    >
                        {!isCollapse && icon}
                        {hasSubNav && collapsed && isCollapse && (
                            <PopOver icon={icon}>
                                <ul className="space-y-1 ">
                                    {submenu.map((subItem, i) => (
                                        <li key={i}>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    (isActive
                                                        ? "bg-gray-100  font-medium dark:bg-oxford-blue"
                                                        : "bg-white dark:bg-rich-black-2") +
                                                    " flex space-x-3 px-3 py-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-oxford-blue transition-colors duration-300"
                                                }
                                                to={subItem.link}
                                            >
                                                {subItem.label}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </PopOver>
                        )}
                        <span
                            className={`text-sm flex-grow ${
                                isCollapse ? "lg:hidden" : "lg:block"
                            }`}
                        >
                            {label}
                        </span>

                        <FiChevronDown
                            className={`${
                                isCollapse && "hidden"
                            } h-4 w-4 self-center ${
                                !collapsed && "rotate-180"
                            } transition-transform`}
                        />
                    </div>
                    {!collapsed && !isCollapse && (
                        <ul className="ml-8 pt-1 space-y-1 ">
                            {submenu.map((subItem) => (
                                <NavList
                                    link={subItem.link}
                                    icon={subItem.icon}
                                    label={subItem.label}
                                    isCollapse={isCollapse}
                                    hasSubNav={!!subItem.items}
                                />
                            ))}
                        </ul>
                    )}
                </>
            )}
        </li>
    );
};

export default NavList;

const PopOver = ({ icon, children }) => {
    return (
        <Popover>
            {({ open }) => (
                <>
                    <Popover.Button className="self-center justify-self-center">
                        {icon}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute origin-top-right left-full -translate-y-1/4 z-10 mt-3 w-auto px-4 sm:px-0">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="flex flex-col space-y-4 bg-white dark:bg-rich-black-2 dark:border border-raisin-black p-3">
                                    {children}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};
