import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://solid-sales-crm.com/api/",
        //baseUrl: "https://staging.solid-sales-crm.com/api/",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.token;
            if (token) headers.set("authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: [
        "Post",
        "Delete",
        "Update",
        "draftDetails",
        "draftList",
        "poProcessDetails",
        "monthlyReport",
        "monthlyInvDetails",
        "supportType",
    ],
    endpoints: () => ({}),
});
