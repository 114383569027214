import React, { useEffect } from "react";
import Title from "../../components/Title";
import { useUnLoadedPoListQuery } from "../../api/poProcessApi";
import { reset, setTotalCount } from "../../features/tableSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import DataTable from "../../components/Tables/DataTable";
import { setLoading } from "../../features/toggleSlice";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";

const NotLoadedPos = () => {
    const dispatch = useDispatch();
    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );

    const {
        data: unLoadedPoList,
        isLoading,
        isError,
        isFetching,
        error,
    } = useUnLoadedPoListQuery({
        pageIndex,
        rowCount,
        pageFilter,
    });

    const tableData = unLoadedPoList?.data;

    useEffect(() => {
        dispatch(setTotalCount(unLoadedPoList?.last_page));
    }, [dispatch, unLoadedPoList?.last_page]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "no.",
            Cell: ({ row: { index } }) => index + 1,
        },
        {
            Header: "KAM Name",
            accessor: "kam",
        },
        {
            Header: "Partner Account Name",
            accessor: "partner_account_name",
        },
        {
            Header: "Quote Ref. Number",
            accessor: "quote_reference_number",
        },
        {
            Header: "First Quote Amount",
            accessor: "first_quote_amount",
        },
        {
            Header: "PO Amount	",
            accessor: "po_amount",
        },
        {
            Header: "PO Date",
            accessor: "closure_datetime_as_per_po",
        },
    ];

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="w-11/12  mx-auto p-7 bg-white rounded-lg shadow-xl m-4 dark:bg-raisin-black">
                        <Title>Not Loaded Po's</Title>

                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />
                    </div>
                )
            )}
        </>
    );
};

export default NotLoadedPos;
