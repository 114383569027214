import React from "react";
import Modal from "../../components/modal";
import { Field, Formik } from "formik";
import { useAddCountryMutation } from "../../api/adminApi";
import { CgSpinnerAlt } from "react-icons/cg";
import { toast } from "react-toastify";

const AddCountry = ({ open, setOpen }) => {
    const [addCountry] = useAddCountryMutation();
    return (
        <Modal open={open} setOpen={setOpen} title={"Add Country"}>
            <Formik
                initialValues={{
                    sortname: "",
                    currency: "",
                    country_name: "",
                    currency_covertion_rate_to_inr: "",
                    phonecode: "",
                }}
                onSubmit={async (values) => {
                    try {
                        const resp = await addCountry(values).unwrap();
                        setOpen(false);
                        toast.success(resp.message);
                    } catch (error) {
                        toast.error(error.data.error);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="overflow-hidden  sm:rounded-md">
                            <div className="border-b-2  px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="sortname"
                                            className="form-label"
                                        >
                                            Sort Name
                                        </label>
                                        <Field
                                            id="sortname"
                                            name="sortname"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="currency"
                                            className="form-label"
                                        >
                                            Currency
                                        </label>
                                        <Field
                                            id="currency"
                                            name="currency"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="country_name"
                                            className="form-label"
                                        >
                                            Country
                                        </label>
                                        <Field
                                            id="country_name"
                                            name="country_name"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="currency_covertion_rate_to_inr"
                                            className="form-label"
                                        >
                                            Currency Rate in INR
                                        </label>
                                        <Field
                                            id="currency_covertion_rate_to_inr"
                                            name="currency_covertion_rate_to_inr"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="phonecode"
                                            className="form-label"
                                        >
                                            Phonecode
                                        </label>
                                        <Field
                                            id="phonecode"
                                            name="phonecode"
                                            className="form-input"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-action">
                                <button
                                    type="submit"
                                    className="form-btn-primary"
                                >
                                    {isSubmitting && (
                                        <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                    )}
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="form-btn-secondary"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddCountry;
