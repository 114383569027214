import { Field, Formik } from "formik";
import React from "react";
import {
    useCityUpdateMutation,
    useGetCityDataQuery,
    useGetStateQuery,
} from "../../api/adminApi";
import { CgSpinnerAlt } from "react-icons/cg";
import { toast } from "react-toastify";

const CityDetails = ({ id, setOpen }) => {
    const { data, isLoading } = useGetCityDataQuery(id);
    const { data: stateList } = useGetStateQuery();
    const [cityUpdate] = useCityUpdateMutation();

    return (
        <>
            {!isLoading && (
                <Formik
                    initialValues={{
                        state_id: data[0]?.state_id || "",
                        city_name: data[0]?.city_name || "",
                    }}
                    onSubmit={async (values) => {
                        try {
                            const resp = await cityUpdate({
                                data: values,
                                id,
                            }).unwrap();
                            setOpen(false);
                            toast.success(resp.message);
                        } catch (error) {
                            toast.error(error.data.error);
                        }
                    }}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="overflow-hidden  sm:rounded-md">
                                <div className="border-b-2  px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="state_id"
                                                className="form-label"
                                            >
                                                State
                                            </label>
                                            <Field
                                                as="select"
                                                name="state_id"
                                                id="state_id"
                                                className=" form-select"
                                            >
                                                {stateList &&
                                                    stateList.map(
                                                        (
                                                            {
                                                                state_name,
                                                                state_id,
                                                            },
                                                            i
                                                        ) => (
                                                            <>
                                                                <option
                                                                    key={
                                                                        state_id
                                                                    }
                                                                    value={
                                                                        state_id
                                                                    }
                                                                >
                                                                    {state_name}
                                                                </option>
                                                            </>
                                                        )
                                                    )}
                                            </Field>
                                        </div>
                                        <div className="col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="city_name"
                                                className="form-label"
                                            >
                                                City
                                            </label>
                                            <Field
                                                id="city_name"
                                                name="city_name"
                                                className="form-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    <button
                                        type="submit"
                                        className="form-btn-primary"
                                    >
                                        {isSubmitting && (
                                            <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                        )}
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="form-btn-secondary"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default CityDetails;
