import React, { useMemo } from "react";
import { useTable } from "react-table";

const Table = ({ tableData = [], tableColumns }) => {
    const data = useMemo(() => tableData, [tableData]);
    const columns = useMemo(() => tableColumns, [tableColumns]);

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div className="overflow-x-auto mt-10 rounded-lg">
            <table
                className="w-full text-left text-gray-500 dark:text-gray-400 text-sm mb-4"
                {...getTableProps()}
            >
                <thead className="capitalize bg-gray-100 dark:bg-charcoal w-max text-gray-700 dark:text-gray-200">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className=" py-3 px-6 whitespace-nowrap"
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                className="border-b last-of-type:border-none"
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="min-w-[200px] px-3 py-2 "
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
