import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useSortBy, useTable } from "react-table";
import { setPageIndex, setRowCount } from "../../features/tableSlice";
import { Filter } from "./Filter";
import Pagination from "./Pagination";
import Select, { createFilter } from "react-select";

const DataTable = ({
    data: tableData,
    tableColumns,
    filter = true,
    children,
}) => {
    const dispatch = useDispatch();

    const {
        totalCount: controlledPageCount,
        rowCount,
        pageIndex: index,
    } = useSelector(({ table }) => table);

    const data = useMemo(() => tableData, [tableData]);
    const columns = useMemo(() => tableColumns, [tableColumns]);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: index, pageSize: rowCount },
            manualPagination: true,
            manualFilters: true,
            pageCount: controlledPageCount,
        },
        useSortBy,
        usePagination
    );

    const goToPage = useCallback((e) => gotoPage(e), [gotoPage]);

    useEffect(() => {
        dispatch(setRowCount(pageSize));
    }, [dispatch, pageSize]);

    useEffect(() => {
        dispatch(setPageIndex(pageIndex));
    }, [dispatch, pageIndex]);

    const [selected, setSelected] = useState({ size: pageSize });

    return (
        <>
            <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:items-center lg:justify-between md:w-2/3 lg:w-full  items-start-start mb-5">
                {filter && <Filter />}
                {children}
            </div>
            {data.length > 0 ? (
                <>
                    <div className="relative overflow-x-auto">
                        <table
                            className="w-full  text-left text-gray-500 dark:text-gray-300 text-sm mb-4"
                            {...getTableProps()}
                        >
                            <thead className="capitalize bg-gray-100  dark:bg-rich-black-2 w-max text-gray-700 dark:text-gray-200">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                className=" py-3 px-6 whitespace-nowrap"
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                {column.render("Header")}

                                                <span>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <BsArrowDownShort className="inline h-5 w-5" />
                                                        ) : (
                                                            <BsArrowUpShort className="inline h-5 w-5" />
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            className="hover:bg-gray-100 border-b last-of-type:border-none dark:border-b-gray-700"
                                            {...row.getRowProps()}
                                        >
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        className="w-max py-3 px-5"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-7 mb-4 flex items-center justify-between text-gray-800 dark:text-gray-200">
                        <strong>
                            Page {pageIndex + 1} of {pageOptions.length}
                        </strong>

                        {/* <PageSize setPageSize={setPageSize} /> */}

                        <Select
                            placeholder="No of rows"
                            value={selected}
                            unstyled
                            menuPosition="fixed"
                            isSearchable={false}
                            onChange={(value) => {
                                setSelected(value);
                                setPageSize(value.size);
                            }}
                            styles={{
                                input: (base) => ({
                                    ...base,
                                }),
                                control: (base) => ({
                                    ...base,
                                }),
                                menu: (base) => ({
                                    ...base,
                                    "::WebkitSliderThumb": "none",
                                }),
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            classNames={{
                                control: ({ isFocused }) =>
                                    `${
                                        isFocused && "border-primary-500"
                                    } border dark:bg-raisin-black dark:border-gray-700 font-semibold rounded-lg hover:cursor-pointer`,

                                input: () =>
                                    "text-gray-700 dark:text-gray-300 font-semibold",

                                singleValue: () =>
                                    "text-gray-700 dark:text-gray-300",

                                option: ({ isFocused, isSelected }) =>
                                    `
                ${
                    isSelected
                        ? "bg-pink dark:bg-pink text-white "
                        : "text-gray-700 dark:text-gray-300"
                }
                  ${isFocused && "bg-gray-200 dark:bg-gray-700 "}
                  text-lg px-3 py-1 my-1 `,

                                dropdownIndicator: () => "text-gray-700",
                                clearIndicator: () => "text-gray-700",
                                menu: () =>
                                    " mt-2 bg-white border dark:bg-raisin-black rounded-lg",

                                indicatorsContainer: () => `px-2 gap-1`,
                                valueContainer: () => "px-3 gap-1",
                                placeholder: () => "text-gray-500",
                            }}
                            options={[
                                { size: 10 },
                                { size: 25 },
                                { size: 50 },
                                { size: 100 },
                            ]}
                            components={{ IndicatorSeparator: null }}
                            getOptionLabel={(option) => option.size}
                            getOptionValue={(option) => option.size}
                        />
                    </div>
                    {controlledPageCount > 1 &&
                        (controlledPageCount ||
                            Math.ceil(pageCount / pageSize)) && (
                            <Pagination
                                totalCount={
                                    controlledPageCount ||
                                    +Math.ceil(pageCount / pageSize)
                                }
                                currentPage={pageIndex || 0}
                                pageSize={pageCount}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                gotoPage={goToPage}
                            />
                        )}
                </>
            ) : (
                <p className="text-lg text-gray-500 dark:text-gray-300 text-center p-3">
                    No Data Found
                </p>
            )}
        </>
    );
};

export default DataTable;
