import { apiSlice } from "./apiSlice";

// const { table } = store.getState();

const poProcessApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        poProcessList: builder.query({
            query: () => `/abco-po-process/urns`,
        }),

        poProcessTableList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/paginated",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),
        }),

        MonthlyInvList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/monthly-invoice/paginated",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),
        }),

        draftList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/draft/paginated",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),
            providesTags: ["draftList"],
        }),

        unLoadedPoList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "all-un-loaded-po/paginated",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),
        }),

        poTypeList: builder.query({
            query: () => `/abco-po-process/po-type/list`,
        }),

        tmcContractTypeList: builder.query({
            query: () => `/abco-po-process/tmc-contract-type`,
        }),

        poCurrencyList: builder.query({
            query: () => `/abco-po-process/currency/list`,
        }),

        poCompanyList: builder.query({
            query: () => `/abco-po-process/company/list`,
        }),

        poCountryList: builder.query({
            query: () => `/partners/country/list`,
        }),

        poStateList: builder.query({
            query: (country) => `partners/state/list?country_id=${country}`,
        }),

        poCityList: builder.query({
            query: (state_id) => `partners/city/list?state_id=${state_id}`,
        }),

        poRegionList: builder.query({
            query: () => `/abco-po-process/create/region`,
        }),

        abcoPoData: builder.query({
            query: (srn) => `/abco-po-process/urn/${srn}`,
        }),

        viewLoadedPos: builder.query({
            query: (id) => `abco-po-process/${id}/show`,
        }),

        monthlyInvSalesPurchaseReport: builder.query({
            query: (id) => `abco-po-process/${id}/show`,
            providesTags: ["monthlyReport"],
        }),

        paymentDueDays: builder.query({
            query: () => "abco-po-process/payment-due-days",
        }),

        paymentDueDaysApprovalEmailsList: builder.query({
            query: () => "abco-po-process/payment-due-days-approval-emails",
        }),

        invoiceRaiseIn: builder.query({
            query: () => "abco-po-process/invoice-raise-in",
        }),

        invoiceCycle: builder.query({
            query: () => "abco-po-process/invoice-cycle",
        }),

        invoiceType: builder.query({
            query: () => "abco-po-process/invoice-type",
        }),

        monthlyInvoiceMonths: builder.query({
            query: () => "abco-po-process/monthly-invoice/months",
        }),

        draftEdit: builder.query({
            query: (id) => `abco-po-process/draft/${id}/edit`,
            providesTags: ["draftDetails"],
        }),

        poProcessEdit: builder.query({
            query: (id) => `abco-po-process/${id}/edit`,
            providesTags: ["poProcessDetails"],
        }),

        monthlyInvEdit: builder.query({
            query: ({ id, month, year, SPRInvDetailId }) =>
                `abco-po-process/monthly-invoice/${id}/${month}/${year}/${SPRInvDetailId}/edit/invoice`,
            providesTags: ["monthlyInvDetails"],
            keepUnusedDataFor: 0,
        }),

        oemList: builder.query({
            query: () => "abco-po-process/create/oem",
        }),

        slaList: builder.query({
            query: () => "abco-po-process/create/sla",
        }),

        productTypeList: builder.query({
            query: () => "abco-po-process/create/product_type",
        }),

        newModelList: builder.query({
            query: () => "abco-po-process/create/newModel",
        }),

        supportTypeList: builder.query({
            query: () => "abco-po-process/create/support_type",
            providesTags: ["supportType"],
        }),

        notificationCount: builder.query({
            query: () => "abco-po-process/notification/count",
        }),

        notificationList: builder.query({
            query: () => "abco-po-process/notification/list",
        }),

        generateUrn: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/generate-urn",
                method: "post",
                body: data,
            }),
        }),

        saveAsDraft: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/draft/save",
                method: "post",
                body: data,
            }),
            keepUnusedDataFor: 0,
            invalidatesTags: ["draftList"],
        }),

        sendForApproval: builder.mutation({
            query: (data) => ({
                url: "abco-po-process",
                method: "post",
                body: data,
            }),
        }),

        sendMonthlyInv: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/monthly-invoice/send/invoice",
                method: "post",
                body: data,
            }),
            // invalidatesTags: ["monthlyInvDetails", "monthlyReport"],
            keepUnusedDataFor: 0,
        }),

        updateMonthlyInv: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/monthly-invoice/invoice/update",
                method: "post",
                body: data,
            }),
            keepUnusedDataFor: 0,
            // invalidatesTags: ["monthlyInvDetails", "monthlyReport"],
        }),

        updatePo: builder.mutation({
            query: ({ values, poProcessId }) => {
                return {
                    url: `abco-po-process/${poProcessId}/update`,
                    method: "post",
                    body: values,
                };
            },
            // invalidatesTags: ["poProcessDetails"],
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    usePoProcessListQuery,
    usePoProcessTableListQuery,
    useMonthlyInvListQuery,
    useUnLoadedPoListQuery,
    useAbcoPoDataQuery,
    usePoTypeListQuery,
    useTmcContractTypeListQuery,
    usePoCurrencyListQuery,
    usePoCompanyListQuery,
    usePoCountryListQuery,
    usePoCityListQuery,
    usePoStateListQuery,
    usePoRegionListQuery,
    useViewLoadedPosQuery,
    useMonthlyInvSalesPurchaseReportQuery,
    usePaymentDueDaysQuery,
    usePaymentDueDaysApprovalEmailsListQuery,
    useInvoiceRaiseInQuery,
    useInvoiceCycleQuery,
    useInvoiceTypeQuery,
    useMonthlyInvoiceMonthsQuery,
    useDraftListQuery,
    useDraftEditQuery,
    usePoProcessEditQuery,
    useMonthlyInvEditQuery,
    useOemListQuery,
    useSlaListQuery,
    useProductTypeListQuery,
    useNewModelListQuery,
    useNotificationCountQuery,
    useNotificationListQuery,
    useSupportTypeListQuery,
    useGenerateUrnMutation,
    useSaveAsDraftMutation,
    useSendForApprovalMutation,
    useSendMonthlyInvMutation,
    useUpdateMonthlyInvMutation,
    useUpdatePoMutation,
} = poProcessApi;
