import React from "react";
import {
    useMotivationalQuoteQuery,
    useRewardsQuery,
} from "../../api/dashboardApi";

const Dashboard = () => {
    const { data: motivationalQuote } = useMotivationalQuoteQuery();
    const { data, isSuccess } = useRewardsQuery();

    return (
        <div className="w-11/12 mx-auto mt-7 bg-gradient-to-b">
            <div className="flex flex-col items-center justify-center p-5">
                <p className="text-2xl">Welcome, </p>
                <p className="text-orange text-xl font-mono mt-5">
                    {motivationalQuote?.quote}
                </p>
            </div>
            {isSuccess && (
                <div className="flex mt-5 flex-wrap">
                    <Rewards
                        details={data?.weekly_reward}
                        type="Performer of the Week"
                    />
                    <Rewards
                        details={data?.monthly_reward}
                        type="Achiever of the Month"
                    />
                    <Rewards
                        details={data?.quaterly_reward}
                        type="Champion of the quarter"
                    />
                </div>
            )}
        </div>
    );
};

const Rewards = ({ details, type }) => {
    return (
        <div className="card group flex-col justify-center shadow-xl items-center mx-auto">
            <div className="card-body">
                <img
                    className="h-40 w-h-40 rounded-full"
                    src={details.image}
                    alt=""
                />
                <div className="">
                    <p className="text-sm text-center font-medium text-slate-700 group-hover:text-slate-900 mt-6">
                        {details.name}
                    </p>
                    <p className="text-sm text-center font-medium text-slate-500 group-hover:text-slate-700">
                        {type}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
