import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { IconContext } from "react-icons";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <IconContext.Provider value={{ size: "1.5rem" }}>
                <App />
            </IconContext.Provider>
        </Provider>
    </BrowserRouter>
    // {/* </React.StrictMode> */}
);
