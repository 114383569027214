import { Field, FieldArray } from "formik";
import React, { Fragment, memo, useEffect } from "react";
import {
    useInvoiceCycleQuery,
    useInvoiceRaiseInQuery,
    usePaymentDueDaysQuery,
} from "../../api/poProcessApi";
import { FiMinus, FiPlus } from "react-icons/fi";
import { PaymentDueDaysApproved } from "./MonthlyInvForm";
import { endDate } from "../../helper";

const IRSDetails = ({
    dueDaysId,
    primaryContact,
    setFieldValue,
    cstartDate,
    spocEmail,
    spocName,
    spocPhoneNumber,
}) => {
    const { data: invoiceCycleList } = useInvoiceCycleQuery();
    const { data: invoiceRaiseInList } = useInvoiceRaiseInQuery();
    const { data: paymentDueDaysList } = usePaymentDueDaysQuery();

    useEffect(() => {
        if (cstartDate.length !== 0) {
            setFieldValue("contractEndDate", endDate(cstartDate));
        }
    }, [cstartDate, setFieldValue]);

    useEffect(() => {
        if ((spocEmail && spocName && spocPhoneNumber) !== "") {
            setFieldValue("primaryContactList[0].email", spocEmail);
            setFieldValue("primaryContactList[0].name", spocName);
            setFieldValue("primaryContactList[0].phoneNumber", spocPhoneNumber);
        }
    }, [setFieldValue, spocEmail, spocName, spocPhoneNumber]);

    return (
        <>
            <div className="col-span-12 form-group-title text-center text-xl">
                IRS Details
            </div>

            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="" className="form-label">
                    Vendor Code
                </label>
                <Field
                    type="text"
                    name="vendorCode"
                    id="vendorCode"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="" className="form-label">
                    Contract Start Date
                </label>
                <Field
                    type="date"
                    name="contractStartDate"
                    id="contractStartDate"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="" className="form-label">
                    Contract End Date
                </label>
                <Field
                    type="date"
                    name="contractEndDate"
                    id="contractEndDate"
                    className="form-input"
                />
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <label htmlFor="" className="form-label">
                    Invoice Cycle
                </label>
                <Field
                    as="select"
                    name="invoiceCycle"
                    id="invoiceCycle"
                    className=" form-select"
                >
                    <option value="">Select Invoice Cycle</option>
                    {invoiceCycleList?.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </Field>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <label htmlFor="" className="form-label">
                    Invoice Raise In
                </label>
                <Field
                    as="select"
                    name="invoiceRaiseIn"
                    id="invoiceRaiseIn"
                    className=" form-select"
                >
                    <option value="">Select Invoice Raise In</option>
                    {invoiceRaiseInList?.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </Field>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <label htmlFor="" className="form-label">
                    Payment Due Days
                </label>
                <Field
                    as="select"
                    name="paymentDueDaysId"
                    id="paymentDueDaysId"
                    className=" form-select"
                >
                    <option value="">Select Payment Due Days</option>
                    {paymentDueDaysList?.map(({ id, days }) => (
                        <option key={id} value={id}>
                            {days}
                        </option>
                    ))}
                </Field>
            </div>

            <PaymentDueDaysApproved
                list={paymentDueDaysList}
                // id={values.paymentDueDaysId}
                id={dueDaysId}
            />
            <div className="col-span-12 lg:col-span-6">
                <label htmlFor="" className="form-label">
                    GST Number
                </label>
                <Field
                    type="text"
                    name="gstNumber"
                    id="gstNumber"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label htmlFor="" className="form-label">
                    Invoice To
                </label>
                <Field
                    type="text"
                    name="invoiceTo"
                    id="invoiceTo"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label htmlFor="" className="form-label">
                    Invoice Bill To
                </label>
                <Field
                    as="textarea"
                    name="invoiceBillTo"
                    id="invoiceBillTo"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label htmlFor="" className="form-label">
                    Invoice Ship To
                </label>
                <Field
                    as="textarea"
                    name="invoiceShipTo"
                    id="invoiceShipTo"
                    className="form-input"
                />
            </div>

            {/* <div className="col-span-12 form-group-title">Primary Contact</div>
            <FieldArray name="primaryContactList">
                {({ push, remove }) => (
                    <>
                        {primaryContact?.map((_, i) => (
                            <Fragment key={i}>
                                <div className="col-span-12 lg:col-span-4">
                                    <label htmlFor="" className="form-label">
                                        Name
                                    </label>
                                    <Field
                                        type="text"
                                        name={`primaryContactList.${i}.name`}
                                        id={`primaryContactList.${i}.name`}
                                        className="form-input"
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-3">
                                    <label htmlFor="" className="form-label">
                                        Phone Number
                                    </label>
                                    <Field
                                        type="text"
                                        name={`primaryContactList.${i}.phoneNumber`}
                                        id={`primaryContactList.${i}.phoneNumber`}
                                        className="form-input"
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-4">
                                    <label htmlFor="" className="form-label">
                                        Email
                                    </label>
                                    <Field
                                        type="email"
                                        name={`primaryContactList.${i}.email`}
                                        id={`primaryContactList.${i}.email`}
                                        className="form-input"
                                    />
                                </div>
                                {i ? (
                                    <div
                                        className="col-span-1 justify-self-center self-center"
                                        key={i + 3}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => remove(i)}
                                        >
                                            <FiMinus className="h-5 w-5" />
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className="col-span-1 justify-self-center self-center"
                                        key={i + 4}
                                    >
                                        <button
                                            type="button"
                                            onClick={() =>
                                                push({
                                                    name: "",
                                                    email: "",
                                                    phoneNumber: "",
                                                })
                                            }
                                        >
                                            <FiPlus className="h-5 w-5" />
                                        </button>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </>
                )}
            </FieldArray> */}
        </>
    );
};

export default memo(IRSDetails);
