import { apiSlice } from "./apiSlice";

export const authApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: "/login",
                method: "post",
                body: data,
            }),
        }),

        signOut: builder.mutation({
            query: () => ({
                url: "/user/sign-out?id=67",
                method: "delete",
            }),
        }),

        userDetails: builder.query({
            query: () => "abco-po-process/userDetails",
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { useLoginMutation, useSignOutMutation, useUserDetailsQuery } =
    authApi;
