import { Field, FieldArray, Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
import {
    useMonthlyInvoiceMonthsQuery,
    usePaymentDueDaysApprovalEmailsListQuery,
    usePaymentDueDaysQuery,
    useSendMonthlyInvMutation,
    useUpdateMonthlyInvMutation,
} from "../../api/poProcessApi";
import { FiMinus, FiPlus } from "react-icons/fi";
import { PurchaseDetails, SalesDetails } from "./SPRDetails";
import { CgSpinnerAlt } from "react-icons/cg";
import { toast } from "react-toastify";

const yearList = [];
for (let i = 2020; i < 2050; i++) {
    yearList.push({ id: +i, value: +i });
}

const MonthlyInvForm = ({
    data,
    type,
    poProcessId,
    month,
    year,
    urn,
    sprInvoiceDetailsId,
    setOpen,
}) => {
    const { data: paymentDueDaysList } = usePaymentDueDaysQuery();
    const { data: monthsList } = useMonthlyInvoiceMonthsQuery();
    const [sendMonthlyInv] = useSendMonthlyInvMutation();
    const [updateMonthlyInv] = useUpdateMonthlyInvMutation();

    const initialValues = {
        spareShipTo: data?.spare_ship_to || "",
        gstNumber: data?.gst_no || "",
        contractStartDate: data?.contract_start_date || "",
        contractEndDate: data?.contract_end_date || "",
        year: year || null,
        month: month || null,
        invoiceTo: data?.invoice_to || "",
        invoiceBillTo: data?.invoice_bill_to || "",
        invoiceShipTo: data?.invoice_ship_to || "",
        paymentDueDays: 0,
        paymentDueDaysId: data?.payment_due_days_id || "",
        paymentDueDaysApproval: "",
        primaryContactList: data?.primaryContactList?.map(
            ({ name, email, phone_number: phoneNumber }) => ({
                name,
                email,
                phoneNumber,
            })
        ) || [
            {
                name: "",
                phoneNumber: "",
                email: "",
            },
        ],
        tnmPurchaseData: data?.monthlyInvoicePurchaseData?.map(
            ({
                description,
                quantity: qty,
                rate,
                remarks,
                supplier_name: supplierName,
            }) => ({ supplierName, description, qty, rate, remarks })
        ) || [
            {
                supplierName: "",
                description: "",
                qty: "",
                rate: "",
                remarks: "",
            },
        ],
        dutyAmount: 0,
        shipmentAmount: 0,
        tnmSalesData: data?.monthlyInvoiceSalesData?.map(
            ({ description, quantity: qty, rate, remarks }) => ({
                description,
                qty,
                rate,
                remarks,
            })
        ) || [
            {
                description: "",
                qty: "",
                rate: "",
                remarks: "",
            },
        ],
        reason: "",
    };

    const sendForm = async (values) => {
        console.log({ values });
        try {
            if (type === "CREATE") {
                const resp = await sendMonthlyInv({
                    abcoId: poProcessId,
                    urn,
                    sprInvoiceDetailsId,
                    ...values,
                }).unwrap();
                console.log(resp);
                toast.success(resp.message);
                setOpen(false);
            } else {
                const resp = await updateMonthlyInv({
                    ...values,
                    abcoId: poProcessId,
                    urn,
                    sprInvoiceDetailsId,
                    method: "put",
                }).unwrap();
                toast.success(resp.message);
                setOpen(false);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.data.error);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => sendForm(values)}
            >
                {({ isSubmitting, values, ...props }) => (
                    <form onSubmit={props.handleSubmit}>
                        <div className="overflow-hidden  sm:rounded-md">
                            <div className="border-b-2  px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="partnerAccount"
                                            className="form-label"
                                        >
                                            Select Invoice Year
                                        </label>
                                        <Field
                                            as="select"
                                            name="year"
                                            id="year"
                                            className=" form-select"
                                        >
                                            <option value="">
                                                Select Year
                                            </option>
                                            {yearList?.map(({ id, value }) => (
                                                <option key={id} value={+id}>
                                                    {value}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="partnerAccount"
                                            className="form-label"
                                        >
                                            Select Invoice Month
                                        </label>
                                        <Field
                                            as="select"
                                            name="month"
                                            id="month"
                                            className=" form-select"
                                        >
                                            <option value="">
                                                Select Month
                                            </option>
                                            {monthsList?.map(({ id, name }) => (
                                                <option key={id} value={id}>
                                                    {name}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="meetingDataTime"
                                            className="form-label"
                                        >
                                            Contract Start Date
                                        </label>
                                        <Field
                                            type="date"
                                            name="contractStartDate"
                                            id="contractStartDate"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="meetingDataTime"
                                            className="form-label"
                                        >
                                            Contract End Date
                                        </label>
                                        <Field
                                            type="date"
                                            name="contractEndDate"
                                            id="contractEndDate"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="partnerAccount"
                                            className="form-label"
                                        >
                                            Payment Due Days
                                        </label>
                                        <Field
                                            as="select"
                                            name="paymentDueDaysId"
                                            id="paymentDueDaysId"
                                            className=" form-select"
                                        >
                                            <option value="">
                                                Select Payment Due Days
                                            </option>
                                            {paymentDueDaysList?.map(
                                                ({ id, days }) => (
                                                    <option key={id} value={id}>
                                                        {days}
                                                    </option>
                                                )
                                            )}
                                        </Field>
                                    </div>
                                    <PaymentDueDaysApproved
                                        list={paymentDueDaysList}
                                        id={values.paymentDueDaysId}
                                    />
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="meetingDataTime"
                                            className="form-label"
                                        >
                                            GST Number
                                        </label>
                                        <Field
                                            type="text"
                                            name="gstNumber"
                                            id="gstNumber"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-3">
                                        <label
                                            htmlFor="meetingDataTime"
                                            className="form-label"
                                        >
                                            Invoice To
                                        </label>
                                        <Field
                                            type="text"
                                            name="invoiceTo"
                                            id="invoiceTo"
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="col-span-6 lg:col-span-4">
                                        <label
                                            htmlFor="designation"
                                            className="form-label"
                                        >
                                            Invoice Bill To
                                        </label>
                                        <Field
                                            as="textarea"
                                            className="form-input"
                                            name="invoiceBillTo"
                                            id="invoiceBillTo"
                                            cols="30"
                                            rows="3"
                                        ></Field>
                                    </div>
                                    <div className="col-span-6 lg:col-span-4">
                                        <label
                                            htmlFor="designation"
                                            className="form-label"
                                        >
                                            Invoice Ship To
                                        </label>
                                        <Field
                                            as="textarea"
                                            className="form-input"
                                            name="invoiceShipTo"
                                            id="invoiceShipTo"
                                            cols="30"
                                            rows="3"
                                        ></Field>
                                    </div>
                                    <div className="col-span-6 lg:col-span-4">
                                        <label
                                            htmlFor="designation"
                                            className="form-label"
                                        >
                                            Service Deliver To
                                        </label>
                                        <Field
                                            as="textarea"
                                            className="form-input"
                                            name="spareShipTo"
                                            id="spareShipTo"
                                            cols="30"
                                            rows="3"
                                        ></Field>
                                    </div>
                                    <FieldArray name="primaryContactList">
                                        {({ remove, push }) => (
                                            <>
                                                <div className="flex justify-between col-span-12">
                                                    <div className="form-group-title">
                                                        Primary Contact
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="bg-gray-200 py-1 px-3 rounded-lg text-sm"
                                                            onClick={() =>
                                                                push({
                                                                    name: "",
                                                                    phoneNumber:
                                                                        "",
                                                                    email: "",
                                                                })
                                                            }
                                                        >
                                                            <FiPlus className="inline h-4 w-4 mr-1" />
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>

                                                {values.primaryContactList.map(
                                                    (_, i) => (
                                                        <>
                                                            <div
                                                                key={i}
                                                                className="col-span-6 lg:col-span-3"
                                                            >
                                                                <label
                                                                    htmlFor="participantFirstName"
                                                                    className="form-label"
                                                                >
                                                                    Name
                                                                </label>

                                                                <Field
                                                                    type="text"
                                                                    name={`primaryContactList.${i}.name`}
                                                                    id={`primaryContactList.${i}.name`}
                                                                    className="form-input"
                                                                />
                                                            </div>
                                                            <div
                                                                key={i + 1}
                                                                className="col-span-6 lg:col-span-3"
                                                            >
                                                                <label
                                                                    htmlFor="participantLastName"
                                                                    className="form-label"
                                                                >
                                                                    Phone Number
                                                                </label>

                                                                <Field
                                                                    type="text"
                                                                    name={`primaryContactList.${i}.phoneNumber`}
                                                                    id={`primaryContactList.${i}.phoneNumber`}
                                                                    className="form-input"
                                                                />
                                                            </div>
                                                            <div
                                                                key={i + 2}
                                                                className="col-span-6 lg:col-span-3"
                                                            >
                                                                <label
                                                                    htmlFor="participantDesignation"
                                                                    className="form-label"
                                                                >
                                                                    Email
                                                                </label>

                                                                <Field
                                                                    type="text"
                                                                    name={`primaryContactList.${i}.email`}
                                                                    id={`primaryContactList.${i}.email`}
                                                                    className="form-input"
                                                                />
                                                            </div>

                                                            <div className="col-span-1 justify-self-center self-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        remove(
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    <FiMinus className="h-5 w-5" />
                                                                </button>
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                    <div className="col-span-12">
                                        {/* <BOQDetails /> */}
                                    </div>
                                    <div className="col-span-12">
                                        <SalesDetails
                                            values={values.tnmSalesData}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <PurchaseDetails
                                            values={values.tnmPurchaseData}
                                            dutyAmount={values.dutyAmount}
                                            shipmentAmount={
                                                values.shipmentAmount
                                            }
                                        />
                                    </div>
                                    {type === "EDIT" && (
                                        <div className="col-span-12">
                                            <label
                                                htmlFor="reason"
                                                className="form-label"
                                            >
                                                Reason for Resending
                                            </label>
                                            <Field
                                                as="textarea"
                                                className="form-input"
                                                name="reason"
                                                id="reason"
                                                cols="30"
                                                rows="3"
                                            ></Field>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="form-action">
                                <button
                                    type="submit"
                                    className="form-btn-primary"
                                >
                                    {isSubmitting && (
                                        <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                    )}
                                    Send Invoice
                                </button>
                                <button
                                    onClick={() => setOpen(false)}
                                    type="button"
                                    className="form-btn-secondary"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default MonthlyInvForm;

export const PaymentDueDaysApproved = ({ list, id }) => {
    const { data } = usePaymentDueDaysApprovalEmailsListQuery();
    const { values, setFieldValue } = useFormikContext();

    let paymentDueDays;
    if (list) {
        list.forEach((element) => {
            if (element.id === +id) {
                paymentDueDays = element.days;
            }
        });
    }

    useEffect(() => {
        setFieldValue("paymentDueDays", paymentDueDays);
    }, [paymentDueDays, setFieldValue, values.paymentDueDays]);

    if (+paymentDueDays > 59)
        return (
            <div className="col-span-6 lg:col-span-3">
                <label htmlFor="partnerAccount" className="form-label">
                    Payment Due Days Approved By
                </label>
                <Field
                    as="select"
                    name="paymentDueDaysApproval"
                    id="paymentDueDaysApproval"
                    className=" form-select"
                >
                    <option value="">Select Approved By</option>
                    {data?.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </Field>
            </div>
        );
};
