const Footer = () => {
    const date = new Date();

    return (
        <footer className="dark:bg-rich-black-2 text-xs sm:text-sm  text-center py-5 border-t dark:border-gray-800 text-gray-600 font-medium dark:text-gray-300 transition-colors duration-300">
            <p>
                Copyright &#169;
                <span className="text-orange">
                    Solid Tech {date.getFullYear()}.{" "}
                </span>
                All Rights Reserved
            </p>
        </footer>
    );
};

export default Footer;
