import React, { useEffect, useState } from "react";
import { TiDocumentAdd } from "react-icons/ti";
import { FcViewDetails } from "react-icons/fc";
import { BiEditAlt } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    useMonthlyInvEditQuery,
    useMonthlyInvListQuery,
} from "../../api/poProcessApi";
import { reset, setTotalCount } from "../../features/tableSlice";
import DataTable from "../../components/Tables/DataTable";
import Modal from "../../components/modal";
import Report from "./Report";
import MonthlyInvForm from "./MonthlyInvForm";
import { setLoading } from "../../features/toggleSlice";
import { EditPoProcess } from "./PoProcessTable";
import { BarLoader } from "react-spinners";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";
import { ToastContainer } from "react-toastify";

const MonthlyInvTable = () => {
    const dispatch = useDispatch();

    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );

    const { data, isLoading, isFetching, isError, error } =
        useMonthlyInvListQuery({
            pageIndex,
            rowCount,
            pageFilter,
        });

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const [newInvModal, setNewInvModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [editMonthlyInvModal, setEditMonthlyInvModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const [type, setType] = useState("");
    const [isSpr, setIsSpr] = useState(null);
    const [poProcessId, setPoProcessId] = useState(null);
    const [poReference, setPoReference] = useState("");
    const [invoice, setInvoice] = useState({
        month: "",
        year: "",
        SPRInvDetailId: "",
    });

    useEffect(() => {
        dispatch(setTotalCount(data?.last_page));
    }, [data?.last_page, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    const tableData = data?.data;

    const tableColumns = [
        {
            Header: "SI no",
            Cell: ({ row: { index } }) => index + 1,
        },
        {
            Header: "Reference No",
            accessor: "reference_number",
        },
        {
            Header: "Solid Sequence No",
            accessor: "solid_sequence_number",
        },
        {
            Header: "Key Account Manager",
            accessor: "kam",
        },
        {
            Header: "Customer Name",
            accessor: "partner",
        },
        {
            Header: "Status",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <span
                    className={`${
                        value.is_approved && !value.is_cancelled
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                    } p-1 rounded-md whitespace-nowrap align-middle`}
                >
                    {value.is_cancelled
                        ? "This PO has been Cancelled"
                        : value.is_approved
                        ? "Verified & Loaded"
                        : "Not Verified"}
                </span>
            ),
        },
        {
            Header: "Generated Monthly Invoice",
            columns: [
                {
                    Header: "Month",
                    accessor: (row) => row,
                    Cell: ({ value }) => {
                        let Invoices = [];

                        for (const [key, data] of Object.entries(
                            value.invoice_months
                        )) {
                            Invoices.push(`${key}: ${data}`);
                        }

                        if (Invoices.length === 0) return "---";

                        return Invoices.map((invoice, i) => (
                            <div
                                className="flex justify-between last:border-none py-2 border-b"
                                key={i}
                            >
                                <span>
                                    {Object.values(invoice.split(":")[1])}
                                </span>

                                <button
                                    className="disabled:opacity-50"
                                    disabled={!value.is_approved}
                                    onClick={() => {
                                        setEditMonthlyInvModal(true);

                                        setPoProcessId(value.id);
                                        setPoReference(value.reference_number);
                                        setType("EDIT");

                                        setInvoice((prev) => ({
                                            ...prev,
                                            month: invoice.split(":")[0],
                                            year: invoice
                                                .split(":")[1]
                                                .split("-")[1],
                                            SPRInvDetailId: invoice
                                                .split(":")[1]
                                                .split("-")[2],
                                        }));
                                    }}
                                >
                                    <BiEditAlt className="text-blue-500" />
                                </button>
                            </div>
                        ));
                    },
                },
            ],
        },

        {
            Header: "Action",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <span className="flex">
                    <button
                        disabled={!value.is_approved}
                        onClick={() => {
                            setPoProcessId(value.id);
                            setPoReference(value.reference_number);
                            setNewInvModal(true);
                            setType("CREATE");
                        }}
                        className="disabled:opacity-50 hover:bg-blue-200 p-2 rounded-full"
                    >
                        <TiDocumentAdd className="h-5 w-5 text-blue-500" />
                    </button>

                    <button
                        disabled={value.is_cancelled}
                        onClick={() => {
                            setPoProcessId(value.id);
                            setIsSpr(value.isSpr);
                            setViewModal(true);
                        }}
                        className="disabled:opacity-50 hover:bg-blue-200 p-2 rounded-full"
                    >
                        <FcViewDetails className="h-5 w-5 text-blue-500" />
                    </button>

                    <button
                        disabled={
                            value.is_cancelled ||
                            value.invoice_months.length === undefined
                        }
                        onClick={() => {
                            setPoProcessId(value.id);
                            setPoReference(value.reference_number);
                            setEditModal(true);
                        }}
                        className="disabled:opacity-50 hover:bg-blue-200 p-2 rounded-full"
                    >
                        <FiEdit3 className="h-5 w-5 text-blue-500" />
                    </button>
                </span>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="p-7 bg-white rounded-lg shadow-xl dark:bg-raisin-black">
                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />

                        <Modal
                            open={editMonthlyInvModal}
                            setOpen={setEditMonthlyInvModal}
                            title="SPR Details For Current Month"
                        >
                            <EditMonthlyInv
                                id={poProcessId}
                                reference={poReference}
                                invoice={invoice}
                                type={type}
                                setOpen={setEditMonthlyInvModal}
                            />
                        </Modal>

                        <Modal
                            open={viewModal}
                            setOpen={setViewModal}
                            title="Sales Purchase Report"
                        >
                            <Report id={poProcessId} isSpr={isSpr} />
                        </Modal>

                        <Modal
                            open={newInvModal}
                            setOpen={setNewInvModal}
                            title="SPR Details For Current Month"
                        >
                            <MonthlyInvForm
                                type={type}
                                poProcessId={poProcessId}
                                urn={poReference}
                                sprInvoiceDetailsId={invoice.SPRInvDetailId}
                                setOpen={setNewInvModal}
                            />
                        </Modal>

                        <Modal open={editModal} setOpen={setEditModal}>
                            <EditPoProcess
                                id={poProcessId}
                                referenceNumber={poReference}
                                setOpen={setEditModal}
                            />
                        </Modal>
                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                            theme="colored"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default MonthlyInvTable;

const EditMonthlyInv = ({ id, reference, invoice, type, setOpen }) => {
    const { month, year, SPRInvDetailId } = invoice;
    const { data, isLoading, isError, error } = useMonthlyInvEditQuery({
        id,
        month,
        year,
        SPRInvDetailId,
    });

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : isLoading ? (
                <div className="w-full my-5">
                    <BarLoader color="#E8687B" className="mx-auto" />
                </div>
            ) : (
                <MonthlyInvForm
                    data={data}
                    type={type}
                    month={month}
                    year={year}
                    poProcessId={id}
                    urn={reference}
                    sprInvoiceDetailsId={SPRInvDetailId}
                    setOpen={setOpen}
                />
            )}
        </>
    );
};
