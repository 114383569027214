import React from "react";
import { useSelector } from "react-redux";
import { BarLoader } from "react-spinners";

const Loader = () => {
    const { loading } = useSelector((state) => state.toggle);

    return (
        <BarLoader
            width={"100%"}
            color="#E8687B"
            loading={loading}
            cssOverride={{ position: "fixed", zIndex: 1 }}
        />
    );
};

export default Loader;
