import React from "react";
import { useViewLoadedPosQuery } from "../../api/poProcessApi";
import Table from "../../components/Tables/Table";
import { FiUser } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { GiSmartphone } from "react-icons/gi";
import { GrDocumentDownload } from "react-icons/gr";
import { salesTotalAmount, totalNLC } from "../../helper";
import { BarLoader } from "react-spinners";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";

const Report = ({ id, isSpr }) => {
    const { data, isLoading, isError, error } = useViewLoadedPosQuery(id);

    const totalAmount = salesTotalAmount(data?.tnm_sales_data);
    const totalNlc = totalNLC(
        data?.tnm_purchase_data,
        data?.tnm_purchase_data && data?.tnm_purchase_data[0].duty_amount,
        data?.tnm_purchase_data && data?.tnm_purchase_data[0].shipment_amount
    );

    const grossProfit = totalAmount - totalNlc;
    const grossProfitPercentage =
        totalNlc === 0
            ? 0
            : Math.round(((totalAmount - totalNlc) / totalNlc) * 100);

    return (
        <div>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : isLoading ? (
                <div className="w-full my-5">
                    <BarLoader color="#E8687B" className="mx-auto" />
                </div>
            ) : (
                <>
                    <div className="flex py-6 px-4">
                        <ReportInfo
                            title="Solid Sequence Number"
                            value={data?.solid_sequence_number}
                        />
                        {isSpr && (
                            <ReportInfo
                                title="SPR DATE"
                                value={data?.spr_date}
                            />
                        )}
                        <ReportInfo title="URN" value={data?.urn} />
                    </div>
                    <dl className="divide-y divide-gray-100 dark:divide-gray-700 grid md:grid-cols-2 grid-cols-1">
                        <InvDetails
                            col={1}
                            title="Invoice To"
                            value={data?.invoice_to}
                        />
                        <InvDetails
                            col={1}
                            title="End Customer"
                            value={data?.end_customer_name}
                        />
                        <InvDetails
                            col={1}
                            title="GST Number"
                            value={data?.gst_no}
                        />
                        <InvDetails
                            col={1}
                            title="Key Account Manager"
                            value={data?.kam}
                        />
                        <InvDetails
                            col={2}
                            title="Invoice Bill To"
                            value={data?.invoice_bill_to}
                        />
                        <InvDetails
                            col={2}
                            title="Invoice Ship To"
                            value={data?.invoice_ship_to}
                        />
                        <InvDetails
                            col={2}
                            title="Spare Ship to"
                            value={data?.spare_ship_to}
                        />
                    </dl>
                    <div className="columns-2">
                        <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                            <h6 className="text-lg font-bold text-gray-800 dark:text-gray-200  mb-4 ">
                                Primary Contacts
                            </h6>
                            <div className="space-y-4">
                                <ContactList
                                    icon={<FiUser />}
                                    title={
                                        data?.primary_contact &&
                                        data?.primary_contact[0]?.name
                                    }
                                    subtitle="Name"
                                />
                                <ContactList
                                    icon={<HiOutlineMail />}
                                    title={
                                        data?.primary_contact &&
                                        data?.primary_contact[0]?.email
                                    }
                                    subtitle="Email"
                                />
                                <ContactList
                                    icon={<GiSmartphone />}
                                    title={
                                        data?.primary_contact &&
                                        data?.primary_contact[0]?.phone_number
                                    }
                                    subtitle="Mobile No"
                                />
                            </div>
                        </div>
                        <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                            <h6 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-4 ">
                                Customer SPOC
                            </h6>
                            <div className="space-y-4">
                                <ContactList
                                    icon={<FiUser />}
                                    title={data?.customer_spoc_name}
                                    subtitle="Name"
                                />
                                <ContactList
                                    icon={<HiOutlineMail />}
                                    title={data?.customer_spoc_email}
                                    subtitle="Email"
                                />
                                <ContactList
                                    icon={<GiSmartphone />}
                                    title={data?.customer_spoc_phone_number}
                                    subtitle="Mobile No"
                                />
                            </div>
                        </div>
                    </div>
                    {!isSpr && (
                        <>
                            <div className="flex bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                                <List
                                    title="Customer Order Number"
                                    value={data?.customer_po_number}
                                />
                                <List
                                    title="Customer Order Date"
                                    value={data?.po_date}
                                />
                                <List
                                    title="Contract Start Date"
                                    value={data?.contract_start_date}
                                />
                                <List
                                    title="Contract End Date"
                                    value={data?.contract_end_date}
                                />
                            </div>
                            <div className="flex bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                                <List
                                    title="Invoice Cycle"
                                    value={data?.invoice_cycle}
                                />
                                <List
                                    title="Invoice Raise in"
                                    value={data?.invoice_raise}
                                />
                                <List
                                    title="Payment Due Days"
                                    value={`${data?.payment_due_days} Days`}
                                />
                                <List title="PO Type" value={data?.po_type} />
                            </div>
                        </>
                    )}
                    {isSpr && (
                        <>
                            <div className="flex bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                                <List
                                    title="Customer Order Number"
                                    value={data?.customer_po_number}
                                />

                                <List
                                    title="Customer Order Date"
                                    value={data?.po_date}
                                />

                                <List
                                    title="Invoice Due Date"
                                    value={data?.invoice_due_date}
                                />
                            </div>
                            <div className="flex bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                                <List
                                    title="Payment Due Date"
                                    value={data?.payment_due_date}
                                />

                                <List
                                    title="Payment Terms"
                                    value={`${data?.payment_due_days} Days`}
                                />

                                <List title="PO Type" value={data?.po_type} />
                            </div>
                        </>
                    )}
                    {(data?.is_renewal_po ||
                        data?.is_penalty ||
                        data?.is_any_prefailures ||
                        data?.payment_due_days > 59) && (
                        <div className="flex bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                            <List
                                title="Old Solid Sequence Number"
                                value={data?.old_solid_sequence_number}
                            />

                            <List
                                title="Penalty Terms"
                                value={data?.penalty_terms}
                            />

                            <List
                                title="Prefailures"
                                value={data?.prefailures}
                            />
                            <List
                                title="Payment Term Approved By"
                                value={data?.payment_due_days_approved_by}
                            />
                        </div>
                    )}
                    {data?.boq_details && data?.boq_details?.length !== 0 && (
                        <BoqDetails data={data?.boq_details} />
                    )}
                    {isSpr && (
                        <>
                            {(data?.contract_start_date ||
                                data?.contract_end_date ||
                                data?.tmc_contract_type) && (
                                <div className="flex bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                                    <List
                                        title="Contract Strat Date"
                                        value={data?.contract_start_date}
                                    />
                                    <List
                                        title="Contract End Date"
                                        value={data?.contract_end_date}
                                    />
                                    <List
                                        title="TMC Contract Type"
                                        value={data?.tmc_contract_type}
                                    />
                                </div>
                            )}

                            {data?.tnm_sales_data &&
                                data?.tnm_sales_data?.length !== 0 && (
                                    <>
                                        <TmnSalesTable
                                            data={data?.tnm_sales_data}
                                            poType={data?.po_type}
                                        />
                                        <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mt-3 mb-5">
                                            <DescriptionLists
                                                title={"Total"}
                                                value={`${data?.po_currency} ${totalAmount}`}
                                            />
                                        </div>
                                    </>
                                )}

                            {data?.tnm_purchase_data &&
                                data?.tnm_purchase_data?.length !== 0 && (
                                    <>
                                        <TmnPurchaseTable
                                            data={data?.tnm_purchase_data}
                                            poType={data?.po_type}
                                        />
                                        <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mt-3 mb-5">
                                            <DescriptionLists
                                                title="If International Purchase, Approx, Duty Amount"
                                                value={
                                                    data?.tnm_purchase_data &&
                                                    `${data?.po_currency} ${data?.tnm_purchase_data[0]?.duty_amount}`
                                                }
                                            />
                                            <DescriptionLists
                                                title="If International Purchase, Approx, Shipment Amount"
                                                value={
                                                    data?.tnm_purchase_data &&
                                                    `${data?.po_currency} ${data?.tnm_purchase_data[0]?.shipment_amount}`
                                                }
                                            />
                                            <DescriptionLists
                                                title="Total NLC(Net Landing Cost,Excl. Tax)"
                                                value={`${data?.po_currency} ${totalNlc}`}
                                            />
                                            <DescriptionLists
                                                title="Gross Profit"
                                                value={`${grossProfit} (${grossProfitPercentage}%) `}
                                            />
                                        </div>
                                    </>
                                )}

                            {data?.spr_details &&
                                data?.spr_details?.length !== 0 && (
                                    <SprDetailsTable data={data?.spr_details} />
                                )}
                        </>
                    )}
                    {!isSpr && (
                        <>
                            {data?.irs_table && data.irs_table.length > 0 && (
                                <>
                                    <IrsTable
                                        data={data?.irs_table}
                                        poAmount={data?.po_amount}
                                        poCurrency={data?.po_currency}
                                    />
                                    <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mt-3 mb-5">
                                        <DescriptionLists
                                            title={"Total"}
                                            value={`${data?.po_currency} ${data?.po_amount}
                  `}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5 mt-5">
                        <h3 className="text-xl mb-3 dark:text-gray-300">
                            Attachments
                        </h3>
                        <dl className="rounded-md text-sm flex flex-col">
                            {data?.attachments &&
                                data?.attachments.map(
                                    ({ file_name, file_link }, i) => (
                                        <DescriptionLists
                                            title={file_name}
                                            value={
                                                <a
                                                    href={file_link}
                                                    download={file_name}
                                                    type="button"
                                                >
                                                    <GrDocumentDownload />
                                                </a>
                                            }
                                            key={i}
                                        />
                                    )
                                )}
                        </dl>
                    </div>
                    <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5">
                        <h3 className="text-xl mb-3 dark:text-gray-300">
                            Invoices
                        </h3>
                        <dl className="rounded-md text-sm flex flex-col">
                            {data?.invoice_attachments?.EXCEL &&
                                data?.invoice_attachments?.EXCEL.map(
                                    ({ file_name, file_link }, i) => (
                                        <DescriptionLists
                                            title={file_name}
                                            value={
                                                <a
                                                    href={file_link}
                                                    download={file_name}
                                                    type="button"
                                                >
                                                    <GrDocumentDownload />
                                                </a>
                                            }
                                            key={i}
                                        />
                                    )
                                )}
                        </dl>
                    </div>
                </>
            )}
        </div>
    );
};

export default Report;

const ReportInfo = ({ title, value }) => {
    return (
        <div className="flex-auto">
            <dt className="text-base leading-7 text-gray-600">{title}</dt>
            <dd className="text-xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                {value}
            </dd>
        </div>
    );
};

const InvDetails = ({ title, value, col }) => {
    return (
        <div
            className={`px-4 py-6 col-span-${col} border-t border-t-gray-100 dark:border-gray-700`}
        >
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                {title}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-300 sm:col-span-2 sm:mt-0">
                {value}
            </dd>
        </div>
    );
};

const ContactList = ({ icon, title }) => {
    return (
        <div className="group flex items-center space-x-2">
            <div className="rounded-full">{icon}</div>
            <div className="ml-3 ">
                <p className="text-sm font-medium text-slate-700 group-hover:text-slate-900">
                    {title}
                </p>
            </div>
        </div>
    );
};

const List = ({ title, value }) => {
    return (
        <dl className="flex-auto">
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                {title}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-300 sm:mt-0">
                {value}
            </dd>
        </dl>
    );
};

const BoqDetails = ({ data }) => {
    const columns = [
        {
            Header: "Po No.",
            accessor: "customer_po_number",
        },
        {
            Header: "OEM",
            accessor: "oem",
        },
        {
            Header: "Model Number",
            accessor: "model_number",
        },

        {
            Header: "Serial Number",
            accessor: "serial_number",
        },
        {
            Header: "Category",
            accessor: "category",
        },
        {
            Header: "Product Type",
            accessor: "product_type",
        },
        {
            Header: "Support Type",
            accessor: "support_type",
        },
        {
            Header: "SLA",
            accessor: "sla",
        },
        {
            Header: "Start Date",
            accessor: "start_date",
        },
        {
            Header: "End Date",
            accessor: "end_date",
        },
        {
            Header: "Support Peroid",
            accessor: "support_period",
        },
        {
            Header: "Total Price",
            accessor: "price",
        },
        {
            Header: "Country",
            accessor: "country_name",
        },
        {
            Header: "State",
            accessor: "state_name",
        },
        {
            Header: "City",
            accessor: "city_name",
        },
        {
            Header: "Region",
            accessor: "region_name",
        },
        {
            Header: "Remarks",
            accessor: "boq_remarks",
        },
    ];

    return <Table tableColumns={columns} tableData={data} />;
};

const TmnSalesTable = ({ data, poType }) => {
    let columns = [
        {
            Header: "Description",
            accessor: "description",
        },

        {
            Header: "Qty",
            accessor: "quantity",
        },
        {
            Header: "Rate (Excl Tax)",
            accessor: "rate",
        },
        {
            Header: "Total Amount(Excl Tax)",
            accessor: (row) => row,
            Cell: ({ value }) => `${value.quantity * +value.rate}`,
        },
        {
            Header: "Remarks",
            accessor: "remarks",
        },
    ];

    if (poType === "RMC" || poType === "TMC") {
        columns.splice(
            1,
            0,
            {
                Header: "Invoice Raised Month",
                accessor: "month_id",
            },
            {
                Header: "Invoice Raised Year",
                accessor: "year",
            }
        );
    }
    console.log(columns);

    return <Table tableColumns={columns} tableData={data} />;
};

const TmnPurchaseTable = ({ data, poType }) => {
    let columns = [
        {
            Header: "Supplier Name",
            accessor: "supplier_name",
        },
        {
            Header: "Description",
            accessor: "description",
        },

        {
            Header: "Qty",
            accessor: "quantity",
        },
        {
            Header: "Rate (Excl Tax)",
            accessor: "rate",
        },
        {
            Header: "Total Amount(Excl Tax)",
            accessor: (row) => row,
            Cell: ({ value }) => `${value.quantity * +value.rate}`,
        },
        {
            Header: "Remarks",
            accessor: "remarks",
        },
    ];

    if (poType === "RMC" || poType === "TMC") {
        columns.splice(
            1,
            0,
            {
                Header: "Invoice Raised Month",
                accessor: "month_id",
            },
            {
                Header: "Invoice Raised Year",
                accessor: "year",
            }
        );
    }

    return <Table tableColumns={columns} tableData={data} />;
};

const SprDetailsTable = ({ data }) => {
    const columns = [
        {
            Header: "Spr No",
            accessor: "spr_no",
            Cell: ({ value }) => `T-${value}`,
        },
        {
            Header: "Invoice Due Date",
            accessor: "invoice_due_date",
        },
        {
            Header: "Payment Due Date",
            accessor: "payment_due_date",
        },
    ];

    return <Table tableColumns={columns} tableData={data} />;
};

const IrsTable = ({ data, poCurrency, poAmount }) => {
    const columns = [
        {
            Header: "IRS No",
            accessor: "irs_no",
        },
        {
            Header: "Po Amount",
            Cell: () => poAmount,
        },
        {
            Header: "Invoice For the Period of",
            columns: [
                {
                    Header: "From",
                    accessor: "invoice_from_date",
                },
                {
                    Header: "To",
                    accessor: "invoice_to_date",
                },
            ],
        },
        {
            Header: "Invoice Due Date",
            accessor: "invoice_due_date",
        },
        {
            Header: "Payment Due Date",
            accessor: "payment_due_date",
        },
        {
            Header: "Invoice Amount",
            accessor: "invoice_amount",
            Cell: ({ value }) => `${poCurrency} ${value}`,
        },
    ];

    return <Table tableColumns={columns} tableData={data} />;
};

const DescriptionLists = ({ title, value }) => {
    return (
        <div className="border-b flex items-baseline justify-between py-3 md:basis-1/2">
            <dt className="text-gray-500 dark:text-gray-400">{title}</dt>
            <dd className="text-gray-900 dark:text-gray-100">{value}</dd>
        </div>
    );
};
