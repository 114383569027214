import { apiSlice } from "./apiSlice";

const adminApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCountryList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/getCountryTable",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),

            providesTags: ["Post", "Delete", "Update"],
        }),

        getStateList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/getStateTable",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),

            providesTags: ["Post", "Delete", "Update"],
        }),

        getCityList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/getCityTable",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),

            providesTags: ["Post", "Delete", "Update"],
        }),

        getCountry: builder.query({
            query: () => "abco-po-process/create/country",
        }),

        getState: builder.query({
            query: () => "abco-po-process/create/state",
        }),

        getCity: builder.query({
            query: () => "abco-po-process/create/city",
        }),

        getCountryData: builder.query({
            query: (id) => `abco-po-process/${id}/editCountry`,
        }),

        getStateData: builder.query({
            query: (id) => `abco-po-process/${id}/editState`,
        }),

        getCityData: builder.query({
            query: (id) => `abco-po-process/${id}/editCity`,
        }),

        countryUpdate: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updateCountry`,
                method: "put",
                body: data,
            }),
            invalidatesTags: ["Update"],
        }),

        stateUpdate: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updateState`,
                method: "put",
                body: data,
            }),
            invalidatesTags: ["Update"],
        }),

        cityUpdate: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updateCity`,
                method: "put",
                body: data,
            }),
            invalidatesTags: ["Update"],
        }),

        deleteCountry: builder.mutation({
            query: (id) => ({
                url: `abco-po-process/${id}/deleteCountry`,
                method: "delete",
            }),
            invalidatesTags: ["Delete"],
        }),

        deleteState: builder.mutation({
            query: (id) => ({
                url: `abco-po-process/${id}/deleteState`,
                method: "delete",
            }),
            invalidatesTags: ["Delete"],
        }),

        deleteCity: builder.mutation({
            query: (id) => ({
                url: `abco-po-process/${id}/deleteCity`,
                method: "delete",
            }),
            invalidatesTags: ["Delete"],
        }),

        addCountry: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addCountry",
                method: "put",
                body: data,
            }),
            invalidatesTags: ["Post"],
        }),

        addState: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addState",
                method: "put",
                body: data,
            }),
            invalidatesTags: ["Post"],
        }),

        addCity: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addCity",
                method: "put",
                body: data,
            }),
            invalidatesTags: ["Post"],
        }),

        addSupportType: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addsupportType",
                method: "post",
                body: data,
            }),
            invalidatesTags: ["supportType"],
        }),

        editSupportType: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updatesupportType`,
                method: "post",
                body: data,
            }),
            invalidatesTags: ["supportType"],
        }),

        addOEM: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addoem",
                method: "post",
                body: data,
            }),
        }),

        editOEM: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updateoem`,
                method: "post",
                body: data,
            }),
        }),

        addSLA: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addsla",
                method: "post",
                body: data,
            }),
        }),

        editSLA: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updatesla`,
                method: "post",
                body: data,
            }),
        }),

        addProductType: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addproductType",
                method: "post",
                body: data,
            }),
        }),

        editProductType: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updateproductType`,
                method: "post",
                body: data,
            }),
        }),

        addNewModel: builder.mutation({
            query: (data) => ({
                url: "abco-po-process/addnewModel",
                method: "post",
                body: data,
            }),
        }),

        editNewModel: builder.mutation({
            query: ({ data, id }) => ({
                url: `abco-po-process/${id}/updatenewModel`,
                method: "post",
                body: data,
            }),
        }),
    }),
});

export const {
    useGetCountryListQuery,
    useGetStateListQuery,
    useGetCityListQuery,
    useGetCountryQuery,
    useGetStateQuery,
    useGetCityQuery,
    useGetCountryDataQuery,
    useGetStateDataQuery,
    useGetCityDataQuery,
    useCountryUpdateMutation,
    useStateUpdateMutation,
    useCityUpdateMutation,
    useDeleteCountryMutation,
    useDeleteStateMutation,
    useDeleteCityMutation,
    useAddCountryMutation,
    useAddStateMutation,
    useAddCityMutation,
    useAddSupportTypeMutation,
    useEditSupportTypeMutation,
    useAddOEMMutation,
    useEditOEMMutation,
    useAddSLAMutation,
    useEditSLAMutation,
    useAddProductTypeMutation,
    useEditProductTypeMutation,
    useAddNewModelMutation,
    useEditNewModelMutation,
} = adminApi;
