import React from "react";

const Title = ({ children, margin = "my-6" }) => {
  return (
    <div className={margin}>
      <h1 className="text-3xl font-bold tracking-tight text-passionate-blue dark:text-gray-200">
        {children}
      </h1>
    </div>
  );
};

export default Title;
