import React, { memo } from "react";
import { useSelector } from "react-redux";
import { setTheme } from "../../features/toggleSlice";
import { useDispatch } from "react-redux";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";

const ThemeControle = () => {
  const dispatch = useDispatch();
  const theme = useSelector(({ toggle }) => toggle.theme);

  return theme === "light" ? (
    <button
      className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-oxford-blue"
      onClick={() => dispatch(setTheme("dark"))}
    >
      <FiSun className="h-6 w-6" />
    </button>
  ) : (
    <button
      className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-oxford-blue"
      onClick={() => dispatch(setTheme("light"))}
    >
      <FaRegMoon className="h-6 w-6" />
    </button>
  );
};

export default memo(ThemeControle);
