import React from "react";
import errorSvg from "../../assets/images/notfound.svg";

const PageNotFound = () => {
    return (
        <div className="w-fit h-fit  object-cover mx-auto mt-12">
            <img src={errorSvg} alt="" width="100%" height="100%" />
        </div>
    );
};

export default PageNotFound;
