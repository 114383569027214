export const dayDifference = (startDate, endDate) => {
    if (startDate.length === 0 || endDate.length === 0) {
        return 0;
    }
    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
};

export const formateDate = (date) => {
    if (date) {
        const d = date.split("-");
        return `${d[2]}-${d[1]}-${d[0]}`;
    }
    return date;
};

export const salesTotalAmount = (data) => {
    console.log(data);
    if (!data) return 0;

    if (!!data) {
        let sum = 0;
        data.forEach((obj) => {
            const rate = obj.rate || 0;
            const qty = obj.qty || obj.quantity || 0;
            sum += rate * qty;
        });

        console.log(sum);
        return sum.toFixed(2);
    }
};

export const totalNLC = (data, dutyAmount = 0, shipmentAmount = 0) => {
    if (!data && !dutyAmount && !shipmentAmount) return 0;

    if (!!data) {
        let sum = 0;
        console.log(data);
        data.forEach((obj) => {
            const rate = obj.rate || 0;
            const qty = obj.qty || obj.quantity || 0;
            sum += rate * qty;
        });
        console.log(sum);
        return sum + parseInt(dutyAmount) + parseInt(shipmentAmount);
    }
};

export const endDate = (startDate) => {
    const d = new Date(startDate);
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();

    if (day < 10) {
        day = "0" + day;
    }

    if (month < 10) {
        month = "0" + month;
    }

    return `${year}-${month}-${day}`;
};
