import React, { memo, useMemo } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { Field } from "formik";
import { Table } from "../../components/Tables";

const className =
    "mt-1 border-b border-transparent bg-transparent px-3 py-2 focus:border-gray-300 focus:outline-none hover:border-gray-300";

const TnmSalesTable = ({ values, remove }) => {
    const salesDetailsColumns = useMemo(
        () => [
            {
                Header: "Description",
                Cell: ({ row: { index } }) => (
                    <Field
                        name={`tnmSalesData[${index}].description`}
                        id={`tnmSalesData[${index}].description`}
                        className={className}
                    />
                ),
            },
            {
                Header: "Qty",
                Cell: ({ row: { index } }) => (
                    <Field
                        name={`tnmSalesData[${index}].qty`}
                        id={`tnmSalesData[${index}].qty`}
                        className={className}
                    />
                ),
            },
            {
                Header: "Rate",
                Cell: ({ row: { index } }) => (
                    <Field
                        name={`tnmSalesData[${index}].rate`}
                        id={`tnmSalesData[${index}].rate`}
                        className={className}
                    />
                ),
            },
            {
                Header: "Total Amount",
                accessor: (row) => row.qty * row.rate,
            },
            {
                Header: "Remarks",
                Cell: ({ row: { index } }) => (
                    <Field
                        name={`tnmSalesData[${index}].remarks`}
                        id={`tnmSalesData[${index}].remarks`}
                        className={className}
                    />
                ),
            },
            {
                Header: "Action",
                Cell: ({ row: { index } }) => (
                    <button type="button" onClick={() => remove(index)}>
                        <HiOutlineTrash className="h-5 w-5" />
                    </button>
                ),
            },
        ],
        [remove]
    );
    return <Table tableColumns={salesDetailsColumns} tableData={values} />;
};

export default memo(TnmSalesTable);
