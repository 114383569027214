import React from "react";
import { useUrnDetailsQuery } from "../../api/poVerificationApi";
import PoTable from "./PoTable";
import { BarLoader } from "react-spinners";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";

const PoInfo = ({ refNumber }) => {
    const { data, isLoading, isSuccess, isError, error } = useUrnDetailsQuery(
        refNumber.quote_reference_number
    );

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : isLoading ? (
                <div className="flex justify-center mt-5">
                    <BarLoader color="#E8687B" />
                </div>
            ) : (
                <>
                    <div className="mt-10 flex flex-col justify-between lg:flex-row gap-4">
                        <div className=" flex flex-col gap-2 mb-5 bg-gray-100 dark:bg-transparent h-fit rounded-md p-2 lg:w-1/2">
                            {isSuccess && (
                                <>
                                    <InfoList
                                        name="refrence number"
                                        value={data.urn}
                                    />
                                    <InfoList
                                        name="key account manager"
                                        value={data.kam}
                                    />
                                    <InfoList
                                        name="po amount"
                                        value={data.po_amount}
                                    />
                                    <InfoList
                                        name="customer name"
                                        value={data.customer_name}
                                    />
                                    <InfoList
                                        name="no of po's attached by KAM"
                                        value={data.no_of_po}
                                    />
                                    {data?.po_pdf_url.length > 0 && (
                                        <embed
                                            className="mt-2"
                                            src={data.po_pdf_url}
                                            width={"100%"}
                                            height={500}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        <PoTable
                            tableData={
                                data?.po_verification_check_points_data || []
                            }
                            urn={data?.urn}
                        />
                    </div>
                    <div className="border-t mt-3 pt-3">
                        <h6 className="text-lg font-bold text-gray-700 dark:text-gray-300">
                            PO Attachments
                        </h6>
                        <ul className="border-b py-3 space-y-2 px-4">
                            {data?.po_list.length > 0
                                ? data?.po_list.map(
                                      ({ original_po_file_name }, i) => (
                                          <li
                                              className="text-gray-500 dark:text-gray-400 list-disc"
                                              key={i}
                                          >
                                              {original_po_file_name}
                                          </li>
                                      )
                                  )
                                : "---"}
                        </ul>
                    </div>
                </>
            )}
        </>
    );
};

const InfoList = ({ name, value }) => (
    <div className="space-y-0 bg-gray-100 dark:bg-charcoal rounded-lg p-2">
        <span className="block text-lg text-amber-600">{value}</span>
        <span className="capitalize block text-xs text-gray-600 dark:text-gray-200 font-medium">
            {name}
        </span>
    </div>
);

export default PoInfo;
