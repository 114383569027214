import React, { useEffect, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import CityDetails from "./CityDetails";
import Modal from "../../components/modal";
import Title from "../../components/Title";
import {
    useAddCityMutation,
    useDeleteCityMutation,
    useGetCityListQuery,
} from "../../api/adminApi";
import { reset, setTotalCount } from "../../features/tableSlice";
import DataTable from "../../components/Tables/DataTable";
import { Field, Formik } from "formik";
import { CgSpinnerAlt } from "react-icons/cg";
import { setLoading } from "../../features/toggleSlice";
import PageNotFound from "../../components/Error/PageNotFound";
import ServerError from "../../components/Error";
import { ToastContainer, toast } from "react-toastify";

const City = () => {
    const dispatch = useDispatch();

    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );

    const {
        data: cityList,
        isLoading,
        isError,
        error,
        isFetching,
    } = useGetCityListQuery({
        pageIndex,
        rowCount,
        pageFilter,
    });
    const [deleteCity] = useDeleteCityMutation();

    const [addCity, setAddCity] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [cityId, setCityId] = useState(null);

    const tableData = cityList?.data;

    useEffect(() => {
        dispatch(setTotalCount(cityList?.last_page));
        return () => dispatch(reset());
    }, [cityList?.last_page, dispatch]);

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "Name",
            accessor: "city_name",
        },
        {
            Header: "State",
            accessor: "state.state_name",
        },
        {
            Header: "Country",
            accessor: "",
        },

        {
            Header: "edit",
            accessor: "city_id",
            Cell: ({ value }) => (
                <button
                    onClick={() => {
                        setCityId(value);
                        setEditModal(true);
                    }}
                    className="hover:bg-blue-100 p-2 rounded-full"
                >
                    <BiEditAlt className="h-5 w-5 hover:text-blue-500" />
                </button>
            ),
        },
        {
            Header: "delete",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={async () => {
                        try {
                            const resp = await deleteCity(
                                value.city_id
                            ).unwrap();
                            toast.success(resp.message);
                        } catch (error) {
                            toast.error(error.data.error);
                        }
                    }}
                >
                    <HiOutlineTrash className="h-5 w-5" />
                </button>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="card w-11/12">
                        <Title>City</Title>
                        <div className="flex justify-end">
                            <button
                                onClick={() => {
                                    setAddCity(true);
                                }}
                                className="bg-gray-100 hover:bg-gray-200  active:bg-gray-300 focus:outline-none py-2 px-3  rounded-lg  dark:bg-charcoal dark:text-gray-300"
                            >
                                <FiPlus className="h-4 w-4 inline mr-3" />
                                Add New City
                            </button>
                        </div>
                        <AddCity open={addCity} setOpen={setAddCity} />
                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />
                        <Modal
                            open={editModal}
                            setOpen={setEditModal}
                            title="Edit City"
                        >
                            <CityDetails id={cityId} setOpen={setEditModal} />
                        </Modal>
                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                            theme="colored"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default City;

const AddCity = ({ open, setOpen }) => {
    const [addCity] = useAddCityMutation();
    return (
        <Modal open={open} setOpen={setOpen}>
            <Formik
                initialValues={{
                    city_name: "bjwbckwjcwkjcwn",
                    state_id: 78,
                }}
                onSubmit={async (values) => {
                    try {
                        const resp = await addCity(values).unwrap();
                        toast.success(resp.message);
                        setOpen(false);
                    } catch (error) {
                        toast.error(error.data.error);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="overflow-hidden  sm:rounded-md">
                            <div className="border-b-2  px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="state_id"
                                            className="form-label"
                                        >
                                            Country
                                        </label>
                                        <Field
                                            as="select"
                                            name="state_id"
                                            id="state_id"
                                            className=" form-select"
                                        ></Field>
                                    </div>
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="city_name"
                                            className="form-label"
                                        >
                                            State
                                        </label>
                                        <Field
                                            id="city_name"
                                            name="city_name"
                                            className="form-input"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-action">
                                <button
                                    type="submit"
                                    className="form-btn-primary"
                                >
                                    {isSubmitting && (
                                        <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                    )}
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="form-btn-secondary"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};
