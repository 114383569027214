import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./features/sidebarSlice";
import { apiSlice } from "./api/apiSlice";
import authSliceReducer from "./features/authSlice";
import tableReducer from "./features/tableSlice";

import toggleReducer from "./features/toggleSlice";

export const store = configureStore({
    reducer: {
        sidebar: sidebarSlice,
        auth: authSliceReducer,

        toggle: toggleReducer,
        table: tableReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});
