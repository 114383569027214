import React, { Fragment, useState } from "react";

import { usePoProcessListQuery } from "../../api/poProcessApi";
import AbcoPoForm from "./AbcoPoForm";
import Select, { createFilter } from "react-select";
import Title from "../../components/Title";
import { ToastContainer } from "react-toastify";

const Loader = () => (
    <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-3 ">
            <div className="h-3 w-1/4 bg-slate-200 rounded" />
            <div className="h-6 w-2/5 bg-slate-200 rounded" />
        </div>
    </div>
);

const PoProcess = () => {
    const { data, isLoading } = usePoProcessListQuery();
    const [selected, setSelected] = useState("");
    
    return (
        <>
            <div className="p-7 bg-white rounded-lg shadow-xl dark:bg-raisin-black">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="w-full md:w-1/2 lg:w-2/5 mt-3">
                        <div className="text-gray-700 dark:text-gray-300 mb-2 font-semibold">
                            Search Reference Number
                        </div>

                        <Select
                            placeholder="Search URN"
                            value={selected}
                            unstyled
                            onChange={(value) => setSelected(value)}
                            isClearable
                            styles={{
                                input: (base) => ({
                                    ...base,
                                }),
                                control: (base) => ({
                                    ...base,
                                }),
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            classNames={{
                                control: ({ isFocused }) =>
                                    `${
                                        isFocused && "border-primary-500"
                                    } border dark:bg-raisin-black dark:border-gray-700 font-semibold rounded-lg hover:cursor-pointer`,

                                input: () =>
                                    "text-gray-700 dark:text-gray-300 font-semibold",

                                singleValue: () =>
                                    "text-gray-700 dark:text-gray-300",

                                option: ({ isFocused, isSelected }) =>
                                    `
                ${
                    isSelected
                        ? "bg-pink dark:bg-pink text-white "
                        : "text-gray-700 dark:text-gray-300"
                }
                  ${isFocused && "bg-gray-200 dark:bg-gray-700 "}
                  text-lg px-3 py-1 my-1 `,

                                dropdownIndicator: () => "text-gray-700",
                                clearIndicator: () => "text-gray-700",
                                menu: () =>
                                    " mt-2 bg-white border dark:bg-raisin-black rounded-lg",

                                indicatorsContainer: () => `px-2 gap-1`,
                                valueContainer: () => "px-3 gap-1",
                                placeholder: () => "text-gray-500",
                            }}
                            options={data}
                            components={{ IndicatorSeparator: null }}
                            getOptionLabel={(option) =>
                                option.quote_reference_number
                            }
                            getOptionValue={(option) =>
                                option.quote_reference_number
                            }
                        />
                    </div>
                )}
            </div>

            {selected && (
                <div className="p-7 bg-white dark:bg-raisin-black rounded-lg shadow-xl mt-10">
                    <Title>Abco Po Form</Title>
                    <AbcoPoForm
                        urn={selected.quote_reference_number}
                        isDraft={false}
                        type={"CREATE"}
                    />
                    <ToastContainer
                        autoClose={1500}
                        hideProgressBar
                        pauseOnFocusLoss={false}
                        position="bottom-left"
                    />
                </div>
            )}
        </>
    );
};

export default PoProcess;
