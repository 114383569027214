import { MdOutlineNotifications } from "react-icons/md";
import {
    useNotificationCountQuery,
    useNotificationListQuery,
} from "../../api/poProcessApi";
import { Fragment, useState } from "react";
import { BiHistory } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";

import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";

const NotificationList = ({ open, setOpen }) => {
    const { data: notificationList } = useNotificationListQuery();
    const theme = useSelector(({ toggle }) => toggle.theme);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className={`relative z-10 ${theme === "dark" ? "dark" : ""}`}
                onClose={setOpen}
            >
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="border-l dark:border-raisin-black pointer-events-auto relative w-screen max-w-md">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="bg-white dark:bg-rich-black-2 flex justify-between py-3 px-5 mt-3">
                                            <Dialog.Title className="text-xl  font-semibold leading-6 text-gray-900 dark:text-gray-100 align-middle">
                                                <MdOutlineNotifications className="w-6 h-6 inline-block mr-3" />
                                                <span className=" inline-block underline underline-offset-8 decoration-pink decoration-2">
                                                    Reminders
                                                </span>
                                            </Dialog.Title>
                                            <button
                                                type="button"
                                                className="dark:bg-gray-400 rounded-full p-1 text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white dark:focus:ring-gray-500"
                                                onClick={() => setOpen(false)}
                                            >
                                                <span className="sr-only">
                                                    Close panel
                                                </span>
                                                <GrFormClose
                                                    className="h-6 w-6 "
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-rich-black-2 py-6 shadow-xl">
                                        <div className="px-4 sm:px-6"></div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div className="space-y-5">
                                                {notificationList?.map(
                                                    ({
                                                        id,
                                                        urn,
                                                        kam,
                                                        message,
                                                        request_sent_date,
                                                        is_read,
                                                    }) => (
                                                        <dl
                                                            key={id}
                                                            className="border dark:border-gray-600 py-2 px-3 rounded-lg"
                                                        >
                                                            <dt className="mb-2 flex text-sm justify-between items-center text-gray-700 dark:text-gray-400">
                                                                <h6 className="font-semibold dark:text-gray-200">
                                                                    {urn}
                                                                </h6>
                                                                <div className="font-semibold">
                                                                    {kam}
                                                                </div>
                                                            </dt>
                                                            <dd className="space-y-1 dark:text-gray-300">
                                                                <div className="mb-2  font-semibold">
                                                                    {message}
                                                                </div>
                                                                <div className="text-xs flex justify-between items-center">
                                                                    <div className="text-xs flex items-center space-x-1">
                                                                        <BiHistory className="h-4 w-4" />
                                                                        <span>
                                                                            {
                                                                                request_sent_date
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        {!is_read && (
                                                                            <button className="font-semibold bg-gray-200 dark:bg-gray-700 py-1 px-2 rounded-full">
                                                                                Mark
                                                                                as
                                                                                read
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

const Notification = () => {
    const [open, setOpen] = useState(false);
    const { data: notificationCount } = useNotificationCountQuery();

    return (
        <>
            <div tabIndex={0}>
                <button
                    className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-oxford-blue transition-all duration-300 relative"
                    onClick={() => setOpen(!open)}
                >
                    <div
                        className="text-[9px] text-white bg-red-500 border-2 border-white dark:border-rich-black rounded-full absolute py-[2px] px-[5px] -top-[3px] right-0 transition-colors duration-300
              "
                    >
                        {notificationCount}
                    </div>
                    <MdOutlineNotifications />
                </button>
            </div>
            <NotificationList open={open} setOpen={setOpen} />
        </>
    );
};

export default Notification;
