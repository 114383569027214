import { Field, FieldArray, Formik, useFormikContext } from "formik";
import React, { Fragment, useEffect, useMemo } from "react";
import BOQDetails from "./BOQDetails";
import IRSDetails from "./IRSDetails";
import SPRDetails from "./SPRDetails";
import {
    useAbcoPoDataQuery,
    useGenerateUrnMutation,
    useMonthlyInvSalesPurchaseReportQuery,
    usePoCompanyListQuery,
    usePoCurrencyListQuery,
    usePoTypeListQuery,
    useSaveAsDraftMutation,
    useSendForApprovalMutation,
    useTmcContractTypeListQuery,
    useUpdatePoMutation,
} from "../../api/poProcessApi";
import { formateDate } from "../../helper";
import { CgSpinnerAlt } from "react-icons/cg";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";

const CompanyName = () => {
    const { data: companyList } = usePoCompanyListQuery();
    const [generateUrn] = useGenerateUrnMutation();
    const { values, setFieldValue, handleChange } = useFormikContext();

    useEffect(() => {
        if (values.solidCompanyId.length > 0) {
            generateUrn({
                solidCompanyId: +values.solidCompanyId,
            })
                .then((resp) =>
                    setFieldValue("solidSequenceNumber", resp.data.ssn)
                )
                .catch((error) => console.log(error));
        }
    }, [generateUrn, setFieldValue, values.solidCompanyId]);

    return (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <label htmlFor="poCurrency" className="form-label">
                Company Name {/*(Order Received in) */}
            </label>
            <select
                name="solidCompanyId"
                id="solidCompanyId"
                className=" form-select"
                value={values.solidCompanyId}
                onChange={handleChange}
            >
                <option value="">Select Company </option>
                {companyList &&
                    companyList.map(({ name, id }) => (
                        <option value={id} key={id}>
                            {name}
                        </option>
                    ))}
            </select>
        </div>
    );
};

export const PrimaryContactList = ({
    primaryContact,
    setFieldValue,
    spocEmail,
    spocName,
    spocPhoneNumber,
}) => {
    useEffect(() => {
        if ((spocEmail && spocName && spocPhoneNumber) !== "") {
            setFieldValue("primaryContactList[0].email", spocEmail);
            setFieldValue("primaryContactList[0].name", spocName);
            setFieldValue("primaryContactList[0].phoneNumber", spocPhoneNumber);
        }
    }, [setFieldValue, spocEmail, spocName, spocPhoneNumber]);
    return (
        <>
            <div className="col-span-12 form-group-title">Primary Contact</div>
            <FieldArray name="primaryContactList">
                {({ push, remove }) => (
                    <>
                        {/* {values?.primaryContactList?.map((_, i) => ( */}
                        {primaryContact?.map((_, i) => (
                            <Fragment key={i}>
                                <div className="col-span-12 lg:col-span-4">
                                    <label htmlFor="" className="form-label">
                                        Name
                                    </label>
                                    <Field
                                        type="text"
                                        name={`primaryContactList.${i}.name`}
                                        id={`primaryContactList.${i}.name`}
                                        className="form-input"
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-3">
                                    <label htmlFor="" className="form-label">
                                        Phone Number
                                    </label>
                                    <Field
                                        type="text"
                                        name={`primaryContactList.${i}.phoneNumber`}
                                        id={`primaryContactList.${i}.phoneNumber`}
                                        className="form-input"
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-4">
                                    <label htmlFor="" className="form-label">
                                        Email
                                    </label>
                                    <Field
                                        type="email"
                                        name={`primaryContactList.${i}.email`}
                                        id={`primaryContactList.${i}.email`}
                                        className="form-input"
                                    />
                                </div>
                                {i ? (
                                    <div
                                        className="col-span-1 justify-self-center self-center"
                                        key={i + 3}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => remove(i)}
                                        >
                                            <FiMinus className="h-5 w-5" />
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className="col-span-1 justify-self-center self-center"
                                        key={i + 4}
                                    >
                                        <button
                                            type="button"
                                            onClick={() =>
                                                push({
                                                    name: "",
                                                    email: "",
                                                    phoneNumber: "",
                                                })
                                            }
                                        >
                                            <FiPlus className="h-5 w-5" />
                                        </button>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </>
                )}
            </FieldArray>
        </>
    );
};

const AbcoPoForm = ({
    urn,
    data,
    isDraft = false,
    poProcessId,
    type = "CREATE",
    setOpen,
}) => {
    const navigate = useNavigate();

    const { data: poData, isLoading, isError, error } = useAbcoPoDataQuery(urn);

    const { data: poCurrency } = usePoCurrencyListQuery();
    const { data: poTypeList } = usePoTypeListQuery();
    const { data: tmcContractTypeList } = useTmcContractTypeListQuery();

    const [saveAsDraft] = useSaveAsDraftMutation();
    const [sendForApproval] = useSendForApprovalMutation();
    const [updatePo] = useUpdatePoMutation();

    const boqDetails =
        data?.boq_details && data?.boq_details.length > 0
            ? data?.boq_details?.map((boq) => ({
                  oem: boq.oem,
                  product_type: boq.product_type,
                  customerModelNumber: boq.customerModelNumber,
                  model_number: boq.model_number,
                  serial_number: boq.serial_number,
                  category: boq.category,
                  support_type: boq.support_type,
                  sla: boq.sla,
                  start_date: boq.start_date,
                  end_date: boq.end_date,
                  support_period: boq.support_period,
                  price: boq.price,
                  country_name: boq.country_name,
                  state_name: boq.state_name,
                  city_name: boq.city_name,
                  region_name: boq.region_name,
                  boq_remarks: boq.boq_remarks,
              }))
            : [
                  {
                      oem: "",
                      product_type: "",
                      customerModelNumber: "",
                      model_number: "",
                      serial_number: "",
                      category: "",
                      support_type: "",
                      sla: "",
                      start_date: "",
                      end_date: "",
                      support_period: "",
                      price: "",
                      country_name: "101",
                      state_name: "",
                      city_name: "",
                      region_name: "",
                      boq_remarks: "",
                  },
              ];

    Yup.addMethod(Yup.array, "unique", function (field, message) {
        return this.test("unique", message, function (array) {
            // const uniqueData = Array.from(
            //     new Set(array.map((row) => row[field]?.toLowerCase()))
            // );

            // console.log(uniqueData);
            // const isUnique = array.length === uniqueData.length;
            // if (isUnique) {
            //     return true;
            // }
            // const index = array.findIndex(
            //     (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
            // );

            // const index = array.map(
            //     (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
            // );

            // const errors = index.map((e, i) => {
            //     if (e) {
            //         return this.createError({
            //             path: `${this.path}.${index}.${field}.value`,
            //             message,
            //         });
            //     }
            // });

            // throw new Yup.ValidationError(errors);

            // if (array[index][field] === "") {
            //     return true;
            // }
            // return this.createError({
            //     path: `${this.path}.${index}.${field}`,
            //     message,
            // });

            const errors = [];
            const duplicateProperties = array
                .filter(
                    (e, i) =>
                        array.findIndex((e2) => e2[field] === e[field]) !== i
                )
                .map((e) => e[field]);
            for (let i = 0; i < array.length; i += 1) {
                const element = array[i];
                if (
                    element[field] !== "" &&
                    duplicateProperties.includes(element[field])
                ) {
                    errors.push(
                        new Yup.ValidationError(
                            message,
                            element,
                            `${this.path}[${i}].${field}`
                        )
                    );
                }
            }

            if (errors.length > 0) {
                return this.createError({ message: () => errors });
            }

            return true;
        });
    });

    const schema = Yup.object().shape({
        customerPoNumber: Yup.string().required("This field is required"),
        boqDetails: Yup.array()
            .of(
                Yup.object().shape({
                    oem: Yup.string().required(),
                    product_type: Yup.string().min(1).required(),
                    customerModelNumber: Yup.string().required(),
                    model_number: Yup.string().required(),
                    serial_number: Yup.string().required(),
                    category: Yup.string().required(),
                    support_type: Yup.string().min(1).required(),
                    sla: Yup.string().required(),
                    start_date: Yup.string().required(),
                    end_date: Yup.string().required(),
                    support_period: Yup.string().required(),
                    price: Yup.string().required(),
                    country_name: Yup.string().required(),
                    state_name: Yup.string().required(),
                    city_name: Yup.string().required(),
                    region_name: Yup.string().required(),
                    boq_remarks: Yup.string().required(),
                })
            )
            .unique("serial_number", "please provide a unique number"),
    });

    const initialValues = {
        abco_po_attachment_0: [""],
        primaryContactList: data?.primary_contact?.map(
            ({ name, email, phone_number: phoneNumber }) => ({
                name,
                email,
                phoneNumber,
            })
        ) || [{ name: "", email: "", phoneNumber: "" }],
        boqDetails: boqDetails,
        poCurrency: data?.po_currency || "",

        remarks: data?.remarks || "",

        poAmountAccordingToCurrency:
            data?.po_amount_according_to_currency || "",

        urn: urn || "",

        solidCompanyId: data?.solid_company_id || "",

        customerPoNumber: data?.customer_po_number || "",

        endCustomerName: data?.end_customer_name || "",

        customerSpocName: data?.customer_spoc_name || "",

        customerSpocEmail: data?.customer_spoc_email || "",

        customerSpocPhoneNumber: data?.customer_spoc_phone_number || "",

        invoiceType: data?.invoice_type || "",

        gstNumber: data?.gst_no || "",

        invoiceTo: data?.invoice_to || "",

        poType: data?.po_type || "",

        tmcType: data?.tmc_contract_type_id || "",

        vendorCode: data?.vendor_code || "",

        contractStartDate: data?.contract_start_date || "",

        contractEndDate: data?.contract_end_date || "",

        invoiceBillTo: data?.invoice_bill_to || "",

        spareShipTo: data?.spare_ship_to || "",

        invoiceShipTo: data?.invoice_ship_to || "",

        invoiceCycle: data?.invoice_cycle || "",

        invoiceRaiseIn: data?.invoice_raise || "",

        paymentDueDaysApproval: data?.payment_due_days_approved_by || "",

        isRenewalPo: data?.is_renewal_po ? true : false,

        isPenalty: data?.is_penalty ? true : false,

        anyPrefailures: data?.is_any_prefailures ? true : false,

        penaltyTerms: data?.penalty_terms || "",

        prefailures: data?.prefailures || "",

        solidSequenceNumber: data?.solid_sequence_number || "",

        oldSolidSequenceNumber: data?.old_solid_sequence_number || "",

        paymentDueDaysId: data?.payment_due_days_id || "",

        paymentDueDays: null,

        tnmSalesData: data?.tnm_sales_data?.map(
            ({ description, quantity: qty, rate, remarks }) => ({
                description,
                qty,
                rate,
                remarks,
            })
        ) || [
            {
                description: "",
                qty: "",
                rate: "",
                remarks: "",
            },
        ],

        tnmPurchaseData: data?.tnm_purchase_data?.map(
            ({
                description,
                quantity: qty,
                rate,
                remarks,
                supplier_name: supplierName,
            }) => ({ supplierName, description, qty, rate, remarks })
        ) || [
            {
                supplierName: "",
                description: "",
                qty: "",
                rate: "",
                remarks: "",
            },
        ],

        shipmentAmount: 0,

        dutyAmount: 0,
    };

    const handleApproval = async (values) => {
        try {
            let resp;
            if (type === "EDIT") {
                resp = await updatePo({
                    ...values,
                    isRenewalPo: values.isRenewalPo ? 1 : 0,
                    isPenalty: values.isPenalty ? 1 : 0,
                    anyPrefailures: values.anyPrefailures ? 1 : 0,
                    poProcessId,
                }).unwrap();

                setOpen && setOpen(false);
            } else {
                console.log("create po");
                resp = await sendForApproval({
                    ...values,
                    isRenewalPo: values.isRenewalPo ? 1 : 0,
                    isPenalty: values.isPenalty ? 1 : 0,
                    anyPrefailures: values.anyPrefailures ? 1 : 0,
                }).unwrap();
                setOpen && setOpen(false);
            }
            toast.success(resp.message);
            console.log(resp);
        } catch (error) {
            toast.error(error.data.error);
        }
    };

    const handleDrafts = async (values) => {
        try {
            const resp = await saveAsDraft({
                ...values,
                isRenewalPo: values.isRenewalPo ? 1 : 0,
                isPenalty: values.isPenalty ? 1 : 0,
                anyPrefailures: values.anyPrefailures ? 1 : 0,
            }).unwrap();

            if (type === "CREATE") {
                console.log("navigate");
                navigate("/abco-po-process/drafts");
            }
            toast.success(resp.message);
            setOpen && setOpen(false);
            console.log(resp);
        } catch (error) {
            toast.error(error.data.error);
        }
    };

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : isLoading ? (
                <div className="animate-pulse ">
                    <div className="h-5 w-1/4 bg-slate-200 rounded mx-auto" />
                </div>
            ) : (
                <>
                    <p
                        className={`font-medium ${
                            +poData?.isPoVerifiedPercentage === 100
                                ? "bg-green-50 text-green-700"
                                : "bg-red-50 text-red-700"
                        }  w-max py-1 px-2 rounded-xl mx-auto my-5`}
                    >
                        PO IS {poData?.isPoVerifiedPercentage} % VERIFIED
                    </p>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        // onSubmit={(values) => handleApproval(values)}
                    >
                        {({
                            values,
                            isSubmitting,
                            errors,
                            setFieldValue,
                            touched,
                            handleChange,
                            isValid,
                            handleSubmit,
                        }) => (
                            <>
                                <form
                                // onSubmit={handleSubmit}
                                >
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="col-span-12 form-group-title">
                                            Account Manager Details
                                        </div>
                                        <div className="col-span-12 lg:col-span-4">
                                            <label
                                                htmlFor="createdBy"
                                                className="form-label"
                                            >
                                                Account Created By
                                            </label>
                                            <input
                                                value={
                                                    poData?.account_created_by
                                                }
                                                type="text"
                                                name="createdBy"
                                                id="createdBy"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 lg:col-span-4">
                                            <label
                                                htmlFor="existingKAM"
                                                className="form-label"
                                            >
                                                Existing KAM
                                            </label>
                                            <input
                                                value={poData?.existing_kam}
                                                type="text"
                                                name="existingKAM"
                                                id="existingKAM"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 lg:col-span-4">
                                            <label
                                                htmlFor="buHeadName"
                                                className="form-label"
                                            >
                                                CAM/KAM Reporting To (BU Head
                                                Name)
                                            </label>
                                            <input
                                                value={poData?.buHeadName}
                                                type="text"
                                                name="buHeadName"
                                                id="buHeadName"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <hr className="col-span-12" />
                                        <div className="col-span-12 form-group-title">
                                            PO Details
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="poAmount"
                                                className="form-label"
                                            >
                                                PO Amount
                                            </label>
                                            <input
                                                value={poData?.po_amount}
                                                type="text"
                                                name="poAmount"
                                                id="poAmount"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="poDate"
                                                className="form-label"
                                            >
                                                PO Date
                                            </label>
                                            <input
                                                value={formateDate(
                                                    poData?.po_date
                                                )}
                                                type="date"
                                                name="poDate"
                                                id="poDate"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="poType"
                                                className="form-label"
                                            >
                                                PO Type
                                            </label>
                                            <Field
                                                as="select"
                                                name="poType"
                                                id="poType"
                                                className=" form-select"
                                            >
                                                <option value="">
                                                    Select PO Type
                                                </option>
                                                {poTypeList &&
                                                    poTypeList.map(
                                                        ({
                                                            name,
                                                            id,
                                                            description,
                                                        }) => (
                                                            <option
                                                                value={id}
                                                                key={id}
                                                                title={
                                                                    description
                                                                }
                                                            >
                                                                {name}
                                                            </option>
                                                        )
                                                    )}
                                            </Field>
                                        </div>
                                        {+values.poType === 2 && (
                                            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                                <label
                                                    htmlFor="tmcType"
                                                    className="form-label"
                                                >
                                                    TMC Contract Type
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="tmcType"
                                                    id="tmcType"
                                                    className=" form-select"
                                                >
                                                    <option value="">
                                                        Select Contract Type
                                                    </option>
                                                    {tmcContractTypeList &&
                                                        tmcContractTypeList.map(
                                                            ({ name, id }) => (
                                                                <option
                                                                    value={id}
                                                                    key={id}
                                                                >
                                                                    {name}
                                                                </option>
                                                            )
                                                        )}
                                                </Field>
                                            </div>
                                        )}
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3 lg:col-start-1">
                                            <label
                                                htmlFor="poLoopDate"
                                                className="form-label"
                                            >
                                                PO Loop Date
                                            </label>
                                            <input
                                                value={formateDate(
                                                    poData?.po_loop_date
                                                )}
                                                type="date"
                                                name="poLoopDate"
                                                id="poLoopDate"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="customerPoNumber"
                                                className="form-label"
                                            >
                                                Customer Po Number
                                            </label>
                                            <Field
                                                type="text"
                                                name="customerPoNumber"
                                                id="customerPoNumber"
                                                className="form-input"
                                            />
                                            {+values.invoiceType === 1 &&
                                                errors?.customerPoNumber &&
                                                touched?.customerPoNumber && (
                                                    <div className="text-red-500 text-xs mt-1 font-semibold">
                                                        {
                                                            errors?.customerPoNumber
                                                        }
                                                    </div>
                                                )}
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="poCurrency"
                                                className="form-label"
                                            >
                                                PO Currency
                                            </label>
                                            <Field
                                                as="select"
                                                name="poCurrency"
                                                id="poCurrency"
                                                className=" form-select"
                                            >
                                                <option value="">
                                                    Select Currency
                                                </option>
                                                {poCurrency &&
                                                    poCurrency.map(
                                                        ({
                                                            currency,
                                                            country_id,
                                                        }) => (
                                                            <option
                                                                key={country_id}
                                                                value={
                                                                    country_id
                                                                }
                                                            >
                                                                {currency}
                                                            </option>
                                                        )
                                                    )}
                                            </Field>
                                        </div>

                                        {+values.poCurrency !== 101 && (
                                            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                                <label
                                                    htmlFor="customerPoNumber"
                                                    className="form-label"
                                                >
                                                    PO Amount According to
                                                    Currency
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="poAmountAccordingToCurrency"
                                                    id="poAmountAccordingToCurrency"
                                                    className="form-input"
                                                />
                                            </div>
                                        )}

                                        <div className="col-span-12"></div>
                                        <hr className="col-span-12" />
                                        <div className="col-span-12 form-group-title">
                                            Other Details
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="endCustomerName"
                                                className="form-label"
                                            >
                                                End Customer Name
                                            </label>
                                            <Field
                                                type="text"
                                                name="endCustomerName"
                                                id="endCustomerName"
                                                className="form-input"
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="customerSpocName"
                                                className="form-label"
                                            >
                                                Customer SPOC Name
                                            </label>
                                            <Field
                                                type="text"
                                                name="customerSpocName"
                                                id="customerSpocName"
                                                className="form-input"
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="customerSpocEmail"
                                                className="form-label"
                                            >
                                                Customer SPOC Email
                                            </label>
                                            <Field
                                                type="text"
                                                name="customerSpocEmail"
                                                id="customerSpocEmail"
                                                className="form-input"
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="customerSpocPhoneNumber"
                                                className="form-label"
                                            >
                                                Customer SPOC Phone Number
                                            </label>
                                            <Field
                                                type="text"
                                                name="customerSpocPhoneNumber"
                                                id="customerSpocPhoneNumber"
                                                className="form-input"
                                            />
                                        </div>

                                        <CompanyName />

                                        <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                            <label
                                                htmlFor="solidSequenceNumber"
                                                className="form-label"
                                            >
                                                Solid's Order Sequence(SoS#)
                                            </label>
                                            <Field
                                                type="text"
                                                name="solidSequenceNumber"
                                                id="solidSequenceNumber"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-12 lg:col-span-6">
                                            <label
                                                htmlFor="customerName"
                                                className="form-label"
                                            >
                                                Customer Name(Order Received In)
                                            </label>
                                            <input
                                                value={poData?.customer_name}
                                                type="text"
                                                name="customerName"
                                                id="customerName"
                                                className="form-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-4">
                                            <Field
                                                type="checkbox"
                                                name="isRenewalPo"
                                                id="isRenewalPo"
                                                className="border-gray-300 focus:outline-none align-middle"
                                            />
                                            <label
                                                htmlFor="isRenewalPo"
                                                className="ml-2 text-sm font-medium text-gray-700"
                                            >
                                                Renewal PO?
                                            </label>
                                        </div>
                                        <div className="col-span-12 md:col-span-4">
                                            <Field
                                                type="checkbox"
                                                name="isPenalty"
                                                id="isPenalty"
                                                className="border-gray-300 focus:outline-none align-middle"
                                            />
                                            <label
                                                htmlFor="isPenalty"
                                                className="ml-2 text-sm font-medium text-gray-700"
                                            >
                                                Is There Any Penalty?
                                            </label>
                                        </div>
                                        <div className="col-span-12 md:col-span-4">
                                            <Field
                                                type="checkbox"
                                                name="anyPrefailures"
                                                id="anyPrefailures"
                                                className="border-gray-300    focus:outline-none align-middle"
                                            />
                                            <label
                                                htmlFor="anyPrefailures"
                                                className="ml-2 text-sm font-medium text-gray-700"
                                            >
                                                Any Prefailures?
                                            </label>
                                        </div>

                                        {values.isRenewalPo && (
                                            <div className="col-span-12 md:col-span-12 lg:col-span-4 ">
                                                <label
                                                    htmlFor="oldSolidSequenceNumber"
                                                    className="form-label"
                                                >
                                                    Enter Solid's Order Sequence
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="oldSolidSequenceNumber"
                                                    id="oldSolidSequenceNumber"
                                                    className="form-input"
                                                />
                                            </div>
                                        )}

                                        {values.isPenalty && (
                                            <div className="col-span-12 md:col-span-12 lg:col-span-4 lg:col-start-5">
                                                <label
                                                    htmlFor="penaltyTerms"
                                                    className="form-label"
                                                >
                                                    Mention Penalty Terms
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="penaltyTerms"
                                                    id="penaltyTerms"
                                                    className="form-input"
                                                />
                                            </div>
                                        )}

                                        {values.anyPrefailures && (
                                            <div className="col-span-12 md:col-span-12 lg:col-span-4 lg:col-start-9">
                                                <label
                                                    htmlFor="customerName"
                                                    className="form-label"
                                                >
                                                    Enter Prefailures
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="prefailures"
                                                    id="prefailures"
                                                    className="form-input"
                                                />
                                            </div>
                                        )}
                                        <hr className="col-span-12" />

                                        <div className="col-span-12 form-group-title">
                                            ABCO Attachments
                                        </div>
                                        <FieldArray name="abco_po_attachment_0">
                                            {({ remove, push }) => (
                                                <div className="col-span-12 md:col-span-6 lg:col-span-4">
                                                    <label
                                                        htmlFor="abco_po_attachment"
                                                        className="form-label"
                                                    >
                                                        Attach PO
                                                    </label>
                                                    {values.abco_po_attachment_0.map(
                                                        (_, i) => (
                                                            <div
                                                                className="flex gap-3 mb-3"
                                                                key={i}
                                                            >
                                                                <Field
                                                                    type="file"
                                                                    name={`abco_po_attachment_0.${i}`}
                                                                    id="abco_po_attachment_0"
                                                                    className="form-input-file"
                                                                />

                                                                {i > 0 ? (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            remove(
                                                                                i
                                                                            )
                                                                        }
                                                                    >
                                                                        <FiMinus className="inline h-4 w-4 mr-1" />
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            push(
                                                                                ""
                                                                            )
                                                                        }
                                                                    >
                                                                        <FiPlus className="inline h-4 w-4 mr-1" />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </FieldArray>
                                        {values.Attachpos && (
                                            <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6 mb-5 col-span-12">
                                                <h3 className="text-xl mb-3 dark:text-gray-300">
                                                    Attachments
                                                </h3>
                                                <dl className="rounded-md text-sm flex flex-col">
                                                    {data?.attachments.map(
                                                        (
                                                            {
                                                                file_name,
                                                                file_url,
                                                            },
                                                            i
                                                        ) => (
                                                            <div
                                                                className="border-b flex items-baseline justify-between py-3 md:basis-1/2"
                                                                key={i}
                                                            >
                                                                <dt className="text-gray-500 dark:text-gray-400">
                                                                    {file_name}
                                                                </dt>
                                                                <dd className="text-gray-900 dark:text-gray-100"></dd>
                                                            </div>
                                                        )
                                                    )}
                                                </dl>
                                            </div>
                                        )}

                                        <hr className="col-span-12" />

                                        {+values.poType !== 2 &&
                                            +values.poType !== 4 && (
                                                <>
                                                    <div className="col-span-12 md:col-span-6 lg:col-span-4">
                                                        <label
                                                            htmlFor="invoiceType"
                                                            className="form-label"
                                                        >
                                                            Generate IRS/SPR
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            name="invoiceType"
                                                            id="invoiceType"
                                                            className=" form-select"
                                                        >
                                                            <option value="">
                                                                SELECT
                                                            </option>
                                                            <option value={1}>
                                                                IRS
                                                            </option>
                                                            <option value={2}>
                                                                SPR
                                                            </option>
                                                        </Field>
                                                    </div>

                                                    {+values.invoiceType ===
                                                        1 && (
                                                        <>
                                                            <IRSDetails
                                                                dueDaysId={
                                                                    values.paymentDueDaysId
                                                                }
                                                                primaryContact={
                                                                    values.primaryContactList
                                                                }
                                                                setFieldValue={
                                                                    setFieldValue
                                                                }
                                                                cstartDate={
                                                                    values.contractStartDate
                                                                }
                                                                // spocEmail={
                                                                //     values.customerSpocEmail
                                                                // }
                                                                // spocName={
                                                                //     values.customerSpocName
                                                                // }
                                                                // spocPhoneNumber={
                                                                //     values.customerSpocPhoneNumber
                                                                // }
                                                            />
                                                            <PrimaryContactList
                                                                primaryContact={
                                                                    values.primaryContactList
                                                                }
                                                                setFieldValue={
                                                                    setFieldValue
                                                                }
                                                                spocEmail={
                                                                    values.customerSpocEmail
                                                                }
                                                                spocName={
                                                                    values.customerSpocName
                                                                }
                                                                spocPhoneNumber={
                                                                    values.customerSpocPhoneNumber
                                                                }
                                                            />
                                                            <hr className="col-span-12" />
                                                            <div className="col-span-12">
                                                                <BOQDetails
                                                                    values={
                                                                        values.boqDetails
                                                                    }
                                                                    errors={
                                                                        errors?.boqDetails
                                                                    }
                                                                    touched={
                                                                        touched?.boqDetails
                                                                            ? touched.boqDetails
                                                                            : null
                                                                    }
                                                                    handleChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {+values.invoiceType ===
                                                        2 && (
                                                        <SPRDetails
                                                            dueDaysId={
                                                                values.paymentDueDaysId
                                                            }
                                                            primaryContact={
                                                                values.primaryContactList
                                                            }
                                                            salesData={
                                                                values.tnmSalesData
                                                            }
                                                            purchaseData={
                                                                values.tnmPurchaseData
                                                            }
                                                            dutyAmount={
                                                                values.dutyAmount
                                                            }
                                                            shipmentAmount={
                                                                values.shipmentAmount
                                                            }
                                                            spocEmail={
                                                                values.customerSpocEmail
                                                            }
                                                            spocName={
                                                                values.customerSpocName
                                                            }
                                                            spocPhoneNumber={
                                                                values.customerSpocPhoneNumber
                                                            }
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </>
                                            )}

                                        {+values.poType !== 2 &&
                                            +values.poType !== 4 && (
                                                <hr className="col-span-12" />
                                            )}
                                        <div className="col-span-12 md:col-span-6 lg:col-span-5">
                                            <label
                                                htmlFor="remarks"
                                                className="form-label"
                                            >
                                                Remarks
                                            </label>
                                            <Field
                                                type="text"
                                                id="remarks"
                                                name="remarks"
                                                className="form-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-action">
                                        {console.log()}
                                        {((+values.invoiceType === 1 &&
                                            !errors?.boqDetails) ||
                                            +values.invoiceType === 2 ||
                                            values.invoiceType === "") && (
                                            <button
                                                type="button"
                                                className="form-btn-primary"
                                                onClick={() =>
                                                    handleApproval(values)
                                                }
                                            >
                                                {isSubmitting && (
                                                    <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                                )}
                                                Send For Approval
                                            </button>
                                        )}

                                        {((+values.invoiceType === 1 &&
                                            !errors?.customerPoNumber) ||
                                            +values.invoiceType !== 1) &&
                                            ((!(type === "EDIT") && !isDraft) ||
                                                isDraft) && (
                                                <button
                                                    type="button"
                                                    className="form-btn-primary"
                                                    onClick={() =>
                                                        handleDrafts(values)
                                                    }
                                                >
                                                    {isSubmitting && (
                                                        <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                                    )}
                                                    Save As Draft
                                                </button>
                                            )}
                                        <button
                                            onClick={() => {
                                                setOpen && setOpen(false);
                                            }}
                                            type="button"
                                            className="form-btn-secondary"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default AbcoPoForm;
