import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";

import logo from "../../assets/images/Solid-Tech-logo.png";
import Img from "../../assets/images/undraw_Designer_re_5v95.png";
import useToken from "../../hooks/useToken";
import { useLoginMutation } from "../../api/authApi";
import { setUserToken } from "../../features/authSlice";
import { Field, Formik } from "formik";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const prevLocation = location?.state?.from?.pathname;

    const { token, setToken } = useToken();
    const [login, { isLoading, isError }] = useLoginMutation();

    if (token) {
        window.history.back();
    }

    const validate = (values) => {
        const errors = {};

        if (!values.user_name) {
            errors.user_name = "Required";
        }

        if (!values.password) {
            errors.password = "Required";
        }
        return errors;
    };

    return (
        <div className="flex items-center min-h-screen p-6 bg-gray-50">
            <div className="relative flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl">
                <BarLoader
                    cssOverride={{ width: "100%", position: "absolute" }}
                    loading={isLoading}
                    color="#E8687B"
                />
                <div className="flex flex-col overflow-y-auto lg:flex-row">
                    <div className="flex items-center justify-center  p-6 sm:p-12 lg:w-1/2">
                        <div className="w-full">
                            <div className="mb-7">
                                <img
                                    className="w-28 mb-5"
                                    src={logo}
                                    alt="Solid Tech"
                                />
                                <h1 className="text-2xl font-semibold text-passionate-blue">
                                    Login
                                </h1>
                                <span className="text-xs border-b-2 pb-1  text-gray-400">
                                    Welcome back! Please enter your details
                                </span>
                            </div>
                            <Formik
                                initialValues={{ user_name: "", password: "" }}
                                validate={validate}
                                onSubmit={async (value) => {
                                    const resp = await login(value).unwrap();

                                    setToken(resp.success.token);
                                    dispatch(
                                        setUserToken(
                                            JSON.parse(
                                                sessionStorage.getItem("token")
                                            )
                                        )
                                    );
                                    navigate(prevLocation || "/");
                                }}
                            >
                                {({ handleSubmit, errors, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="">
                                            <label>Email</label>
                                            <Field
                                                className="mt-1 block w-full border  focus:shadow-[0_0_1rem_0_rgba(140,152,164,0.25)] outline-none py-2 px-3 rounded-md text-sm"
                                                type="text"
                                                name="user_name"
                                                placeholder="john@doe.com"
                                            />
                                            {errors.user_name &&
                                            touched.user_name ? (
                                                <div className="text-red-500 text-sm font-semibold p-1">
                                                    {errors.user_name}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="mt-4 ">
                                            <label>Password</label>
                                            <Field
                                                className="mt-1 block w-full border focus:shadow-[0_0_1rem_0_rgba(140,152,164,0.25)] outline-none py-2 px-3 rounded-md text-sm"
                                                type="password"
                                                name="password"
                                                placeholder="***************"
                                            />
                                            {errors.password &&
                                            touched.password ? (
                                                <div className="text-red-500 text-sm font-semibold p-1">
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                        </div>
                                        {isError && (
                                            <div className="text-red-500 text-sm font-semibold p-1">
                                                Please check your credentials
                                                and try again.
                                            </div>
                                        )}
                                        <div className="mt-7 flex space-y-3 md:space-y-0 md:justify-between items-start md:items-center flex-col md:flex-row">
                                            <div className="flex space-x-3 items-center">
                                                <Field
                                                    id="remember"
                                                    name="remember"
                                                    type="checkbox"
                                                    className="accent-pink-500 focus:outline-1 outline-pink-500"
                                                />
                                                <label
                                                    htmlFor="remember"
                                                    className="text-gray-600"
                                                >
                                                    Remember Me
                                                </label>
                                            </div>

                                            <Link
                                                href="/forgot-password"
                                                className="text-sm  font-medium text-pink hover:underline focus:underline focus:outline-none"
                                                to="/forgot-password"
                                            >
                                                Forgot your password?
                                            </Link>
                                        </div>

                                        <button
                                            type="submit"
                                            className="mt-5 py-2 px-3 rounded-md w-full bg-pink text-white hover:bg-[#d95569] focus:outline-none focus:bg-[#d95569] active:scale-[0.99]"
                                            disabled={isLoading}
                                        >
                                            Log In
                                        </button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className="h-32 md:h-auto lg:w-1/2 hidden lg:flex items-center">
                        <div>
                            <img
                                className="object-cover h-full"
                                src={Img}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
