import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: {},
    token: JSON.parse(sessionStorage?.getItem("token")),
    user: {},
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserToken: (state, action) => {
            state.token = action.payload;
        },

        logout: (state) => {
            state.currentUser = {};
            state.token = null;
        },

        setUserDetails: (state, { payload }) => {
            state.user = payload;
        },

        reset: () => initialState,
    },
});

export const { setUserToken, logout, setUserDetails, reset } =
    authSlice.actions;
export default authSlice.reducer;
