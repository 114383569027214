import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/Solid-Tech-logo.png";
import avtar from "../../assets/images/avtar.png";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/authSlice";
import useToken from "../../hooks/useToken";
import { MdOutlineNotifications } from "react-icons/md";

import { FaRegMoon } from "react-icons/fa";
import { setTheme, toggleDrawer } from "../../features/toggleSlice";
import Notification from "./Notification";
import { Menu, Switch, Transition } from "@headlessui/react";
import ThemeControle from "./ThemeControle";
import { FaRegUserCircle } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";

const Header = () => {
    const dispatch = useDispatch();
    const { clearToken } = useToken();
    const navigate = useNavigate();
    const theme = useSelector(({ toggle }) => toggle.theme);

    return (
        <header className="bg-white dark:bg-rich-black-3 text-gray-700 dark:text-gray-300 sticky top-0 px-3 py-2 shadow-md  z-[5] transition-colors duration-300">
            <div className="flex justify-between lg:justify-end ">
                <div className="flex space-x-5 items-center lg:hidden">
                    <button
                        onClick={() => dispatch(toggleDrawer(true))}
                        className="bg-gray-50 drop-shadow-xl border-2 border-transparent
            dark:bg-rich-black dark:border-gray-500 rounded-full px-[0.4rem] py-[0.4rem] h-fit "
                    >
                        <FaChevronRight className="h-4 w-4" />
                    </button>

                    <img src={logo} alt="Solid-Tech" className="w-24 " />
                </div>

                <div className="flex items-center space-x-6 mr-5">
                    <ThemeControle />
                    <Notification />

                    <Menu as="div" className="p-2">
                        <Menu.Button>
                            <img
                                src={avtar}
                                alt=""
                                className="h-8 w-8 rounded-full"
                            />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform scale-100 opaity-100"
                            leave="transition ease-in duration-75 "
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-rich-black-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-2 px-3">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                to="/account-settings"
                                                className={`${
                                                    active
                                                        ? "bg-gray-100 dark:bg-oxford-blue"
                                                        : "text-gray-700 dark:text-gray-200"
                                                } group flex space-x-3 items-center rounded-md p-2 text-sm`}
                                            >
                                                <FaRegUserCircle className="h-5 w-5" />
                                                <span> Your Profile</span>
                                            </Link>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    dispatch(logout());
                                                    dispatch(reset());
                                                    clearToken();
                                                    navigate("/login");
                                                }}
                                                className={`${
                                                    active
                                                        ? "bg-gray-100 dark:bg-oxford-blue"
                                                        : "text-gray-700 dark:text-gray-200"
                                                } group w-full flex space-x-3 items-center rounded-md p-2 text-sm`}
                                            >
                                                <BiLogOut className="h-5 w-5" />
                                                <span> Sign Out</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                // onClick={() => setOpen(!open)}
                                                className={`${
                                                    active
                                                        ? "bg-gray-100 dark:bg-oxford-blue"
                                                        : "text-gray-700 dark:text-gray-200"
                                                } group flex space-x-3 items-center rounded-md p-2 text-sm md:hidden w-full`}
                                            >
                                                <MdOutlineNotifications className="h-5 w-5" />
                                                <span> Notification</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                className={`${
                                                    active
                                                        ? "bg-gray-100 dark:bg-oxford-blue"
                                                        : "text-gray-700 dark:text-gray-200"
                                                } group flex space-x-3 items-center rounded-md p-2 text-sm md:hidden`}
                                            >
                                                <FaRegMoon className="h-5 w-5" />

                                                <span className="cursor-default">
                                                    Dark Mode
                                                </span>
                                                <Switch
                                                    checked={theme === "dark"}
                                                    onClick={
                                                        theme === "light"
                                                            ? () =>
                                                                  dispatch(
                                                                      setTheme(
                                                                          "dark"
                                                                      )
                                                                  )
                                                            : () =>
                                                                  dispatch(
                                                                      setTheme(
                                                                          "light"
                                                                      )
                                                                  )
                                                    }
                                                    className={`${
                                                        theme === "light"
                                                            ? "bg-gray-200"
                                                            : "bg-[#E8687B]"
                                                    }
          relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={`${
                                                            theme === "dark"
                                                                ? "translate-x-4"
                                                                : "translate-x-0"
                                                        }
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                    />
                                                </Switch>
                                            </div>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </header>
    );
};

export default Header;
