import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import {
    useLazyMsaUrlQuery,
    useLazyNdaUrlQuery,
    usePartnerListQuery,
} from "../../api/nda-msaApi";
import DataTable from "../../components/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { reset, setTotalCount } from "../../features/tableSlice";
import { setLoading } from "../../features/toggleSlice";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";

const PartnerList = () => {
    const dispatch = useDispatch();
    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );

    const [msaUrl] = useLazyMsaUrlQuery();
    const [ndaUrl] = useLazyNdaUrlQuery();

    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [kamData, setKamData] = useState("");

    const { data, isLoading, isFetching, isError, error } = usePartnerListQuery(
        {
            pageIndex,
            rowCount,
            pageFilter,
        }
    );

    useEffect(() => {
        dispatch(setTotalCount(data?.last_page));
    }, [data?.last_page, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const columns = [
        {
            Header: "no",
            Cell: ({ row: { index } }) => index + 1,
        },
        {
            Header: "name",
            accessor: "name",
        },
        {
            Header: "NDA",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={async () => {
                        try {
                            const resp = await ndaUrl(value.partner_id);
                            setPdfUrl(resp.data.pdf_url);
                            setKamData(resp.data.KAM_data[0]);
                            setOpen(true);
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                >
                    View NDA
                </button>
            ),
        },
        {
            Header: "MSA",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={async () => {
                        try {
                            const resp = await msaUrl(value.partner_id);
                            setPdfUrl(resp.data.pdf_url);
                            setKamData(resp.data.KAM_data[0]);
                            setOpen(true);
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                >
                    View MSA
                </button>
            ),
        },
    ];
    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <>
                        <div className="w-11/12  mx-auto p-7 bg-white rounded-lg shadow-xl m-4 dark:bg-raisin-black">
                            <Title>NDA & MSA</Title>

                            <DataTable
                                tableColumns={columns}
                                data={data?.data}
                            />
                        </div>
                        <Modal
                            title={`KAM - ${kamData.first_name} ${kamData.last_name}`}
                            open={open}
                            setOpen={setOpen}
                        >
                            <embed
                                title={pdfUrl}
                                src={pdfUrl}
                                height={"600"}
                                width={"100%"}
                                className="mt-2"
                            />
                            {pdfUrl}
                        </Modal>
                    </>
                )
            )}
        </>
    );
};

export default PartnerList;
