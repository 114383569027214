import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Title from "../../components/Title";

const AbcoPoProcess = () => {
  return (
    <div className=" w-11/12  mx-auto">
      <Title>Abco PO Process </Title>
      <div className="space-x-8 pb-4 mb-4 border-b dark:border-b-gray-600">
        <NavLink
          className={({ isActive }) =>
            `${
              isActive
                ? "border-pink text-pink"
                : "border-transparent text-gray-500 dark:text-white"
            } border-b-2 pb-4 px-1 font-medium text-sm outline-none`
          }
          to="."
          end
        >
          Abco Process
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `${
              isActive
                ? "border-pink text-pink"
                : "border-transparent text-gray-500 dark:text-white"
            } border-b-2 pb-4 px-1 font-medium text-sm outline-none`
          }
          to="loaded-pos"
        >
          Loaded POs
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `${
              isActive
                ? "border-pink text-pink"
                : "border-transparent text-gray-500 dark:text-white"
            } border-b-2 pb-4 px-1 font-medium text-sm outline-none`
          }
          to="drafts"
        >
          Drafts
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `${
              isActive
                ? "border-pink text-pink"
                : "border-transparent text-gray-500 dark:text-white"
            } border-b-2 pb-4 px-1 font-medium text-sm outline-none`
          }
          to="monthly-invoice-pos"
        >
          Monthly Invoice POs
        </NavLink>
      </div>

      <Outlet />
    </div>
  );
};

export default AbcoPoProcess;
