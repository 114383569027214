import { useField } from "formik";
import React, { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";

const AutoComplete = ({
    options: data,
    opLabel,
    opValue,
    placeholder,
    value,
    name,
}) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const [selected, setSelected] = useState("");

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <Select
            defaultValue={selected}
            menuPosition="absolute"
            menuPlacement="bottom"
            inputId={field.name}
            placeholder={placeholder}
            value={selected}
            name={field.name}
            id={field.name}
            controlShouldRenderValue
            unstyled
            onChange={(option) => {
                setSelected(option);
                setValue(option[opValue]);
            }}
            styles={{
                input: (base) => ({
                    ...base,
                }),
                control: (base) => ({
                    ...base,
                }),
                singleValue: (base) => ({
                    ...base,
                    width: "fit-content",
                    whiteSpace: "unset",
                    overflow: "visible",
                }),

                container: (base) => ({
                    ...base,
                    position: "static",
                }),
                menu: (base) => ({
                    ...base,
                    width: "auto",
                    top: "unset",
                }),
            }}
            filterOption={createFilter({
                ignoreAccents: false,
            })}
            classNames={{
                control: ({ isFocused }) =>
                    `${
                        isFocused && "border-b border-gray-300"
                    } border-b border-transparent dark:bg-raisin-black dark:border-gray-700 hover:border-gray-300 flex-1 font-semibold rounded-lg hover:cursor-pointer ${
                        meta.error && "bg-red-100"
                    }`,

                input: () => "text-gray-700 dark:text-gray-300  ",

                singleValue: () => "text-gray-700  dark:text-gray-300",

                option: ({ isFocused, isSelected }) =>
                    `${
                        isSelected
                            ? "bg-pink dark:bg-pink text-white "
                            : "text-gray-700 dark:text-gray-300"
                    }
                    ${isFocused && "bg-gray-200 dark:bg-gray-700 "}
                    text-lg px-3 py-1 my-1 rounded-lg`,
                menuList: () => "bg-white",
                dropdownIndicator: () => "text-gray-700",
                clearIndicator: () => "text-gray-700",
                menu: () =>
                    "p-2 mt-2 bg-white border dark:bg-raisin-black rounded-lg",

                indicatorsContainer: () => `px-0 gap-0 `,
                valueContainer: () => "px-1 gap-0",
                placeholder: () => `text-gray-500 text-xs font-400`,
            }}
            options={data}
            components={{
                IndicatorSeparator: null,
                DropdownIndicator: null,
            }}
            getOptionLabel={(option) => option[opLabel]}
            getOptionValue={(option) => option[opValue]}
        />
    );
};

export default AutoComplete;
