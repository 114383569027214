import React, { memo } from "react";
import { usePagination, DOTS } from "../../hooks/usePagination";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

const paginationItem =
    "flex items-center h-8 w-8 text-sm rounded-full justify-center cursor-pointer selection:bg-gray-300 hover:bg-gray-200 font-size-";

const Pagination = (props) => {
    const {
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    function top() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    // If there are less than 2 times in pagination range we shall not render the component
    // if (/*currentPage === 0 ||*/ paginationRange.length < 2) {
    //   return null;
    // }

    // const onNext = () => {
    //   onPageChange(currentPage + 1);
    // };

    // const onPrevious = () => {
    //   onPageChange(currentPage - 1);
    // };

    // let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <ul className="flex items-center justify-center space-x-1">
            {/* Left navigation arrow */}
            <li>
                <button
                    className={`${paginationItem} ${
                        !canPreviousPage && "cursor-auto"
                    } disabled:opacity-60`}
                    onClick={() => {
                        previousPage();
                        top();
                    }}
                    disabled={!canPreviousPage}
                >
                    <FiChevronLeft className="w-4 h-4" />
                </button>
            </li>

            {paginationRange.map((pageNumber) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li className="pagination-item dots">&#8230;</li>;
                }

                // Render our Page Pills
                return (
                    <li
                        className={`${paginationItem} ${
                            pageNumber === currentPage + 1
                                ? "bg-pink text-white hover:bg-pink"
                                : "text-gray-700"
                        } select-none`}
                        onClick={() => {
                            gotoPage(pageNumber - 1);
                            top();
                        }}
                    >
                        {pageNumber}
                    </li>
                );
            })}

            {/*  Right Navigation arrow */}
            <li>
                <button
                    className={`${paginationItem} ${
                        !canNextPage && "cursor-auto"
                    }  disabled:opacity-60`}
                    onClick={() => {
                        nextPage();
                        top();
                    }}
                    disabled={!canNextPage}
                >
                    <FiChevronRight className="w-4 h-4" />
                </button>
            </li>
        </ul>
    );
};

export default memo(Pagination);
