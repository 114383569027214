import React, { Fragment, memo } from "react";
import { Field, FieldArray } from "formik";
import { FiMinus, FiPlus } from "react-icons/fi";
import { usePaymentDueDaysQuery } from "../../api/poProcessApi";
import TnmSalesTable from "./TnmSalesTable";
import TnmPurchaseTable from "./TnmPurchaseTable";
import { PaymentDueDaysApproved } from "./MonthlyInvForm";
import { salesTotalAmount, totalNLC } from "../../helper";
import { PrimaryContactList } from "./AbcoPoForm";

const className =
    "mt-1 border-b border-transparent bg-transparent px-3 py-2 focus:border-gray-300 focus:outline-none hover:border-gray-300";

const SPRDetails = ({
    dueDaysId,
    setFieldValue,
    primaryContact,
    spocEmail,
    spocName,
    spocPhoneNumber,
    salesData,
    purchaseData,
    dutyAmount,
    shipmentAmount,
}) => {
    console.log("spr details");
    const { data: paymentDueDaysList } = usePaymentDueDaysQuery();

    return (
        <>
            <div className="col-span-12 form-group-title text-center text-xl">
                SPR Details
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <label htmlFor="" className="form-label">
                    Payment Due Days
                </label>
                <Field
                    as="select"
                    name="paymentDueDaysId"
                    id="paymentDueDaysId"
                    className=" form-select"
                >
                    <option value="">Select Due Days</option>
                    {paymentDueDaysList?.map(({ id, days }) => (
                        <option key={id} value={id}>
                            {days}
                        </option>
                    ))}
                </Field>
            </div>

            <PaymentDueDaysApproved list={paymentDueDaysList} id={dueDaysId} />

            <div className="col-span-12 lg:col-span-3">
                <label htmlFor="" className="form-label">
                    GST Number
                </label>
                <Field
                    type="text"
                    name="gstNumber"
                    id="gstNumber"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-3">
                <label htmlFor="" className="form-label">
                    Invoice To
                </label>
                <Field
                    type="text"
                    name="invoiceTo"
                    id="invoiceTo"
                    className="form-input"
                />
            </div>

            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="" className="form-label">
                    Invoice Bill To
                </label>
                <Field
                    as="textarea"
                    name="invoiceBillTo"
                    id="invoiceBillTo"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="" className="form-label">
                    Invoice Ship To
                </label>
                <Field
                    as="textarea"
                    name="invoiceShipTo"
                    id="invoiceShipTo"
                    className="form-input"
                />
            </div>
            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="" className="form-label">
                    Service Deliver To
                </label>
                <Field
                    as="textarea"
                    name="spareShipTo"
                    id="spareShipTo"
                    className="form-input"
                />
            </div>
            {/* <div className="col-span-12 form-group-title">Primary Contact</div>
            <FieldArray name="primaryContactList">
                {({ push, remove }) => (
                    <>
                        {primaryContact?.map((_, i) => (
                            <Fragment key={i}>
                                <div className="col-span-12 lg:col-span-4">
                                    <label htmlFor="" className="form-label">
                                        Name
                                    </label>
                                    <Field
                                        type="text"
                                        name={`primaryContactList.${i}.name`}
                                        id={`primaryContactList.${i}.name`}
                                        className="form-input"
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-3">
                                    <label htmlFor="" className="form-label">
                                        Phone Number
                                    </label>
                                    <Field
                                        type="text"
                                        name={`primaryContactList.${i}.phoneNumber`}
                                        id={`primaryContactList.${i}.phoneNumber`}
                                        className="form-input"
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-4">
                                    <label htmlFor="" className="form-label">
                                        Email
                                    </label>
                                    <Field
                                        type="email"
                                        name={`primaryContactList.${i}.email`}
                                        id={`primaryContactList.${i}.email`}
                                        className="form-input"
                                    />
                                </div>
                                {i ? (
                                    <div
                                        className="col-span-1 justify-self-center self-center"
                                        key={i + 3}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => remove(i)}
                                        >
                                            <FiMinus className="h-5 w-5" />
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className="col-span-1 justify-self-center self-center"
                                        key={i + 4}
                                    >
                                        <button
                                            type="button"
                                            onClick={() =>
                                                push({
                                                    name: "",
                                                    email: "",
                                                    phoneNumber: "",
                                                })
                                            }
                                        >
                                            <FiPlus className="h-5 w-5" />
                                        </button>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </>
                )}
            </FieldArray> */}
            <PrimaryContactList
                primaryContact={primaryContact}
                setFieldValue={setFieldValue}
                spocEmail={spocEmail}
                spocName={spocName}
                spocPhoneNumber={spocPhoneNumber}
            />

            <SalesDetails values={salesData} />
            <PurchaseDetails
                values={purchaseData}
                salesValue={salesData}
                dutyAmount={dutyAmount}
                shipmentAmount={shipmentAmount}
            />
        </>
    );
};

export default memo(SPRDetails);

export const SalesDetails = memo(({ name = "tnmSalesData", values }) => {
    console.log(values);
    console.log("sales details");

    return (
        <>
            <div className="col-span-12">
                <FieldArray name="tnmSalesData">
                    {({ push, remove }) => (
                        <>
                            <div className="flex justify-between">
                                <div className="form-group-title">
                                    T and M Sales Details
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="bg-gray-200 py-1 px-3 rounded-lg text-sm"
                                        onClick={() =>
                                            push({
                                                description: "",
                                                qty: "",
                                                rate: "",
                                                remarks: "",
                                            })
                                        }
                                    >
                                        <FiPlus className="inline h-4 w-4 mr-1" />
                                        Add
                                    </button>
                                </div>
                            </div>

                            <TnmSalesTable values={values} remove={remove} />
                        </>
                    )}
                </FieldArray>
            </div>
            <TotalAmount data={values} />
        </>
    );
});

export const PurchaseDetails = memo(
    ({ values, salesValue, dutyAmount, shipmentAmount }) => {
        console.log("purchase details");

        const totalAmount = salesTotalAmount(salesValue);
        const totalNlc = totalNLC(values, dutyAmount, shipmentAmount);
        const grossProfit = totalAmount - totalNlc;
        const grossProfitPercentage = Math.round(
            ((totalAmount - totalNlc) / totalNlc) * 100
        );

        return (
            <>
                <div className="col-span-12">
                    <FieldArray name="tnmPurchaseData">
                        {({ push, remove }) => (
                            <>
                                <div className="flex justify-between">
                                    <div className="form-group-title">
                                        T and M Purchase Details
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="bg-gray-200 py-1 px-3 rounded-lg text-sm"
                                            onClick={() =>
                                                push({
                                                    supplierName: "",
                                                    description: "",
                                                    qty: "",
                                                    rate: "",
                                                    remarks: "",
                                                })
                                            }
                                        >
                                            <FiPlus className="inline h-4 w-4 mr-1" />
                                            Add
                                        </button>
                                    </div>
                                </div>

                                <TnmPurchaseTable
                                    values={values}
                                    remove={remove}
                                />
                            </>
                        )}
                    </FieldArray>
                </div>
                <div className="col-span-12 place-self-center mt-3">
                    <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6">
                        <dl className="rounded-md text-sm flex flex-col">
                            <div className="border-b flex items-baseline justify-between py-3 md:basis-1/2">
                                <dt className="text-gray-500 dark:text-gray-400">
                                    If International Purchase, Approx, Duty
                                    Amount
                                </dt>
                                <dd className="text-gray-900 dark:text-gray-100">
                                    <Field
                                        name="dutyAmount"
                                        id="dutyAmount"
                                        className={className + " text-end"}
                                    />
                                </dd>
                            </div>
                            <div className="border-b flex items-baseline justify-between py-3 md:basis-1/2">
                                <dt className="text-gray-500 dark:text-gray-400">
                                    If International Purchase, Approx, Shipment
                                    Amount
                                </dt>
                                <dd className="text-gray-900 dark:text-gray-100">
                                    <Field
                                        name="shipmentAmount"
                                        id="shipmentAmount"
                                        className={className + " text-end"}
                                    />
                                </dd>
                            </div>
                            <div className="border-b flex items-baseline justify-between py-3 md:basis-1/2">
                                <dt className="text-gray-500 dark:text-gray-400">
                                    Total NLC(Net Landing Cost,Excl. Tax)
                                </dt>
                                <dd className="px-3 text-gray-900 dark:text-gray-100">
                                    {totalNlc}
                                </dd>
                            </div>
                            <div className="flex items-baseline justify-between py-3 md:basis-1/2">
                                <dt className="text-gray-500 dark:text-gray-400">
                                    Gross Profit
                                </dt>
                                <dd className="px-3 text-gray-900 dark:text-gray-100">
                                    {` ${grossProfit} (${grossProfitPercentage}%)`}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </>
        );
    }
);

const TotalAmount = memo(({ data }) => {
    console.log(data);
    console.log("total amount");

    return (
        <div className="col-span-12 mt-3">
            <div className="bg-gray-50 dark:bg-raisin-black rounded-2xl p-6">
                <dl className="rounded-md text-sm flex flex-col">
                    <div className="border-b flex items-baseline justify-between py-3 md:basis-1/2">
                        <dt className="text-gray-500 dark:text-gray-400">
                            Total
                        </dt>
                        <dd className="text-gray-900 dark:text-gray-100">
                            {salesTotalAmount(data)}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
});
