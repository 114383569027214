import { apiSlice } from "./apiSlice";

const nda_msa_Api = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        partnerList: builder.query({
            query: ({ pageFilter, pageIndex, rowCount }) => ({
                url: "abco-po-process/partnerList",
                params: {
                    page: pageIndex + 1,
                    rowsCount: rowCount,
                    keywords: pageFilter,
                },
            }),
        }),

        ndaUrl: builder.query({
            query: (id) => `abco-po-process/partner_nda_file/${id}`,
        }),
        msaUrl: builder.query({
            query: (id) => `abco-po-process/partner_msa_file/${id}`,
        }),
    }),
});

export const { usePartnerListQuery, useLazyNdaUrlQuery, useLazyMsaUrlQuery } =
    nda_msa_Api;
