import React from "react";
import Title from "../../components/Title";
import avtar from "../../assets/images/avtar.png";
import { FiEdit3 } from "react-icons/fi";

const EditProfile = () => {
  return (
    <div className="card w-11/12">
      <Title>Edit Profile</Title>

      <div className="relative left-1/2 -translate-x-1/2 inline-block mx-auto mb-7">
        <img src={avtar} alt="Solid-Tech" className="w-24 rounded-full" />
        <label htmlFor="profile">
          <input id="profile" type="file" className="hidden" />
          <span className="absolute right-0 bottom-0 bg-slate-100 p-2 rounded-full text-gray-600">
            <FiEdit3 className="h-4 w-4" />
          </span>
        </label>
      </div>
      <form>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 form-group-title">Basic Information</div>
          <div className="col-span-6 sm:col-span-2 self-center">
            <label htmlFor="name" className="form-lable">
              Full Name
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <div className="flex">
              <input
                type="text"
                name="firstName"
                className="form-input rounded-r-none"
              />
              <input
                type="text"
                name="lastName"
                className="form-input rounded-l-none"
              />
            </div>
          </div>
          <div className="col-span-6 sm:col-span-2 self-center">
            <label htmlFor="email" className="form-lable">
              Email
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <input name="email" type="email" className="form-input" />
          </div>
          <div className="col-span-6 sm:col-span-2 self-center">
            <label htmlFor="phNumber" className="form-lable">
              Phone Number
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <input name="phNumber" type="text" className="form-input" />
          </div>
          <hr className="col-span-6" />
          <div className="col-span-6 form-group-title">Job Description</div>
          <div className="col-span-6 sm:col-span-2 self-center ">
            <label htmlFor="" className="form-lable">
              Job Profile
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <select className="form-select" name="" id="">
              <option value="">Sales Operation Executive</option>
            </select>
          </div>
          <div className="col-span-6 sm:col-span-2 self-center ">
            <label htmlFor="" className="form-lable">
              Time Zone Region
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <select className="form-select" name="" id="">
              <option value="">Asia</option>
            </select>
          </div>
          <div className="col-span-6 sm:col-span-2 self-center ">
            <label htmlFor="" className="form-lable">
              Time Zone
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <select className="form-select" name="" id="">
              <option value="">Asia/India</option>
            </select>
          </div>
          <div className="col-span-6 sm:col-span-2 self-center ">
            <label htmlFor="" className="form-lable">
              Solid Company
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <select className="form-select" name="" id="">
              <option value="">Solid-India</option>
            </select>
          </div>{" "}
          <hr className="col-span-6" />
          <div className="col-span-6 form-group-title">
            Change your password
          </div>
          <div className="col-span-6 sm:col-span-2 self-center">
            <label htmlFor="userName" className="form-lable">
              User Name
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <input name="userName" type="text" className="form-input" />
          </div>
          <div className="col-span-6 sm:col-span-2 self-center">
            <label htmlFor="password" className="form-lable">
              Password
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <input name="password" type="text" className="form-input" />
          </div>
          <div className="col-span-6 sm:col-span-2 self-center">
            <label htmlFor="conf irmPassword" className="form-lable">
              Re-Enter Password
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <input name="confirmPassword" type="text" className="form-input" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
