import React from "react";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useToken from "../hooks/useToken";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import PageNotFound from "../components/Error/PageNotFound";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUserDetailsQuery } from "../api/authApi";
import { setUserDetails } from "../features/authSlice";

const Layout = () => {
    const { token } = useToken();
    const location = useLocation();
    const dispatch = useDispatch();
    const { data, isSuccess } = useUserDetailsQuery();

    useEffect(() => {
        if (isSuccess) {
            dispatch(setUserDetails(data[0]));
        }
    }, [data, dispatch, isSuccess]);

    const { theme } = useSelector(({ toggle }) => toggle);

    return (
        <div className={`flex items-stretch ${theme === "dark" ? "dark" : ""}`}>
            <Sidebar />
            <div
                className={`flex flex-col flex-1 w-0 max-w-full h-screen lg:h-auto`}
            >
                <Header />
                <Main>
                    {token ? (
                        <Outlet />
                    ) : (
                        <Navigate
                            to="/login"
                            replace={true}
                            state={{ from: location }}
                        />
                    )}
                </Main>
                <Footer />
            </div>
        </div>
    );
};

export default Layout;

const Main = ({ children }) => {
    return (
        <main className="flex-1 bg-white dark:bg-rich-black-2 relative  transition-colors duration-300 pb-6">
            <Loader />
            {children}
        </main>
    );
};

export const Admin = () => {
    const { user } = useSelector(({ auth }) => auth);
    console.log(user.user_id === (38 || 55));

    return <>{+user.user_id === (38 || 55) ? <Outlet /> : <PageNotFound />}</>;
};

export const Private = ({ userId }) => {
    // const { user } = useSelector(({ auth }) => auth);
    // return <>{+user.user_id === userId ? <Outlet /> : <PageNotFound />}</>;
};
