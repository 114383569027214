import React, { useEffect, useState } from "react";
import CountryDetails from "./CountryDetails";
import { HiOutlineTrash } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import Modal from "../../components/modal";
import Title from "../../components/Title";
import DataTable from "../../components/Tables/DataTable";
import {
    useDeleteCountryMutation,
    useGetCountryListQuery,
} from "../../api/adminApi";
import { reset, setTotalCount } from "../../features/tableSlice";
import { useDispatch, useSelector } from "react-redux";
import AddCountry from "./AddCountry";
import { setLoading } from "../../features/toggleSlice";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";
import { ToastContainer, toast } from "react-toastify";

const Country = () => {
    const dispatch = useDispatch();

    const { pageIndex, rowCount, pageFilter } = useSelector(
        ({ table }) => table
    );
    const {
        data: countryList,
        isLoading,
        isError,
        isFetching,
        error,
    } = useGetCountryListQuery({
        pageIndex,
        rowCount,
        pageFilter,
    });
    const [deleteCountry] = useDeleteCountryMutation();

    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [countryId, setCountryId] = useState(null);

    const tableData = countryList?.data;

    useEffect(() => {
        dispatch(setTotalCount(countryList?.last_page));
        return () => dispatch(reset());
    }, [countryList?.last_page, dispatch]);

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "no.",
            accessor: "country_id",
        },
        {
            Header: "Name",
            accessor: "country_name",
        },

        {
            Header: "edit",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={() => {
                        setEditModal(true);
                        setCountryId(value.country_id);
                    }}
                    className="hover:bg-blue-100 p-2 rounded-full"
                >
                    <BiEditAlt className="h-5 w-5 hover:text-blue-500" />
                </button>
            ),
        },
        {
            Header: "delete",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={async () => {
                        try {
                            const resp = await deleteCountry(
                                value.country_id
                            ).unwrap();
                            toast.success(resp.message);
                        } catch (error) {
                            toast.error(error.data.error);
                        }
                    }}
                >
                    <HiOutlineTrash className="h-5 w-5" />
                </button>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="card w-11/12">
                        <Title>Country</Title>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setAddModal(true)}
                                className="bg-gray-100 hover:bg-gray-200  active:bg-gray-300 focus:outline-none py-2 px-3  rounded-lg  dark:bg-charcoal dark:text-gray-300"
                            >
                                <FiPlus className="h-4 w-4 inline mr-3" />
                                Add New Country
                            </button>
                        </div>

                        <AddCountry open={addModal} setOpen={setAddModal} />

                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />

                        <Modal
                            open={editModal}
                            setOpen={setEditModal}
                            title="Edit Country"
                        >
                            <CountryDetails
                                id={countryId}
                                setOpen={setEditModal}
                            />
                        </Modal>
                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                            theme="colored"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default Country;
