import { Route, Routes } from "react-router-dom";
import Layout, { Admin, Private } from "./layout";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import PoDetails from "./pages/poVerification";
import NotLoadedPos from "./pages/unLoadedPo";
import AbcoPoProcess from "./pages/abcoPoProcess";
import Drafts from "./pages/abcoPoProcess/Drafts";
import PoProcessTable from "./pages/abcoPoProcess/PoProcessTable";
import MonthlyInvTable from "./pages/abcoPoProcess/MonthlyInvTable";
import PoProcess from "./pages/abcoPoProcess/PoProcess";
import Country from "./pages/Admin/Country";
import State from "./pages/Admin/State";
import City from "./pages/Admin/City";
import EditProfile from "./pages/UserProfile";
import SupportType from "./pages/Admin/SupportType";
import Oem from "./pages/Admin/Oem";
import Sla from "./pages/Admin/Sla";
import ProductType from "./pages/Admin/ProductType";
import Model from "./pages/Admin/Model";
import PageNotFound from "./components/Error/PageNotFound";
import PartnerList from "./pages/NDA-MSA/PartnerList";

function App() {
    return (
        <>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="account-settings" element={<EditProfile />} />
                    <Route path="verify-po-details" element={<PoDetails />} />
                    <Route path="all-un-loaded-po" element={<NotLoadedPos />} />

                    <Route path="abco-po-process" element={<AbcoPoProcess />}>
                        <Route index element={<PoProcess />} />
                        <Route path="drafts" element={<Drafts />} />
                        <Route
                            path="monthly-invoice-pos"
                            element={<MonthlyInvTable />}
                        />
                        <Route path="loaded-pos" element={<PoProcessTable />} />
                    </Route>
                    {/* <Route element={<Private userId={55} />}> */}
                    <Route path="nda-msa" element={<PartnerList />} />
                    {/* </Route> */}
                    <Route path="admin" element={<Admin />}>
                        <Route path="country" element={<Country />} />
                        <Route path="state" element={<State />} />
                        <Route path="city" element={<City />} />
                        <Route path="support-type" element={<SupportType />} />
                        <Route path="oem" element={<Oem />} />
                        <Route path="sla" element={<Sla />} />
                        <Route path="product-type" element={<ProductType />} />
                        <Route oute path="model" element={<Model />} />
                    </Route>
                </Route>

                <Route path="*" element={<PageNotFound />} />
                {/* <Route path="/account-settings" element={<EditProfile />} />
                 */}

                {/* 
        <Route path="forgot-password" element={<ForgotPassword />} /> */}
            </Routes>
        </>
    );
}

export default App;
