import { apiSlice } from "./apiSlice";

const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    motivationalQuote: builder.query({
      query: () => "dashboard/motivational-quote",
    }),

    rewards: builder.query({
      query: () => "dashboard/rewards",
    }),
  }),
});

export const { useMotivationalQuoteQuery, useRewardsQuery } = dashboardApi;
