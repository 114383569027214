import { Field, FieldArray, getIn, useFormikContext } from "formik";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { GrFormUpload, GrDownload } from "react-icons/gr";
import { HiPlus, HiOutlineTrash } from "react-icons/hi";
import boqexcelFile from "../../assets/Boq Sample Data.xlsx";
import {
    useNewModelListQuery,
    useOemListQuery,
    usePoCityListQuery,
    usePoCountryListQuery,
    usePoRegionListQuery,
    usePoStateListQuery,
    useProductTypeListQuery,
    useSlaListQuery,
    useSupportTypeListQuery,
} from "../../api/poProcessApi";
import { dayDifference, endDate } from "../../helper";
import { read, utils } from "xlsx";
import { useGetCityQuery, useGetStateQuery } from "../../api/adminApi";
import AutoComplete from "../../components/AutocompleteField";

let boqDetails = {
    oem: "",
    product_type: "",
    customerModelNumber: "",
    model_number: "",
    serial_number: "",
    category: "",
    support_type: "",
    sla: "",
    start_date: "",
    end_date: "",
    support_period: "",
    price: "",
    country_name: "",
    state_name: "",
    city_name: "",
    region_name: "",
    boq_remarks: "",
};

const OemList = ({ name, index, value }) => {
    const { data: oemList, isLoading } = useOemListQuery();

    return (
        <>
            {!isLoading && (
                <AutoComplete
                    options={oemList}
                    name={`${name}[${index}].oem`}
                    opLabel={"oem"}
                    opValue={"oem_id"}
                    placeholder={"Select oem  "}
                    value={oemList?.find((e) => e.oem_id === +value) || ""}
                />
            )}
        </>
    );
};

const CustomerModelNumber = ({ name, index, className }) => {
    return (
        <Field
            name={`${name}[${index}].customerModelNumber`}
            className={className}
            placeholder="Enter Model Number"
        />
    );
};
const ModelNumberList = ({ name, index, value }) => {
    const { data: newModelList, isLoading } = useNewModelListQuery();
    return (
        <>
            {!isLoading && (
                <AutoComplete
                    options={newModelList}
                    name={`${name}[${index}].model_number`}
                    opLabel={"new_model"}
                    opValue={"new_model_id"}
                    placeholder={"Select Model Number"}
                    value={
                        newModelList?.find((e) => e.new_model_id === +value) ||
                        ""
                    }
                />
            )}
        </>
    );
};

const SerialNo = ({ name, index, className }) => (
    <Field
        name={`${name}[${index}].serial_number`}
        className={className}
        placeholder="Enter SN"
    />
);

const Category = ({ name, index, className }) => (
    <Field
        name={`${name}[${index}].category`}
        className={className + " appearance-none"}
        as="select"
    >
        <option value="">Select Category</option>
        <option value="NEW">NEW</option>
        <option value="RENEWAL">RENEWAL</option>
    </Field>
);

const ProductTypeList = ({ name, index, className }) => {
    const { data: productTypeList } = useProductTypeListQuery();
    return (
        <Field
            name={`${name}[${index}].product_type`}
            className={className + " appearance-none"}
            as="select"
        >
            <option value="">Select Product Type</option>
            {productTypeList &&
                productTypeList.map(({ product_type, product_type_id }) => (
                    <option key={product_type_id} value={product_type_id}>
                        {product_type}
                    </option>
                ))}
        </Field>
    );
};

const SupportTypeList = ({ name, index, className }) => {
    const { data: supportTypeList } = useSupportTypeListQuery();

    return (
        <Field
            name={`${name}[${index}].support_type`}
            className={className + " appearance-none"}
            as="select"
        >
            <option value="">Select Support Type</option>
            {supportTypeList &&
                supportTypeList.map(({ support_type, support_type_id }) => (
                    <option key={support_type_id} value={support_type_id}>
                        {support_type}
                    </option>
                ))}
        </Field>
    );
};

const SLAList = ({ name, index, className }) => {
    const { data: slaList } = useSlaListQuery();
    return (
        <Field
            name={`${name}[${index}].sla`}
            className={className + " appearance-none"}
            as="select"
        >
            <option value="">Select SLA</option>
            {slaList &&
                slaList.map(({ sla, sla_id }) => (
                    <option key={sla_id} value={sla_id}>
                        {sla}
                    </option>
                ))}
        </Field>
    );
};

const StartDate = ({ name, index, className }) => (
    <Field
        type="date"
        name={`${name}[${index}].start_date`}
        className={className}
    />
);

const EndDate = ({ name, index, className }) => {
    const { values, setFieldValue } = useFormikContext();
    const formikSlice = getIn(values, name);

    const start_date = formikSlice[index].start_date;

    useEffect(() => {
        if (start_date.length !== 0) {
            setFieldValue(`${name}[${index}].end_date`, endDate(start_date));
        }
    }, [start_date, setFieldValue, name, index]);

    return (
        <Field
            type="date"
            name={`${name}[${index}].end_date`}
            className={className}
        />
    );
};

const SupportPeroid = ({ name, index, className }) => {
    const { values, setFieldValue } = useFormikContext();
    const formikSlice = getIn(values, name);

    const start_date = formikSlice[index].start_date;
    const end_date = formikSlice[index].end_date;

    useEffect(() => {
        setFieldValue(
            `${name}[${index}].support_period`,
            dayDifference(start_date, end_date)
        );
    }, [end_date, index, name, setFieldValue, start_date]);

    return (
        <Field
            name={`${name}[${index}].support_period`}
            readOnly
            className={className}
        />
    );
};

const CountryLits = ({ name, index, className }) => {
    const { data: countryList } = usePoCountryListQuery();
    return (
        <Field
            as="select"
            name={`${name}[${index}].country_name`}
            className={className + " appearance-none"}
        >
            <option value="">Select Country</option>
            {countryList &&
                countryList.map(({ country_name, country_id }) => (
                    <option value={country_id} key={country_id}>
                        {country_name}
                    </option>
                ))}
        </Field>
    );
};

const StateList = ({ name, index, className }) => {
    const { values } = useFormikContext();
    const formikSlice = getIn(values, name);

    const country = formikSlice[index].country_name;
    const { data: stateList } = usePoStateListQuery(country);

    return (
        <Field
            as="select"
            name={`${name}[${index}].state_name`}
            className={className + " appearance-none"}
        >
            <option value="">Select State</option>
            {stateList &&
                stateList.map(({ state_name, state_id }) => (
                    <option key={state_id} value={state_id}>
                        {state_name}
                    </option>
                ))}
        </Field>
    );
};

const CityList = ({ name, index, className }) => {
    const { values } = useFormikContext();
    const formikSlice = getIn(values, name);

    const state = formikSlice[index].state_name;
    const { data: cityList } = usePoCityListQuery(state);

    return (
        <Field
            as="select"
            name={`${name}[${index}].city_name`}
            className={className + " appearance-none"}
        >
            <option value="">Select City</option>
            {cityList &&
                cityList.map(({ city_name, city_id }) => (
                    <option key={city_id} value={city_id}>
                        {city_name}
                    </option>
                ))}
        </Field>
    );
};

const RegionList = ({ name, index, className }) => {
    const { data: regionList } = usePoRegionListQuery();
    return (
        <Field
            as="select"
            name={`${name}[${index}].region_name`}
            className={className + " appearance-none"}
        >
            <option value="">Select Region</option>
            {regionList &&
                regionList.map(({ region, region_id }) => (
                    <option key={region_id} value={region_id}>
                        {region}
                    </option>
                ))}
        </Field>
    );
};

const Remarks = ({ name, index, className }) => (
    <Field
        name={`${name}[${index}].boq_remarks`}
        className={className}
        placeholder="Any Remarks !"
    />
);

const TotalPrice = ({ name, index, className }) => (
    <Field
        name={`${name}[${index}].price`}
        className={className}
        placeholder="Enter Total Price"
    />
);

const BOQDetails = ({ values, errors, touched, handleChange }) => {
    let prevRow = values ? values[values?.length - 1] : {};
    const [isLastRow, setIsLastRow] = useState(false);

    let nextRow = useMemo(
        () => ({
            ...boqDetails,
            support_type: prevRow?.support_type || "",
            sla: prevRow?.sla || "",
            start_date: prevRow?.start_date || "",
            end_date: prevRow?.end_date || "",
            support_period: prevRow?.support_period || "",
            country_name: prevRow?.country_name || "",
            state_name: prevRow?.state_name || "",
            city_name: prevRow?.city_name || "",
            region_name: prevRow?.region_name || "",
            oem: prevRow?.oem || "",
            product_type: prevRow?.product_type || "",
            customerModelNumber: prevRow?.customerModelNumber || "",
            model_number: prevRow?.model_number || "",
            category: prevRow?.category || "",
            boq_remarks: prevRow?.boq_remarks || "",
        }),
        [
            prevRow?.boq_remarks,
            prevRow?.category,
            prevRow?.city_name,
            prevRow?.country_name,
            prevRow?.customerModelNumber,
            prevRow?.end_date,
            prevRow?.model_number,
            prevRow?.oem,
            prevRow?.product_type,
            prevRow?.region_name,
            prevRow?.sla,
            prevRow?.start_date,
            prevRow?.state_name,
            prevRow?.support_period,
            prevRow?.support_type,
        ]
    );

    if (touched && !errors && !isLastRow) {
        values.push(nextRow);
    }

    const name = "boqDetails";
    const className =
        "mt-1 w-full border-b border-transparent bg-transparent px-1 py-2 focus:border-gray-300 focus:outline-none hover:border-gray-300";

    return (
        <FieldArray name="boqDetails">
            {({ push, remove, pop }) => (
                <>
                    <div className="flex justify-between">
                        <div className="form-group-title">BOQ Details</div>
                        <div className="flex space-x-7">
                            <a
                                href={boqexcelFile}
                                download="BOQ Template"
                                type="button"
                                className="p-2 flex  items-center justify-center rounded-lg text-sm"
                            >
                                <GrDownload className="inline h-4 w-4 mr-3" />
                                BOQ Template
                            </a>
                            <label
                                htmlFor="file-upload"
                                className="bg-gray-200 p-2 flex items-center justify-center space-x-3 rounded-lg cursor-pointer"
                            >
                                <GrFormUpload className="h-4 w-4 mr-3" />
                                upload excel file
                            </label>
                            <ImportFileData />

                            <button
                                type="button"
                                className="bg-gray-200 py-1 px-3 rounded-lg text-sm"
                                onClick={() => {
                                    push(nextRow);
                                }}
                            >
                                <HiPlus className="inline h-4 w-4 mr-3" />
                                Add
                            </button>
                        </div>
                    </div>

                    {/* <BOQTable
                        name={"boqDetails"}
                        remove={remove}
                        values={values}
                        pop={pop}
                        nextRow={nextRow}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                    /> */}
                    <div className="overflow-x-auto mt-10 rounded-lg">
                        <table className="text-left text-gray-500 dark:text-gray-400 text-sm mb-4">
                            <thead className="capitalize text-xs text-gray-700 dark:text-gray-200">
                                <tr>
                                    <th className="sticky left-0 py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        no.
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        OEM
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Customer Model No
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Model No
                                    </th>

                                    <th className="whitespace-nowrap py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Serial No
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Category
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Product Type
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Support Type
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        SLA
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Start Date
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        End Date
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Support Peroid
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Country
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        State
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        City
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Region
                                    </th>
                                    <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Remarks
                                    </th>
                                    <th className="whitespace-nowrap py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
                                        Total Price
                                    </th>
                                    <th className="py-3 px-2 bg-gray-100 dark:bg-charcoal">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {values.map((_, i) => (
                                    <tr key={i}>
                                        <td className="z-[1] px-2 py-2 border-b border-r bg-white sticky left-0">
                                            <span
                                                className={`${
                                                    errors &&
                                                    Object.values(
                                                        errors[i] || {}
                                                    ).length !== 0 &&
                                                    "text-white rounded-lg bg-red-400"
                                                } p-2 font-semibold text-center`}
                                            >
                                                {i + 1}
                                            </span>
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <OemList
                                                name={name}
                                                index={i}
                                                value={values[i].oem}
                                                className={`${className}
                                        ${
                                            errors &&
                                            errors[i] &&
                                            errors[i]?.oem !== undefined &&
                                            "field-error"
                                        }
                                    `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <CustomerModelNumber
                                                name={name}
                                                index={i}
                                                className={`${className}
                                        ${
                                            errors &&
                                            errors[i] &&
                                            errors[i]?.customerModelNumber !==
                                                undefined &&
                                            "field-error"
                                        }
                                    `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <ModelNumberList
                                                name={name}
                                                value={values[i].model_number}
                                                index={i}
                                                handleChange={handleChange}
                                                className={`${className} ${
                                                    errors &&
                                                    errors[i] &&
                                                    errors[i]?.model_number !==
                                                        undefined &&
                                                    "field-error"
                                                }`}
                                            />
                                        </td>

                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <SerialNo
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.serial_number !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <Category
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.category !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <ProductTypeList
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.product_type !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <SupportTypeList
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.support_type !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <SLAList
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.sla !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <StartDate
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.start_date !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <EndDate
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.end_date !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <SupportPeroid
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.support_period !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <CountryLits
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.country_name !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <StateList
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.state_name !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <CityList
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.city_name !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <RegionList
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.region_name !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <Remarks
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.boq_remarks !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b border-r bg-white">
                                            <TotalPrice
                                                name={name}
                                                index={i}
                                                className={`${className}
                        ${
                            errors &&
                            errors[i] &&
                            errors[i]?.price !== undefined &&
                            "field-error"
                        }
                        `}
                                            />
                                        </td>
                                        <td className="px-2 py-2 border-b bg-white">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    values.length === 1
                                                        ? null
                                                        : values.length ===
                                                          i + 1
                                                        ? (setIsLastRow(true),
                                                          pop())
                                                        : remove(i)
                                                }
                                            >
                                                <HiOutlineTrash className="h-5 w-5" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </FieldArray>
    );
};

// const BOQTable = ({
//     name,
//     touched,
//     remove,
//     nextRow,
//     values,
//     pop,
//     errors,
//     handleChange,
// }) => {
//     const className =
//         "mt-1 w-full border-b border-transparent bg-transparent px-1 py-2 focus:border-gray-300 focus:outline-none hover:border-gray-300";

//     // useEffect(() => {
//     if (touched && !errors) {
//         values.push(nextRow);
//     }
//     // }, [errors, nextRow, touched, values]);

//     return (
//         <div className="overflow-x-auto mt-10 rounded-lg">
//             <table className="text-left text-gray-500 dark:text-gray-400 text-sm mb-4">
//                 <thead className="capitalize text-xs text-gray-700 dark:text-gray-200">
//                     <tr>
//                         <th className="sticky left-0 py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             no.
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             OEM
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Customer Model No
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Model No
//                         </th>

//                         <th className="whitespace-nowrap py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Serial No
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Category
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Product Type
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Support Type
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             SLA
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Start Date
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             End Date
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Support Peroid
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Country
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             State
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             City
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Region
//                         </th>
//                         <th className="py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Remarks
//                         </th>
//                         <th className="whitespace-nowrap py-3 px-2 border-r bg-gray-100 dark:bg-charcoal">
//                             Total Price
//                         </th>
//                         <th className="py-3 px-2 bg-gray-100 dark:bg-charcoal">
//                             Action
//                         </th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {values.map((_, i) => (
//                         <tr key={i}>
//                             <td className="z-[1] px-2 py-2 border-b border-r bg-white sticky left-0">
//                                 <span
//                                     className={`${
//                                         errors &&
//                                         Object.values(errors[i] || {})
//                                             .length !== 0 &&
//                                         "text-white rounded-lg bg-red-400"
//                                     } p-2 font-semibold text-center`}
//                                 >
//                                     {i + 1}
//                                 </span>
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <OemList
//                                     name={name}
//                                     index={i}
//                                     value={values[i].oem}
//                                     className={`${className}
//                                         ${
//                                             errors &&
//                                             errors[i] &&
//                                             errors[i]?.oem !== undefined &&
//                                             "field-error"
//                                         }
//                                     `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <CustomerModelNumber
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                                         ${
//                                             errors &&
//                                             errors[i] &&
//                                             errors[i]?.customerModelNumber !==
//                                                 undefined &&
//                                             "field-error"
//                                         }
//                                     `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <ModelNumberList
//                                     name={name}
//                                     value={values[i].model_number}
//                                     index={i}
//                                     handleChange={handleChange}
//                                     className={`${className} ${
//                                         errors &&
//                                         errors[i] &&
//                                         errors[i]?.model_number !== undefined &&
//                                         "field-error"
//                                     }`}
//                                 />
//                             </td>

//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <SerialNo
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.serial_number !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <Category
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.category !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <ProductTypeList
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.product_type !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <SupportTypeList
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.support_type !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <SLAList
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.sla !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <StartDate
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.start_date !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <EndDate
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.end_date !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <SupportPeroid
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.support_period !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <CountryLits
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.country_name !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <StateList
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.state_name !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <CityList
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.city_name !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <RegionList
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.region_name !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <Remarks
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.boq_remarks !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b border-r bg-white">
//                                 <TotalPrice
//                                     name={name}
//                                     index={i}
//                                     className={`${className}
//                         ${
//                             errors &&
//                             errors[i] &&
//                             errors[i]?.price !== undefined &&
//                             "field-error"
//                         }
//                         `}
//                                 />
//                             </td>
//                             <td className="px-2 py-2 border-b bg-white">
//                                 <button
//                                     type="button"
//                                     onClick={() =>
//                                         // values.length === i + 1
//                                         //     ? pop()
//                                         //     :
//                                         remove(i)
//                                     }
//                                 >
//                                     <HiOutlineTrash className="h-5 w-5" />
//                                 </button>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };
const ImportFileData = () => {
    const { values, setFieldValue } = useFormikContext();
    const boqData = getIn(values, "boqDetails");

    const { data: regionList } = usePoRegionListQuery();
    const { data: countryList } = usePoCountryListQuery();
    const { data: oemList } = useOemListQuery();
    const { data: slaList } = useSlaListQuery();
    const { data: productTypeList } = useProductTypeListQuery();
    const { data: newModelList } = useNewModelListQuery();
    const { data: supportTypeList } = useSupportTypeListQuery();
    const { data: stateList } = useGetStateQuery();
    const { data: citiesList } = useGetCityQuery();

    const oem = useCallback(
        (value) => {
            let obj = oemList.find((e) => e.oem === value);
            return obj?.oem_id;
        },
        [oemList]
    );

    const modelNumber = useCallback(
        (value) => {
            let obj = newModelList.find((e) => e.new_model === value);
            return obj?.new_model_id;
        },
        [newModelList]
    );

    const product_type = useCallback(
        (value) => {
            let obj = productTypeList.find((e) => e.product_type === value);
            return obj?.product_type_id;
        },
        [productTypeList]
    );

    const supportType = useCallback(
        (value) => {
            let obj = supportTypeList.find((e) => e.support_type === value);
            return obj?.support_type_id;
        },
        [supportTypeList]
    );

    const sla = useCallback(
        (value) => {
            let obj = slaList.find((e) => e.sla === value);
            return obj?.sla_id;
        },
        [slaList]
    );

    const country = (value) => {
        let countryId = countryList.find((e) => e.country_name === value);
        return countryId?.country_id;
    };

    const state = useCallback(
        (value) => {
            let obj = stateList.find((e) => e.state_name === value);
            return obj?.state_id;
        },
        [stateList]
    );

    const city = useCallback(
        (value) => {
            let obj = citiesList?.find((e) => e.city_name === value);
            return obj?.city_id;
        },
        [citiesList]
    );

    const region = useCallback(
        (value) => {
            let obj = regionList.find((e) => e.region === value);
            return obj?.region_id;
        },
        [regionList]
    );

    const handalImport = (e) => {
        const files = e.target.files;
        if (files.length) {
            const file = files[0];

            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = read(e.target.result, {
                    cellDates: true,
                    raw: false,

                    dateNF: "yyyy-mm-dd",
                });
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        raw: false,
                    });

                    setFieldValue(
                        "boqDetails",

                        rows
                            .map((data) => ({
                                oem: oem(data.oem) || "",
                                customerModelNumber:
                                    data.customerModelNumber || "",
                                model_number:
                                    modelNumber(data.modelNumber) || "",
                                serial_number: data.serialNumber || "",
                                product_type:
                                    product_type(data.productType) || "",
                                category: data.category || "",
                                support_type:
                                    supportType(data.supportType) || "",
                                sla: sla(data.sla) || "",
                                start_date: data.startDate || "",
                                end_date: data.endDate || "",
                                price: data.totalPrice || "",
                                country_name: country(data.country) || "",
                                state_name: state(data.state) || "",
                                city_name: city(data.city) || "",
                                region_name: region(data.region) || "",
                                boq_remarks: data.remarks || "",
                            }))
                            .concat(boqData)
                    );
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <input
            id="file-upload"
            type="file"
            onChange={handalImport}
            className="sr-only"
        />
    );
};

export default BOQDetails;
