import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import DataTable from "../../components/Tables/DataTable";
import Modal from "../../components/modal";
import { useSupportTypeListQuery } from "../../api/poProcessApi";
import { BiEditAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { Field, Formik } from "formik";
import { CgSpinnerAlt } from "react-icons/cg";
import {
    useAddSupportTypeMutation,
    useEditSupportTypeMutation,
} from "../../api/adminApi";
import { useDispatch } from "react-redux";
import { setLoading } from "../../features/toggleSlice";
import ServerError from "../../components/Error";
import PageNotFound from "../../components/Error/PageNotFound";
import { ToastContainer, toast } from "react-toastify";

const SupportType = () => {
    const dispatch = useDispatch();

    const {
        data: supportTypeList,
        isLoading,
        isError,
        error,
        isFetching,
    } = useSupportTypeListQuery();
    console.log(supportTypeList);

    const [modal, setModal] = useState(false);
    const [type, setType] = useState("");
    const [supportTypeId, setSupportTypeId] = useState(null);

    const tableData = supportTypeList;

    useEffect(() => {
        dispatch(setLoading(isLoading));

        return () => dispatch(setLoading(false));
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isLoading === false && isFetching) {
            dispatch(setLoading(isFetching));
        }
        return () => dispatch(setLoading(false));
    }, [dispatch, isFetching, isLoading]);

    const tableColumns = [
        {
            Header: "no.",
            accessor: "support_type_id",
        },
        {
            Header: "Name",
            accessor: "support_type",
        },

        {
            Header: "edit",
            accessor: (row) => row,
            Cell: ({ value }) => (
                <button
                    onClick={() => {
                        setModal(true);
                        setType("EDIT");
                        setSupportTypeId(value.support_type_id);
                    }}
                    className="hover:bg-blue-100 p-2 rounded-full"
                >
                    <BiEditAlt className="h-5 w-5 hover:text-blue-500" />
                </button>
            ),
        },
    ];

    return (
        <>
            {isError ? (
                <div className="flex flex-col items-center justify-center w-full">
                    {error.status === "FETCH_ERROR" ||
                    error.originalStatus === 500 ? (
                        <ServerError />
                    ) : error.originalStatus === 404 ? (
                        <PageNotFound />
                    ) : (
                        <h1 className="text-red-500 ">Somthing went wrong</h1>
                    )}
                </div>
            ) : (
                !isLoading && (
                    <div className="card w-11/12">
                        <Title>Support Type</Title>
                        <div className="flex justify-end">
                            <button
                                onClick={() => {
                                    setModal(true);
                                    setType("ADD");
                                }}
                                className="bg-gray-100 hover:bg-gray-200  active:bg-gray-300 focus:outline-none py-2 px-3  rounded-lg  dark:bg-charcoal dark:text-gray-300"
                            >
                                <FiPlus className="h-4 w-4 inline mr-3" />
                                Add New Support Type
                            </button>
                        </div>

                        <DataTable
                            data={tableData}
                            tableColumns={tableColumns}
                        />

                        <SupportTypeForm
                            open={modal}
                            setOpen={setModal}
                            type={type}
                            id={supportTypeId}
                        />
                        <ToastContainer
                            autoClose={1500}
                            hideProgressBar
                            pauseOnFocusLoss={false}
                            position="bottom-left"
                            theme="colored"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default SupportType;

const SupportTypeForm = ({ open, setOpen, type, id }) => {
    const { data: supportTypeList } = useSupportTypeListQuery();

    const [supportTypeVal, setSupportTypeVal] = useState("");

    const [addSupportType] = useAddSupportTypeMutation();
    const [editSupportType] = useEditSupportTypeMutation();

    useEffect(() => {
        if (!!id) {
            setSupportTypeVal(
                supportTypeList.find((e) => e.support_type_id === id)
                    ?.support_type
            );
        }
    }, [id, supportTypeList, supportTypeVal]);

    return (
        <Modal open={open} setOpen={setOpen}>
            <Formik
                initialValues={{
                    support_type: type === "EDIT" ? supportTypeVal : "",
                }}
                onSubmit={async (values) => {
                    try {
                        const resp = await addSupportType(values).unwrap();
                        await editSupportType({
                            data: values,
                            id,
                        }).unwrap();
                        toast.success(resp.message);
                        setOpen(false);
                    } catch (error) {
                        toast.error(error.data.error);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="overflow-hidden  sm:rounded-md">
                            <div className="border-b-2  px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 lg:col-span-2">
                                        <label
                                            htmlFor="country_id"
                                            className="form-label"
                                        >
                                            Support Type
                                        </label>
                                        <Field
                                            name="support_type"
                                            id="support_type"
                                            className=" form-input"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-action">
                                <button
                                    type="submit"
                                    className="form-btn-primary"
                                >
                                    {isSubmitting && (
                                        <CgSpinnerAlt className="animate-spin h-4 w-4 mr-3" />
                                    )}
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="form-btn-secondary"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};
