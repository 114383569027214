import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const Modal = ({ open, title, children, setOpen = null }) => {
  const theme = useSelector(({ toggle }) => toggle.theme);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 ${theme === "dark" ? "dark" : ""}`}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opaity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500  bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 tranalate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opaity-100 translate-y-0 sm:translate-y-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 sm:scale-95 sm:translate-y-0"
            >
              <Dialog.Panel className=" transform overflow-hidden rounded-lg bg-white dark:bg-rich-black text-left shadow-xl transition-all lg:max-w-[90%] sm:my-8 w-3/4 ">
                <div className="bg-white dark:bg-rich-black-2 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className=" sm:flex sm:items-start">
                    <div className="w-full mt-3 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-800 dark:text-gray-100"
                      >
                        {title}
                      </Dialog.Title>
                      {children}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
